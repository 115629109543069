import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { SpinnerCircularFixed } from "spinners-react";

import left_img_sign_in from "../../assets/sign_left_img.png";
import "../CSS file/signin.css";
import SignHeader from "../../Components/Sign Header/SignHeader";
import { helpUserSigningIn, emptyErrorMessage, emptySuccessMessage } from "../../StateManagement/slicers/authSlicer";
import { selectUserFullNameParams } from "../../StateManagement/slicers/userSlicer";
import { signInWithFirebase } from "../common/common";

const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export default function SignIn() {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const fullNameParams = useSelector(selectUserFullNameParams);
  const [showPwdInput, setShowPwdInput] = useState(false);
  const [signInMethod, setSignInMethod] = useState("");

  const { isLoading, errorMessage, successMessage, user } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (successMessage) {
      alert.show(successMessage, { type: "success" });
      dispatch(emptySuccessMessage());
      handleNavigation();
    }
    if (errorMessage) {
      alert.show(errorMessage, { type: "error" });
      dispatch(emptyErrorMessage());
    }
  }, [successMessage, errorMessage]);

  const handleNavigation = () => {
    if (user?.user_personal_profile) {
      const steps_followed = parseInt(user.user_personal_profile.no_of_steps_followed);
      if (steps_followed === 0) navigate("/WelcomePage");
      else if (steps_followed === 1) navigate("/PersonalPage");
      else if (steps_followed === 2) navigate("/IntroPage");
      else if (steps_followed === 3) navigate("/StudyPreferencePage");
      // else if (steps_followed >= 4) navigate(`/Viewprofile/${fullNameParams}`);
      else if (steps_followed >= 4) navigate(`/feeds`);
    } else {
      navigate("/WelcomePage");
    }
  };

  const handleSubmit = (values, { setFieldError }) => {
    setSignInMethod("emailSignIn");
    dispatch(helpUserSigningIn(values,
    ))
      .unwrap()
      .catch((error) => {
        if (error.message === "The user credentials were incorrect.") {
          setFieldError('email', 'Invalid email or password');
          setFieldError('password', 'Invalid email or password');
        }
      });
  };

  const handleSignInWithGoogle = async () => {
    setSignInMethod("googleSignIn");
    const res = await signInWithFirebase();
    dispatch(
      helpUserSigningIn({
        email: res.user.email,
        password: "Jay",
        provider: res.user.providerData[0].providerId,
     

      })
    );
  };

  return (
    <>
      <SignHeader />
      <div className="main_container_sign_in">
        <div className="left_container_sign">
          <img
            src={left_img_sign_in}
            alt="left_img"
            loading="lazy"
            className="img-fluid left_img_sign_in"
          />
        </div>
        <div className="right_container_sign">
          <div className="right_under_contianer">
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={SignInSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <h4 className="sign_in_tex">Sign In</h4>
                  <h6 className="welcome_text">
                    Welcome back! Please signin to continue.
                  </h6>

                  <h6 className="email_text">Email address</h6>
                  <Field
                    type="email"
                    name="email"
                    className={`email_imput form-control ${errors.email && touched.email ? 'is-invalid' : ''}`}
                    placeholder="Enter email"
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />

                  <div className="pwd_container">
                    <h6 className="email_text">Password</h6>
                    <div className="pwd_btn_eye_container1">
                      <Field
                        type={showPwdInput ? "text" : "password"}
                        name="password"
                        className={`form-control ${errors.password && touched.password ? 'is-invalid' : ''}`}
                        placeholder="Enter password"
                      />
                      <button
                        type="button"
                        className="eye_btn_pwd"
                        onClick={() => setShowPwdInput(!showPwdInput)}
                      >
                        {showPwdInput ? (
                          <FaRegEyeSlash className="self_eye_pwd" />
                        ) : (
                          <FaRegEye className="self_eye_pwd" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                    <Link className="forgot_text" to="/ForgotPwd">
                      Forgot Password?
                    </Link>
                  </div>

                  <button
                    type="submit"
                    className="btn_sign_in same_btn_sign_in"
                    disabled={isLoading && signInMethod === "emailSignIn"}
                  >
                    {isLoading && signInMethod === "emailSignIn" ? (
                      <SpinnerCircularFixed
                        speed={138}
                        size={30}
                        enabled={isLoading}
                        color="white"
                      />
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </Form>
              )}
            </Formik>

            <div className="or_horizontal_line">
              <div className="line_horizontal" />
              <span className="or_text_sign_in">OR</span>
              <div className="line_horizontal" />
            </div>

            <div className="btn_google_fb_contianer">
              <button
                className="btn_google same_btn_sign_in"
                onClick={handleSignInWithGoogle}
                disabled={isLoading && signInMethod === "googleSignIn"}
              >
                {isLoading && signInMethod === "googleSignIn" ? (
                  <SpinnerCircularFixed
                    speed={138}
                    size={30}
                    enabled={isLoading}
                    color="white"
                  />
                ) : (
                  "Sign in with Google"
                )}
              </button>

              <Link className="dont_have_text" to="/Signup">
                Don't have an account?{" "}
                <span className="create_text1">Create an Account</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}