import React, { useState, useEffect, useRef } from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  MdThumbUpOffAlt,
  MdThumbUp,
  MdOutlineChatBubbleOutline,
  MdIosShare,
  MdOutlineChatBubble,
  MdOutlineSentimentSatisfied,
  MdSend,
} from "react-icons/md";
import "../../CSS file/middleprofile.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  deleterUserPostById,
  userSendInteractionOnPost,
} from "../../../StateManagement/slicers/userSlicer";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchUserFeedWall,
  userAddCommentOnFeeds,
} from "../../../StateManagement/slicers/userSlicer";
import { Navigation, Pagination, Keyboard } from "swiper";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import moment from "moment";
import "@szhsin/react-menu/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import profile_avtar from "../../../assets/profile_pic.jpg";
import "../../CSS file/comment.css";
import Picker from "emoji-picker-react";
import CustDialogBox from "../../../Components/CustDialogBox";
import ShareDialogBox from "./ShareDialogBox";
import AllCountSection from "./AllCountSection";
import PostMiddleSkeleton from "../../../Components/skeleton loader/PostMiddleSkeleton";
import { useAlert } from "react-alert";
const profile_pic = require("../../../assets/pro_pi_2.png");
const MAX_ITEMS = 5;

export default function PostMiddle(props) {
  const postVdoRef = useRef();
  const alert = useAlert();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userBySearch = useSelector((state) => state.user.storeUserBySearch);
  const userInfo = useSelector((state) => state.user.user);
  const userAllPost = useSelector((state) => state.user.userPost);
  const userFeedWall = useSelector((state) => state.user.userWall);
  const storeUserFriendGallery = useSelector(
    (state) => state.user.storeUserFriendGallery
  );
  const [user, setUser] = useState([]);
  const [userPost, setUserPost] = useState([]);
  const [userLikeOnPost, setUserLikeOnPost] = useState([]);
  const [clickComment, setClickComment] = useState(false);
  const [clickShare, setClickShare] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [openCommentExpansion, setOpenCommentExpansion] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [countToggleModal, setCountToggleModal] = useState(false);
  const [commentBlock, setCommentBlock] = useState("");
  const [likeThumbnails, setLikeThumbnails] = useState(false);
  const [userLikeInteraction, setUserLikeInteraction] = useState("");
  const Com = useRef("");
  const [visibleItems, setVisibleItems] = useState(MAX_ITEMS);

  const handleLoadMore = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + MAX_ITEMS);
  };

  useEffect(() => {
    dispatch(fetchUserFeedWall());
  }, [fetchUserFeedWall]);

  useEffect(() => {
    if (location.state === "id") {
      setUser(userBySearch);
      setUserPost(storeUserFriendGallery);
    } else if (location.pathname === "/MyActivityMain") {
      if (userFeedWall) {
        let result = userFeedWall.filter((item) => {
          return item.user_id === userInfo.id;
        });
        setUser(userInfo);
        setUserPost(result);
      }
    } else {
     
      setUser(userInfo);
      setUserPost(userFeedWall);
    }
  }, [userBySearch, userInfo, userFeedWall, userAllPost, location]);

  async function handleSend(data) {
    dispatch(
      userAddCommentOnFeeds({
        user_id: user.id,
        feed_id: data.id,
        comment: Com.current.value,
        // if_reply: 0,
        // comment_id: "",
      })
    );
    setCommentInput("");
  }
  // function handleLike(e) {
  //   let emptyArray = [];
  //   if (!commentBlock.includes(e.index)) {
  //     setCommentBlock((state) => [...state, e.index]);
  //     dispatch(userSendInteractionOnPost({ if_liked: true }, e.id));
  //   } else {
  //     const indexFound = commentBlock.indexOf(e.index);
  //     const currentValues = commentBlock;
  //     currentValues.splice(indexFound, 1);
  //     currentValues.forEach((item) => {
  //       emptyArray.push(item);
  //     });
  //     setCommentBlock(emptyArray);
  //     dispatch(userSendInteractionOnPost({ if_liked: false }, e.id));
  //   }
  // }

  useEffect(() => {
    const storedLikes = JSON.parse(localStorage.getItem('likedComments')) || [];
    setCommentBlock(storedLikes);
}, []);

function handleLike(e) {
    let updatedCommentBlock;
    if (!commentBlock.includes(e.index)) {
        updatedCommentBlock = [...commentBlock, e.index];
        dispatch(userSendInteractionOnPost({ if_liked: true }, e.id));
    } else {
        updatedCommentBlock = commentBlock.filter(index => index !== e.index);
        dispatch(userSendInteractionOnPost({ if_liked: false }, e.id));
    }

    setCommentBlock(updatedCommentBlock);
    localStorage.setItem('likedComments', JSON.stringify(updatedCommentBlock));
}

  function handleComment(e) {
    setClickComment(e);
    setOpenCommentExpansion(e);
  }

  function handleShare() {
    setClickShare(!clickShare);
    setToggleModal(true);
  }

  const onEmojiClick = (event, emojiObject) => {
    setCommentInput((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojis(false);
  };

  function handleRemovePost(e) {
    dispatch(deleterUserPostById(e));
    alert.info('Post Deleted.');
  }

  function handleOpenLikesCount(e) {
    setCountToggleModal(true);
  }


  useEffect(() => {
    const arrayObjOne = [
      { userId: "3", display: "Miraj" },
      { userId: "2", display: "Viku" },

    ]

    // Array Object 2
    const arrayObjTwo = [
      { empId: "3", display: "Meet", designation: "Jr. Officer", otherName: { id: 1, name: "" } },
      { empId: "2", display: "Vikas", designation: "Jr. Officer", otherName: { id: 2, name: "" } },
      { empId: "1", display: "Gyan", designation: "Sr. Officer", otherName: { id: 3, name: "" } },
      { empId: "4", display: "Gulu", designation: "Ast. Manager", otherName: { id: 4, name: "" } },
    ]


    //crud operation on array object 1 and 2  and return result in array object 3
    const result = arrayObjOne.map((item) => {
      const found = arrayObjTwo.find((element) => element.empId === item.userId);
      return {
        ...item,
        ...found,
        otherName: {
          ...found.otherName,
          name: item.display
        }
      }
    })





  }, [])

  
  const handleUserClick = (userId) => {
    navigate(`/p/Viewprofile/${userId}`);
  };
  
  const renderLikedUser = (data) => {
    const likedUsers = data?.filter((perPost) => perPost.if_liked === 1);

    if (!likedUsers || likedUsers.length === 0) {
      return null;
    }

    const likedUserNames = likedUsers.slice(0, 3).map((perPost) => (
      <span 
        key={perPost.user_id} 
        onClick={() => handleUserClick(perPost.user_id)}
        style={{ cursor: 'pointer' }}
      >
        {`${perPost.user_interacted_name.first_name} ${perPost.user_interacted_name.last_name}`}
      </span>
    ));

    return (
      <>
        <p className="like_user_list" style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
          {likedUserNames.reduce((prev, curr) => [prev, ', ', curr])}
        </p>
        {likedUsers.length > 2 && (
          <span className="view-more-like" onClick={() => setCountToggleModal(true)}> ...view more</span>
        )}
      </>
    );
  };

  const renderAllPostItems = userPost.map((perPost, index) => {
    
    if (index < visibleItems) {
      if (perPost) {
        return (
          <div className="" key={index}>
            <div className="latest_profile_name_subname_timeperiod_container">
              <div className="latest_profile_contianer">
                <img
                  loading="lazy"
                  className="profile_img_activity"
                  alt="pic"
                  src={
                    perPost?.feeds_uploaded_user_profile?.profile_pic
                      ? perPost?.feeds_uploaded_user_profile?.profile_pic
                      : profile_pic
                  }
                />
              </div>
              <div className="profile_name_subname_container">
                <h6 className="profile_name_acivity">
                  {perPost?.feeds_uploaded_user_profile?.first_name +
                    " " +
                    perPost.feeds_uploaded_user_profile?.last_name}
                </h6>
                <h6 className="profile_subname_acivity">
                  {moment(perPost.created_at).format("MMM Do, YYYY")}
                </h6>
              </div>
              {location.pathname === "/MyActivityMain" ? (
                <span className="five_hour_text">
                  <Menu
                    direction="left"
                    menuButton={
                      <MenuButton>
                        {" "}
                        <BsThreeDots />
                      </MenuButton>
                    }
                  >
                    <div onClick={() => handleRemovePost(perPost.id)}>
                      <MenuItem value="red">Remove post</MenuItem>
                    </div>
                  </Menu>
                </span>
              ) : null}
            </div>
            <p className="details_activity_text">
              {perPost?.content ? perPost?.content : ""}
            </p>
            {perPost?.files !== null ? (
              <Swiper
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                grabCursor={true}
                // loop={true}
                keyboard={true}
                modules={[Navigation, Pagination, Keyboard]}
                className="mySwiper"
              >
                {JSON.parse(perPost?.files).map((perPostData, index) => {
                  let detail12 = perPostData
                    .split(/[#?]/)[0]
                    .split(".")
                    .pop()
                    .trim();
                  if (
                    detail12 === "mp4" ||
                    detail12 === "webm" ||
                    detail12 === "Mkv" ||
                    detail12 === "avi" ||
                    detail12 === "Mp4"
                  ) {
                    return (
                      <SwiperSlide className="img_post" key={Math.random()}>
                        <div
                          className="img_flex_wrap_container1"
                          onClick={() => props.fileValue(perPost?.files)}
                        >
                          <video
                            className="video_iframe_upload vdo_post_style"
                            ref={postVdoRef}
                            controls
                          >
                            <source src={perPostData} />
                          </video>
                        </div>
                      </SwiperSlide>
                    );
                  }

                if (
                  detail12 === "png" ||
                  detail12 === "jpg" ||
                  detail12 === "jpeg" ||
                  detail12 === "jfif"
                ) {
                  return (
                    <SwiperSlide className="img_post" key={Math.random()}>
                      <img
                        loading="lazy"
                        src={perPostData}
                        alt="posterImage"
                        className="post_img_self"
                        onClick={() => props.fileValue(perPost?.files)}
                      />
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          ) : null}
          <div
            className="like_count_modal_container"
            onClick={() => {
              handleOpenLikesCount(perPost);
              setUserLikeInteraction(perPost);
            }}
          >
            {perPost.likes_count}

              <MdThumbUp />

             




            {renderLikedUser(perPost.feeds_has_user_interactions)}
           
          </div>
          <div className="like_comment_share_container">
            <button
              className="comment_contaier"
              onClick={() => {
                handleLike({ index: index, id: perPost.id });
                setLikeThumbnails(!likeThumbnails);
              }}
            >
              <span className="like_text">
                {commentBlock.includes(index) ||
                  perPost?.if_liked === true ||
                  perPost?.if_liked === "1"
                  ? "Liked"
                  : "Like"}
              </span>
              {commentBlock.includes(index) ||
                perPost?.if_liked === "1" ||
                perPost?.if_liked === true ? (
                <MdThumbUp className="like_icon" />
              ) : (
                <MdThumbUpOffAlt className="like_icon" />
              )}
            </button>

              <button
                className="comment_contaier"
                onClick={() => handleComment(index)}
              >
                <span className="like_text">Comment</span>
                

                {clickComment === index ? (
                  <MdOutlineChatBubble className="like_icon" />
                ) : (
                  <MdOutlineChatBubbleOutline className="like_icon" />
                )}
              </button>

              <button className="comment_contaier" onClick={handleShare}>
                <span className={clickShare ? "liked_text" : "like_text"}>
                  Share
                </span>
                <MdIosShare className="like_icon" />
              </button>
              {/* <button onClick={() => handleViewPostDetails(perPost.id)}>
          View Post Details
        </button> */}

            </div>{" "}
            {
              openCommentExpansion === index ? (
                <div className="collapse_container">
                  <div className="card_body">
                    <div className="profile_txtinput_emoiji_container">
                      <img
                        loading="lazy"
                        className="profile_avtar"
                        alt="profile"
                        src={profile_avtar}
                      />

                      <div className="input_happy_container">
                        <input
                          className="comment_text_input"
                          name="text"
                          type="text"
                          ref={Com}
                          value={commentInput}
                          onChange={(e) => setCommentInput(e.target.value)}
                          placeholder="Write comment ..."
                        />
                        <button
                          className="emoji_btn"
                          // onClick={() => setShowEmojis((val) => !val)}
                          onClick={() => setShowEmojis(!showEmojis)}
                        >
                          <MdOutlineSentimentSatisfied className="emoji_icon" />
                        </button>

                        <button
                          className="emoji_btn"
                          // onClick={() => handleSend({ Com: Com, id: perPost.id })}
                          onClick={() => handleSend({ Com: Com.current, id: perPost.id })}
                        >
                          <MdSend className="emoji_icon" />
                        </button>

                        {showEmojis && (
                          <div className="emoiji_contianer">
                            <Picker
                              preload={true}
                              pickerStyle={{ width: "100%" }}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="scroll_comment_section">
                      {perPost?.feeds_has_comments.map((item, index) => {
                       
                        return (
                          <div
                            className="profile_and_comment_container"
                            key={index}
                          >
                            <div className="pro_pic_container">
                              <div className="profile_container">
                                <img
                                  loading="lazy"
                                  src={
                                    item?.comment_by_user_name?.profile_pic
                                      ? item?.comment_by_user_name?.profile_pic
                                      : profile_avtar
                                  }
                                  alt="profile_img"
                                />
                              </div>
                            </div>
                            <div className="text_content_container">
                              <strong>
                                {item.comment_by_user_name.first_name}{" "}
                                {item.comment_by_user_name.last_name}
                              </strong>
                                <span style={{ float: "right", fontSize: "11px", lineHeight: "23px" }}>{moment(item?.created_at).fromNow()}</span>
                              <p>{item.comment}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null
            }
            <div className="space_box" />
          </div >
        );
      }
    }
    return null
  });

  return (
    <>
      {userFeedWall.length > 0 ? renderAllPostItems : <PostMiddleSkeleton />}

      <CustDialogBox
        dialogHeaderName="Share"
        open={toggleModal}
        onClose={() => setToggleModal(false)}
      >
        <ShareDialogBox />
      </CustDialogBox>

      <CustDialogBox
        dialogHeaderName="All Likes"
        open={countToggleModal}
        onClose={() => setCountToggleModal(false)}
      >
        <AllCountSection userLikeInteraction={userLikeInteraction} />
      </CustDialogBox>
      {userPost.length > visibleItems && (
              <button onClick={handleLoadMore}>Load More</button>
            )}
    </>
  );
}
