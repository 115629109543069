import React, { useRef, useEffect, useState } from "react";
import SelectBox from "../../Components/SelectBox";
import "../CSS file/filtercontent.css";
import { fetchUniversity } from "../../StateManagement/slicers/universitySlicer";
import { fetchLoggedInUser } from "../../StateManagement/slicers/userSlicer";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountriesData } from "../../StateManagement/slicers/countrySlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { fetchStudyAreas } from "../../StateManagement/slicers/studySlicer";
import { fetchPrograms } from "../../StateManagement/slicers/studySlicer";
import { fetchIntakes } from "../../StateManagement/slicers/studySlicer";
import { fetchDurations } from "../../StateManagement/slicers/studySlicer";
import { fetchEnglishExamsDetails } from "../../StateManagement/slicers/studySlicer";

function FilterContent(props) {
  const dispatch = useDispatch();
  const [countriesToRender, setCountriesToRender] = useState([]);
  const [specializationsToRender, setSpecializationsToRender] = useState([]);
  const [programLevelToRender, setProgramLevelToRender] = useState([]);
  const [intakeToRender, setIntakeToRender] = useState([]);
  const [durationToRender, setDurationToRender] = useState([]);
  const [
    EnglishExamsDetailsToRender,
    setEnglishExamsDetailsIdToRender,
  ] = useState([]);
  const token = useSelector((state) => state.token.token);
  const countries = useSelector((state) => state.country.countries);
  const specializations = useSelector((state) => state.study.specializations);
  const programLevel = useSelector((state) => state.study.programLevels);
  const intakes = useSelector((state) => state.study.intakes);
  const duration = useSelector((state) => state.study.duration);
  const englishExamsDetails = useSelector((state) => state.study.englishExam);

  const universityFilter = useRef({
    country: "",
    specializing: "",
    degree: "",
    intake: "",
    duration: "",
    language: "",
    test: "",
    tuitionFee: "",
  });

  const universityUpdateFilter = useRef({});


  useEffect(() => {
   
    dispatch(fetchApiBearerToken());
    dispatch(fetchCountriesData());
    dispatch(fetchStudyAreas());
    dispatch(fetchPrograms());
    dispatch(fetchIntakes());
    dispatch(fetchDurations());
    dispatch(fetchEnglishExamsDetails());
  }, [dispatch]);

  useEffect(() => {
    setEnglishExamsDetailsIdToRender(englishExamsDetails);
  }, [englishExamsDetails]);

  useEffect(() => {
    if (duration.length > 0) {
      setDurationToRender(duration);
    }
  }, [duration]);

  useEffect(() => {
    if (programLevel.length > 0) {
      setProgramLevelToRender(programLevel);
    }
  }, [programLevel]);

  useEffect(() => {
    if (intakes.length > 0) {
      setIntakeToRender(intakes);
    }
  }, [intakes]);

  useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      dispatch(fetchLoggedInUser());
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (countries.length > 0) {
      setCountriesToRender(countries);
    }
    if (specializations.length > 0) {
      setSpecializationsToRender(specializations);
    }
   
  }, [countries, specializations]);

  function handleClear() {}

  async function handleFilterSubmit(e) {
    e.preventDefault();
    dispatch(fetchUniversity(universityUpdateFilter.current));
    props.filter(false);
  }
  return (
    <>
      <div className="clear_btn_contaienrt">
        <button className="clear_all_btn" onClick={handleClear}>
          Clear all
        </button>
      </div> 
      <div className="filter_main_contianer">
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="I would like to study in"
            initialValue="Select option"
            options={
              countriesToRender.length > 0
                ? countriesToRender.map((name) => {
                    return {
                      optionName: name.country_name,
                      optionValue: name.id,
                    };
                  })
                : []
            }
            selectValue={(e) => {
              universityFilter.current["country"] = e;
              universityUpdateFilter.current["UniversityCountry"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Specializing in"
            initialValue="Select option"
            options={
              specializationsToRender.length > 0
                ? specializationsToRender.map((name) => {
                    return {
                      optionName: name.name,
                      optionValue: name.id,
                    };
                  })
                : []
            }
            selectValue={(e) => {
              universityFilter.current["specializing"] = e;
              universityUpdateFilter.current["SpecializationChoice"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="With a degree"
            initialValue="Select option"
            options={
              programLevelToRender.length > 0
                ? programLevelToRender.map((name) => {
                    return {
                      optionName: name.program_level_name,
                      optionValue: name.id,
                    };
                  })
                : []
            }
            selectValue={(e) => {
              universityFilter.current["degree"] = e;
              universityUpdateFilter.current["Degree"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Select intake"
            initialValue="Select option"
            options={
              intakeToRender.length > 0
                ? intakeToRender.map((name) => {
                    return {
                      optionName: name.intake_name,
                      optionValue: name.intake_name,
                    };
                  })
                : []
            }
            selectValue={(e) => {
              universityFilter.current["intake"] = e;
              
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Select duration"
            initialValue="Select option"
            options={
              durationToRender.length > 0
                ? durationToRender.map((name) => {
                    return {
                      optionName: name.duration_length,
                      optionValue: name.duration_length,
                    };
                  })
                : []
            }
            
            selectValue={(e) => {
              universityFilter.current["duration"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Select language test"
            initialValue="Select option"
            options={[
              { optionName: "Required", optionValue: "Required" },
              { optionName: "Not Required", optionValue: "Not Required" },
            ]}
            selectValue={(e) => {
              universityFilter.current["language"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Select additional test"
            initialValue="Select option"
            options={
              EnglishExamsDetailsToRender.length > 0
                ? EnglishExamsDetailsToRender.map((name) => {
                    return {
                      optionName: name.name,
                      optionValue: name.name,
                    };
                  })
                : []
            }
           
            selectValue={(e) => {
              universityFilter.current["test"] = e;
            }}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Selec tution fee"
            initialValue="Select option"
            options={[
              { optionName: "Below $10000", optionValue: "Below $10000" },
              { optionName: "$10000 - $25000", optionValue: "$10000 - $25000" },
              { optionName: "$25000 - $50000", optionValue: "$25000 - $50000" },
              { optionName: "Above $50000", optionValue: "Above $50000" },
            ]}
            selectValue={(e) => {
              universityFilter.current["tuitionFee"] = e;
             
            }}
          />
        </div>
      </div>
      <div className="filter_submit_btn_container">
        <button className="submit_btn_dialog_box" onClick={handleFilterSubmit}>
          Submit
        </button>
      </div>
    </>
  );
}

export default FilterContent;
