import React, { useState, useEffect } from "react";
import "../CSS file/viewpro.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import LeftProfile from "../View Profile/left profile/LeftProfile";
import "@szhsin/react-menu/dist/index.css";
import MiddleProfile from "../View Profile/middle profile/MiddleProfile";
import RightProfile from "../View Profile/right profile/RightProfile";
import whtsApp from "../../assets/bot.png";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import profile1 from "../../assets/profile_pic.jpg";
import close from "../../assets/close.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { useLocation } from "react-router-dom";

const theme = {
  background: "#f5f8fb",
  fontFamily: "IBMReg",
  headerBgColor: "var(--theme-red)",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "var(--theme-red)",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const steps = [
  {
    id: "1",
    message: "Welcome to the DYNAMIC WORLD EDUCATION COMMUNITY !!!",
  },
  {
    id: "1",
    message: "How can I help you?",
    end: false,
  },
];
export default function Viewprofile() {
  let [showChat, setShowChat] = useState(false);
  const universityLoading = useSelector((state) => state.university.isLoading);
  const userLoading = useSelector((state) => state.user.isLoading);

  const token = useSelector((state) => state.token.token);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, []);

  

  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };
  return (
    <div>
      <CommonHeader />
      <div className="view_profile_main_container" id="viewprofile">
        <div className="one_main_container">
          <LeftProfile visible="visible" />
        </div>
        <div className="two_main_container">
          <MiddleProfile />
        </div>
        <div className="third_main_container">
          <div className="btn_whats_ap">
            {showChat ? (
              <ThemeProvider theme={theme}>
                <ChatBot
                  steps={steps}
                  headerComponent={
                    <div className="header_chat_content">
                      <button className="close_btn" onClick={hideChat}>
                        <img
                          src={close}
                          alt="close"
                          loading="lazy"
                          className="close_btn_icon"
                        />
                      </button>
                    </div>
                  }
                  botAvatar={whtsApp}
                  userAvatar={profile1}
                  enableSmoothScroll={true}
                />
              </ThemeProvider>
            ) : (
              <button className="btn" onClick={() => startChat()}>
                <img
                  loading="lazy"
                  src={whtsApp}
                  alt="whtsApp"
                  className="whts_app_chat"
                />
                <h6 className="chat_with_text"> Chat with bot </h6>
              </button>
            )}
          </div>
          <RightProfile visible="visible" />
        </div>
      </div>
    </div>
    //   )}
    // </>
  );
}

// import React, { useState } from "react";
// import "../CSS file/connectionmain.css";
// import CommonHeader from "../../Components/Common header/CommonHeader";
// import "../CSS file/activity.css";
// import MiddleProfile from "../View Profile/middle profile/MiddleProfile";
// import GalleryActivity from "./GalleryActivity";

// export default function MyActivityMain() {
//   return (
//     <div className="main_connection_contianer">
//       <CommonHeader />
//       <div className="conn_title_search_bar_contianer">
//         <h4 className="conn_title">My Activity</h4>
//       </div>

//       <hr className="conn_line" />

//       <div className="connection_under_main_container">
//         <div className="activity_gallery_news_feed_section_wrapper">
//           <div className="activity_gallery_wrapper">
//             <GalleryActivity />
//           </div>

//           <div className="activity_news_feed_wrapper">
//             <MiddleProfile />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
