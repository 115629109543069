import React, { useState, useEffect } from "react";
import "./peopleImgtitle.css";

export default function PeopleImgTitle(props) {
  const [peopleTitleImg, setPeopleTitleImg] = useState([]);

  useEffect(() => {
    if (props.valueToRender?.length > 0) {
      setPeopleTitleImg(props.valueToRender);
    }
  }, [props.valueToRender]);

  const renderImgTitle = peopleTitleImg.map((perPeopleDetail) => {
    return (
      <button
        className="profile_people_title_sub_title_container"
        key={Math.random()}
      >
        <img
          src={perPeopleDetail.people_img}
          alt="people"
          className="people_img_self"
          loading="lazy"
        />
        <div className="peoplw_title_text_subtitle_contianer">
          <h6 className="people_title_text">{perPeopleDetail.peopleTitle}</h6>
          <p className="people_sub_title_text">
            {perPeopleDetail.peopleSubTitle}
          </p>
        </div>
      </button>
    );
  });

  return <>{peopleTitleImg.length > 0 ? renderImgTitle : null}</>;
}
