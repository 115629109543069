import React, { useState, useEffect } from "react";
import EditProfileHeader from "./EditProfileHeader";
import HeaderSubtitle from "./HeaderSubtitle";
import "../CSS file/righteditprofile.css";
import { BsPlus } from "react-icons/bs";
import ExamDialogbox from "./Dialog Box Content/ExamDialogbox";
import CustDialogBox from "../../Components/CustDialogBox";
import { useDispatch, useSelector } from "react-redux";
import { fetchEnglishExamsById } from "../../Api/Api";
import HeaderSubtitle2 from "./HeaderSubtitle2";


export default function Content4() {
  const user = useSelector((state) => state.user.user);
  const [allExamData, setAllExamData] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [examstore, setExamStore] = useState([])


  useEffect(() => {

    if (user?.user_appeared_for_test) {
      if (user.user_appeared_for_test.length > 0) {
        setAllExamData(user.user_appeared_for_test);
      }
      else {
        setAllExamData([]);
      }
    }
  }, [user]);

  const handleOpenExperienceBox = () => {
    setToggleModal(true);
  };

  function handleToggle(e) {
    setToggleModal(e)
  }


  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchEnglishExamsById(user?.id);
      setExamStore(response?.data?.data)
    }
    fetchData()

  }, [allExamData])








  const renderTestData = allExamData.map((exam,index) => {
    return (
      <div className="exam_one_container" key={index}>
        <EditProfileHeader editHeaderName="Exams" edit="examInfo"
          examId={exam.id}

        />
        <div className="wrap_container_exam">
          <HeaderSubtitle2

            // titleHeader1={(exam?.test_id === "1") ? "IELTS" : (exam?.test_id === "2") ? "TOEFL" : (exam?.test_id === "3") ? "PTE" : (exam?.test_id === "4") ? "DET" : (exam?.test_id === "5") ? "GMAT" : (exam?.test_id === "6") ? "GRE" : (exam?.test_id === "7") ? "SAT" : (exam?.test_id === "8") ? "ACT" : null}
            titleHeader1={exam?.test_info?.name}
            //titleHeader1={() => handleEnglishById(exam?.test_id)}

            subTitleHeader1={
              exam?.overall_score
                ? "Total Score :- " + exam?.overall_score
                : "------"
            }
          />

          {JSON.parse(exam?.schema_wise_marks).map((item ,index) => {
            return (
              <HeaderSubtitle2
                key={index}
                titleHeader1={item?.sectionName + "( 0-180 )"}
                subTitleHeader1={
                  item?.sectionValue ? item?.sectionValue : "------"
                }
              />
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className="exam_main_contaier">
      <CustDialogBox
        dialogHeaderName="Add Exam Details"
        open={toggleModal}
        onClose={() => setToggleModal(false)}
      >
        <ExamDialogbox handleToggle={handleToggle} />
      </CustDialogBox>
      <div className="exam_main_under_container">
        {allExamData.length > 0 ? renderTestData : null}

        <div className="exam_one_container_border">
          <button className="squre_contianer" onClick={handleOpenExperienceBox}>
            <BsPlus className="plus_icon" />
          </button>
        </div>
      </div>
    </div>
  );

}




