import React, { useEffect, useState } from "react";
import { PropagateLoader } from "react-spinners";
import "../Screens/CSS file/loaderfullpage.css";

function LoaderFullPage(props) {
  let [loading, setLoading] = useState(true);
  return (
    <div className="loader_full_page_main_container">
      <PropagateLoader color="var(--theme-blue)" loading={loading} size={40} />
    </div>
  );
}

export default LoaderFullPage;
