import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { MdMailOutline } from "react-icons/md";
import { BsHouseDoor, BsPhone } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoggedInUser } from "../../StateManagement/slicers/userSlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { getPreferredUniversity } from "../../StateManagement/slicers/universitySlicer";

export default function LeftProfileSkeleton(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state.token.token);
  const { isLoading, preferredUniversities } = useSelector(
    (state) => state.university
  );

  const userFeedWall = useSelector((state) => state.user.userWall);
  const userInfo = useSelector((state) =>
    state.user.user ? state.user.user : state.auth.user
  );

  const userBySearch = useSelector((state) => state.user.storeUserBySearch);
  const [user, setUser] = useState([]);

  const [allScore, setAllScore] = useState([]);
  const [postArr, setPostArr] = useState([]);
  const [storePost, setStorePost] = useState([]);

  useEffect(() => {
    setPostArr(userFeedWall);
  }, [userFeedWall]);

  useEffect(() => {
    let result = postArr.filter((item) => {
      return item.user_id === userInfo.id;
    });

    setStorePost(result.length);
  }, [postArr]);
  useEffect(() => {
    if (location.state === "id") {
      setUser(userBySearch);
    } else {
      setUser(userInfo);
    }
  }, [userBySearch, userInfo]);

  const allCountItms = [
    {
      numberCount: storePost ? storePost : 0,
      textCount: "Post",
    },
    {
      numberCount: user?.user_has_social_background?.followers
        ? user?.user_has_social_background?.followers
        : 0,
      textCount: "Followers",
    },
    {
      numberCount: user?.user_has_social_background?.following
        ? user?.user_has_social_background?.following
        : 0,
      textCount: "Following",
    },
  ];

  const [allUnivereName, setAllUnivereName] = useState([]);

  
  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, []);

  useEffect(() => {
    if (token != "" && token != undefined && token != null) {
      dispatch(fetchLoggedInUser());
    }
  }, [token]);

  useEffect(() => {
    if (user?.user_appeared_for_test) {
      if (user?.user_appeared_for_test.length > 0) {
        // if (user?.user_appeared_for_test.length > 6) {
        //   let data = user.user_appeared_for_test.slice(0, 5)
        //   setAllScore(data);
        // } else {
        setAllScore(user.user_appeared_for_test);
        // }
      }
    }
  }, [user]);

  useEffect(() => {
    if (preferredUniversities.length > 0) {
      setAllUnivereName(preferredUniversities);
    }
  }, [preferredUniversities]);

  useEffect(() => {
 
    if (token != "" && token != undefined && token != null) {
      dispatch(fetchLoggedInUser());
      dispatch(getPreferredUniversity());
    }
  }, [token]);

  const allContInfo = [
    {
      iconConInfo: <BsHouseDoor className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.address
        ? user?.user_personal_profile?.address
        : "--------",
    },
    {
      iconConInfo: <MdMailOutline className="icon_cotanct_info" />,
      contactInfo: user?.email,
    },
    {
      iconConInfo: <BsPhone className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.phone
        ? user?.user_personal_profile?.phone
        : "--------",
    },
    {
      iconConInfo: <BsPhone className="icon_cotanct_info" />,
      contactInfo: user?.user_personal_profile?.alternate_phone
        ? user?.user_personal_profile?.alternate_phone
        : "--------",
    },
  ];

  const renderContactInfo = allContInfo.map((perContact) => {
    return (
      <div className="contact_icon_title_contianer" key={Math.random()}>
        {perContact.iconConInfo}
        <div
          style={{ marginTop: 5 }}
          className="data_contact_info skeleton_text skeleton skeleton_width"
        />
        <p className="data_contact_info"></p>
      </div>
    );
  });

  const renderViewContent = allCountItms.map((perCount) => {
    return (
      <div className="post_container" key={Math.random()}>
        <div
          style={{ marginTop: 5 }}
          className="number_text skeleton_text skeleton skeleton_width_short"
        />

        <div
          style={{ marginTop: 5 }}
          className="title_number_text skeleton_text skeleton skeleton_width"
        />
      </div>
    );
  });

  return (
    <div className="one_under_main_container">
      <div className="profile_circle_container skeleton_remove_border">
        <div className="profile_pic_self skeleton skeleton_remove_border" />
      </div>
      <div className="name_username_editbtn_tagline_count_container">
        <div className="name_of_profile skeleton_text skeleton skeleton_width"></div>
        <div
          style={{ marginTop: 5 }}
          className="username_profile skeleton_text skeleton skeleton_width_short"
        />

        {/* (<div className="two_btn_cotainer">
            <button className="same_btn_profile follow_btn_search follow_btn_activity">
              Follow
            </button>
          </div>) */}

        {props.visible === "visible" ? null : (
          <div className="two_btn_cotainer">
            <div
              style={{ height: 25 }}
              className="msg_btn same_btn_profile skeleton skeleton_width skeleton_remove_border  "
            />
            <div
              style={{ height: 25 }}
              className="edit_profile_btn same_btn_profile skeleton skeleton_width skeleton_remove_border "
            />
          </div>
        )}
        {user?.user_personal_profile?.description != null &&
        user?.user_personal_profile?.description != "" &&
        user?.user_personal_profile?.description != undefined ? (
          <>
            <div
              style={{ marginTop: 5 }}
              className="name_of_profile skeleton_text skeleton skeleton_width"
            />
            <div
              style={{ marginTop: 5 }}
              className="name_of_profile skeleton_text skeleton skeleton_width"
            />
            <div
              style={{ marginTop: 5 }}
              className="name_of_profile skeleton_text skeleton skeleton_width"
            />
            <div
              style={{ marginTop: 5 }}
              className="username_profile skeleton_text skeleton skeleton_width_short"
            />
          </>
        ) : (
          <p className="about_text_description">
            Update Your Bio Description In Edit Profile Screen...
          </p>
        )}
      </div>
      <div className="post_follow_container"> {renderViewContent} </div>
      <div className="univercity_contianer">
        <h5 className="heading_university_text"> Preffered University </h5>
        <ul className="univercity_under_contianer">
          <li
            className="univercity_under_container1 skeleton_width"
            key={Math.random()}
          >
            <div
              style={{ marginTop: 5 }}
              className="ox_foard_text skeleton_text skeleton skeleton_width"
            />
          </li>
        </ul>
      </div>
      <h5 className="score_text"> Contact Information </h5> {renderContactInfo}
    </div>
  );
}
