import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from "../../Api/Api";
import SignHeader from "../../Components/Sign Header/SignHeader";
import verify_img from "../../assets/verify.png";
import { useAlert } from "react-alert";

export default function NewPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const email = location.state?.email || "";
  const otp = location.state?.otp || "";
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      alert.show("Passwords do not match.", { type: "error" });
      return;
    }

    try {
      await resetPassword(email, newPassword, otp);
      navigate("/"); // Redirect to login page after successful password reset
      alert.show("your password is reset.", { type: "success" });
    } catch (error) {
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <div>
      <SignHeader />

      <div className="forgot_main_container">
        <div className="forgot_under_contianer">
          <img
            loading="lazy"
            src={verify_img}
            className="img-fluid forgot_img"
          />
          <div className="reset_content_contianer">
            <h6 className="reset_pwd_text">New Password</h6>
            <p className="reset_extra_content">
              Please check your email and click the verify button or link to
              verify your OTP.
            </p>
            {/* {error && <div>{error}</div>} */}
            <form onSubmit={handleResetPassword} className="w-100">
              <input
                type="email"
                placeholder="Enter your email"
                className="sign_up_input my-3"
                value={email}
                disabled
              />
              <input
                type="password"
                placeholder="Enter new password"
                className="sign_up_input my-3"
                value={newPassword}
                onChange={handleNewPasswordChange}
                required
              />
              <input
                type="password"
                placeholder="Enter confirm password"
                className="sign_up_input my-3"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <button type="submit" className="reset_btn_self w-100 mt-2">
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}