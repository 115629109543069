import React, { useEffect } from "react";
import "../CSS file/connectionmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import ConnectionLeftSide from "./ConnectionLeftSide";
import ConnectionRightSide from "./ConnectionRightSide";
import CategorySearch from "../../Components/CategorySearch";
import { useSelector } from "react-redux";
import CustLoader from "../../Components/Loader/CustLoader";
import { userConnectionWithOthers } from "../../StateManagement/slicers/userSlicer";
import { useDispatch } from "react-redux";

export default function ConnectionMain() {
  let dispatch = useDispatch()
  const universityTypeToRender = useSelector(
    (state) => state.university.universityTypeToRender
  );
  const { isLoading } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(userConnectionWithOthers());
  }, [userConnectionWithOthers]);


  return (

    <div className="main_connection_contianer">
      {isLoading ? <CustLoader />
        : null}
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">{universityTypeToRender}</h4>
        <CategorySearch />
      </div>
      <hr className="conn_line" />
      <div className="connection_under_main_container">
        <div className="left_side_connection_contianer">
          <ConnectionLeftSide />
        </div>
        <div className="right_side_connection_contianer">
          <ConnectionRightSide />
        </div>
      </div>
    </div>
  );
}
