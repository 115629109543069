// import React from "react";
// import "./custloader.css";

// function CustLoader(props) {
//   return (
//     <div className="lds-default">
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//       <div></div>
//     </div>
//   );
// }

// export default CustLoader;

import React from 'react';

const CustLoader = () => {
  const overlayStyle = {
    position: 'fixed',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.4)', // Light gray background with transparency
    // backdropFilter: 'blur(5px)', // Blur effect
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000 // Ensure it's on top of other content
  };

  const spinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite'
  };

  const spinKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div style={overlayStyle}>
      <style>{spinKeyframes}</style>
      <div style={spinnerStyle}></div>
    </div>
  );
};

export default CustLoader;
