import React, { useState } from "react";
import SectionOfUnivercity from "./SectionOfUnivercity";

function SectionMain(props) {
  const [changeSection, setChangeSection] = useState();

  return (
    <div>
      <SectionOfUnivercity />
   
    </div>
  );
}
 
export default SectionMain; 
  