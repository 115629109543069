import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../CSS file/personaldialog.css";
import InputText from "../../../Components/InputTextBox/InputText";
import SelectBox from "../../../Components/SelectBox";
import {
  fetchUser,
  updateUser,
  updateLoggedInUser,
} from "../../../StateManagement/slicers/userSlicer.js";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";
import { useAlert } from "react-alert";

const validationSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State/Province is required"),
  city: Yup.string().required("City is required"),
  pin_code: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be at least 5 digits")
    .max(9, "Must be less than 9 digits")
    .required("Postal Zip code is required"),
  address: Yup.string()
    .required("Address Line is required")
    .min(10, "Must be at least 10 digits")
    .max(150, "Must be less than  150 digits"),
});

export default function AddressDialogbox(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.user);
  const [userProfileUpdate, setUserProfileUpdate] = useState({});
  const userDetailsToUpdate = useRef({});

  const formik = useFormik({
    initialValues: {
      country: user?.user_personal_profile?.country || "",
      state: user?.user_personal_profile?.state || "",
      city: user?.user_personal_profile?.city || "",
      pin_code: user?.user_personal_profile?.pin_code || "",
      address: user?.user_personal_profile?.address || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoggedInUser(values));
      alert.success("Address Updated Successfully");
      props.handleToggle(false);
    },
  });

  useEffect(() => {
    formik.setValues({
      country: user?.user_personal_profile?.country || "",
      state: user?.user_personal_profile?.state || "",
      city: user?.user_personal_profile?.city || "",
      pin_code: user?.user_personal_profile?.pin_code || "",
      address: user?.user_personal_profile?.address || "",
    });
  }, [user]);

  useEffect(() => {
    setUserProfileUpdate(user);
  }, [user]);
  const userAddress = useRef({
    city: "",
    state: "",
    pinCode: "",
    country: "",
    address: "",
  });

  const countries = useSelector((state) => state.country.countries);



  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateLoggedInUser(userDetailsToUpdate.current));
    alert.success("Address Update SuccessFully");

    props.handleToggle(false);
  }



  return (
    // <form onSubmit={handleSubmit}>
    <form onSubmit={formik.handleSubmit}>
      <div className="acc_details_main_contianer">
        <div className="select_input_text_contianer">
          {/* <SelectBox
            headTextInput="Country"
            initialValue={
              userProfileUpdate?.user_personal_profile?.country
                ? userProfileUpdate?.user_personal_profile?.country
                : "Select option"
            }
            options={
              countries.length > 0
                ? countries.map((country) => {
                  return {
                    optionName: country.country_name,
                    optionValue: country.id,
                  };
                })
                : []
            }
            selectValue={(e) => {
              userAddress.current["country"] = e;
              userDetailsToUpdate.current["country"] = e;
            }}
          /> */}
          <SelectBox
            headTextInput="Country"
            initialValue={formik.values.country}
            options={
              countries.length > 0
                ? countries.map((country) => ({
                    optionName: country.country_name,
                    optionValue: country.country_name,
                  }))
                : []
            }
            selectValue={(e) => formik.setFieldValue("country", e)}
            className={
              formik.touched.country && formik.errors.country
                ? "is-invalid"
                : ""
            }
          />
          {formik.touched.country && formik.errors.country ? (
            <div className="error">{formik.errors.country}</div>
          ) : null}
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="State/Province"
            initialValue={formik.values.state}
            options={
              countries.length > 0
                ? countries.map((country) => ({
                    optionName: country.country_name,
                    optionValue: country.country_name,
                  }))
                : []
            }
            selectValue={(e) => formik.setFieldValue("state", e)}
            className={
              formik.touched.state && formik.errors.state ? "is-invalid" : ""
            }
          />
          {formik.touched.state && formik.errors.state ? (
            <div className="error">{formik.errors.state}</div>
          ) : null}
        </div>

        <div className="select_input_text_contianer">
          {/* <SelectBox
            headTextInput="City"
            initialValue={
              userProfileUpdate?.user_personal_profile?.city
                ? userProfileUpdate?.user_personal_profile?.city
                : "Select option"
            }
            options={
              countries.length > 0
                ? countries.map((country) => {
                  return {
                    optionName: country.country_name,
                    optionValue: country.id,
                  };
                })
                : []
            }
            selectValue={(e) => {
              userAddress.current["city"] = e;
              userDetailsToUpdate.current["city"] = e;
            }}
          /> */}
          <SelectBox
            headTextInput="City"
            initialValue={formik.values.city}
            options={
              countries.length > 0
                ? countries.map((country) => ({
                    optionName: country.country_name,
                    optionValue: country.country_name,
                  }))
                : []
            }
            selectValue={(e) => formik.setFieldValue("city", e)}
            className={
              formik.touched.city && formik.errors.city ? "is-invalid" : ""
            }
          />
          {formik.touched.city && formik.errors.city ? (
            <div className="error">{formik.errors.city}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          
          <InputText
            headTextInput="Postal Zip code"
            inputType="text"
            placeholderText="Enter zipcode"
            inputId="pin_code"
            name="pin_code"
            value={formik.values.pin_code}
            onChange={formik.handleChange}
            className={
              formik.touched.pin_code && formik.errors.pin_code
                ? "is-invalid"
                : ""
            }
          />
          {formik.touched.pin_code && formik.errors.pin_code ? (
            <div className="error">{formik.errors.pin_code}</div>
          ) : null}
        </div>
        <div className="input_textarea_container">
          <h6 className="input_label_text">Address Line</h6>
         
          <textarea
            id="address"
            name="address"
            placeholder="Enter your full address.."
            value={formik.values.address}
            onChange={formik.handleChange}
            className={`${
              formik.touched.address && formik.errors.address
                ? "is-invalid"
                : ""
            } textarea_about_style `}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="error">{formik.errors.address}</div>
          ) : null}
        </div>
      </div>

      <div className="btn_submit_cntainer">
        <button
          className="submit_btn_dialog_box"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? (
            <SpinnerCircularFixed
              speed={138}
              size={30}
              enabled={isLoading}
              color="white"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
