import React, { useState, useEffect } from "react";
import "../CSS file/righteditprofile.css";
import EditProfileHeader from "./EditProfileHeader";
import HeaderSubtitle from "./HeaderSubtitle";
import { fetchCountryById } from "../../Api/Api";
import { useSelector } from "react-redux";

export default function Content1() {
  const user = useSelector((state) => state.user.user);
  const [preferredTimeOfContact, setPreferredTimeOfContact] = useState("");
  const [preferredModeOfContact, setPreferredModeOfContact] = useState("");
  const [citizenShip, setCitizenShip] = useState("");
  const [countryName, setCountryName] = useState("");
  useEffect(() => {

    async function checkCountry() {
      if (user?.user_personal_profile?.ethnicity) {
        let res = await fetchCountryById(
          user?.user_personal_profile?.ethnicity
        );
        setCountryName(res.data.country_name);

      }
    }
    async function checkCitizenShipCountry() {
      if (user?.user_personal_profile?.selected_citizenship_id
      ) {
        let res = await fetchCountryById(
          user?.user_personal_profile?.selected_citizenship_id

        );
        setCitizenShip(res.data.country_name);

      }
    }

    checkCountry();
    checkCitizenShipCountry();

  }, [user]);
  useEffect(() => {
  }, [user?.user_personal_profile?.citizenship])

  useEffect(() => {
    let time_of_contacts = "";
    let mode_of_contacts = "";
  
    if (user?.user_interests_and_background) {
      let times = JSON.parse(
        user?.user_interests_and_background?.time_of_contact
      );
      let modes = JSON.parse(
        user?.user_interests_and_background?.mode_of_contact
      ); {
        if (times?.length > 0) {
          times.forEach((time) => {
            if (time_of_contacts !== "") {
              time_of_contacts = time_of_contacts + "," + time;
            } else {
              time_of_contacts = time;
            }
          });
          setPreferredTimeOfContact(time_of_contacts);
        }
      }


      if (modes?.length > 0) {
        modes.forEach((mode) => {
          if (mode_of_contacts !== "") {
            mode_of_contacts = mode_of_contacts + "," + mode;
          } else {
            mode_of_contacts = mode;
          }
        });
        setPreferredModeOfContact(mode_of_contacts);
      }

    }
  }, [user]);

  const allBasicInfo = [
    {
      basicTitle: "Date of birth",
      basicSubtitle: user?.user_personal_profile?.date_of_birth
        ? user?.user_personal_profile?.date_of_birth
        : "-----",
    },
    {
      basicTitle: "Country of Citizenship",
      basicSubtitle: citizenShip
        ? citizenShip
        : "-----",
    },

    {
      basicTitle: "Ethnicity",
      basicSubtitle: countryName ? countryName
        : "-----",
    },
    {
      basicTitle: "Gender",
      basicSubtitle: user?.user_personal_profile?.gender
        ? user?.user_personal_profile?.gender
        : "-----",
    },
    {
      basicTitle: "Marital Status",
      basicSubtitle: user?.user_personal_profile?.marital_status
        ? user?.user_personal_profile?.marital_status
        : "-----",
    },
  ];

  const allAddressInfo = [
    {
      addressTitle: "City",
      addressSubtitle: user?.user_personal_profile?.city
        ? user?.user_personal_profile?.city
        : "-----",
    },
    {
      addressTitle: "State/Province",
      // addressSubtitle: user?.user_personal_profile?.state
      //   ? user?.user_personal_profile?.state
      //   : user?.user_personal_profile?.province
      //   ? user?.user_personal_profile?.province
      //   : "-----",
      addressSubtitle: user?.user_personal_profile?.state
        ? user?.user_personal_profile?.state
        : "-----",
    },
    // {
    //   addressTitle: "Province ",
    //   addressSubtitle: ,
    // },
    {
      addressTitle: "Country",
      addressSubtitle: user?.user_personal_profile?.country
        ? user?.user_personal_profile?.country
        : "-----",
    },
    {
      addressTitle: "Postal Zip",
      addressSubtitle: user?.user_personal_profile?.pin_code
        ? user?.user_personal_profile?.pin_code
        : "-----",
    },
    {
      addressTitle: "Address Line",
      addressSubtitle: user?.user_personal_profile?.address
        ? user?.user_personal_profile?.address
        : "-----",
    },
  ];

  const allPrefferedData = [
    {
      preffredTitle: "Time of contact",
      preffredSubtitle: preferredTimeOfContact
        ? preferredTimeOfContact
        : "------",
    },
    {
      preffredTitle: "Mode of contact",
      preffredSubtitle: preferredModeOfContact
        ? preferredModeOfContact
        : "------",
    },
  ];

  // if (allBasicInfo.length > 0) {
  return (
    <div className="content_main_one_container">
      {/* basic info contianer */}

      <div className="basic_info_address_contaier">
        <div className="basic_info_container">
          <EditProfileHeader
            editHeaderName="Basic of Information"
            edit="basicInfo"
            dlt="dlt"
          />

          {allBasicInfo.map((perBasicInfo) => {
            return (
              <div className="" key={Math.random()}>
                <HeaderSubtitle
                  titleHeader={perBasicInfo.basicTitle}
                  subTitleHeader={perBasicInfo.basicSubtitle}
                />
              </div>
            );
          })}
        </div>

        {/* adress contianer */}

        <div className="address_info_container">
          <EditProfileHeader
            editHeaderName="Address"
            edit="addressInfo"
            dlt="dlt"
          />

          <div className="address_under_contianer">
            {allAddressInfo.map((perAddressInfo) => {
              return (
                <div
                  className="address_title_subtitle_contianer"
                  key={Math.random()}
                >
                  <HeaderSubtitle
                    titleHeader={perAddressInfo.addressTitle}
                    subTitleHeader={perAddressInfo.addressSubtitle}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Preffred for */}
      <div className="prefferd_container">
        <EditProfileHeader
          editHeaderName="Preferred for"
          edit="prefferedInfo"
          dlt="dlt"
        />

        <div className="address_under_contianer">
          {allPrefferedData.map((perPreffred) => {
            return (
              <div
                className="address_title_subtitle_contianer"
                key={Math.random()}
              >
                <HeaderSubtitle
                  titleHeader={perPreffred.preffredTitle}
                  subTitleHeader={perPreffred.preffredSubtitle}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
  // } else {
  //   return <></>;
  // }
}
