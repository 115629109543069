// import React from "react";
// import "./Screens/CSS file/scrollbar.css";
// import "./Screens/CSS file/fonts.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import SignIn from "./Screens/Signin/SignIn";
// import ForgotPwd from "./Screens/Forgot Screen/ForgotPwd";
// import Signup from "./Screens/Sign up/Signup";
// import Verify from "./Screens/Verify/Verify";
// import Viewprofile from "./Screens/View Profile/Viewprofile";
// import Editprofile from "./Screens/Edit profile/EditProfile";
// import ConnectionMain from "./Screens/Connection Screen/ConnectionMain";
// import GroupMain from "./Screens/Group/GroupMain";
// import EventMain from "./Screens/Events Screen/EventMain";
// import UnivercityMain from "./Screens/Univercity&Course/UnivercityMain";
// import ViewDetailUnivercity from "./Screens/Univercity&Course/ViewDetailUnivercity";
// import ViewDetailCourses from "./Screens/Univercity&Course/Courses/ViewDetailCourses";
// import ErrorPage from "./Screens/ErrorPage";
// import SectionMain from "./Screens/Univercity&Course/SectionMain";
// import WelcomePage from "./Screens/InitialPages/WelcomePage";
// import IntroPage from "./Screens/InitialPages/IntroPage";
// import PerosonalPage from "./Screens/InitialPages/PerosonalPage";
// import StudyPreferencePage from "./Screens/InitialPages/StudyPreferencePage";
// import ThankYouPage from "./Screens/InitialPages/ThankYouPage";
// import GalleryMain from "./Screens/Gallery/GalleryMain";
// import MyActivityMain from "./Screens/Activity/MyActivityMain";
// import SearchMain from "./Screens/Search/SearchMain";
// import ApplicationStepper from "./Screens/Stepper/ApplicationStepper";
// import LoaderFullPage from "./Components/LoaderFullPage";
// import ApplyNow from "./Screens/ApplyNow";
// import FilterMain from "./Screens/Filter/FilterMain";
// import NewPassword from "./Screens/NewPassword/NewPassword";
// import PostDetail from "./Screens/PostDetail/PostDetail";
// import PublicViewprofile from "./Screens/View Profile/Viewprofile copy";
// import SignUpContainer from "./Screens/Sign up/SignUpContainer";
// import ProtectedRoute from "./utils/ProtectedRoute";

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/SectionMain" exact element={<SectionMain />} />
//         <Route
//           path="/ApplicationStepper"
//           exact
//           element={<ApplicationStepper />}
//         />
//         <Route path="/FilterMain" exact element={<ProtectedRoute><FilterMain /></ProtectedRoute>} />
//         <Route path="/LoaderFullPage" exact element={<LoaderFullPage />} />
//         <Route path="/" exact element={<SignIn />} />
//         <Route path="/ForgotPwd" exact element={<ForgotPwd />} />
//         <Route path="/Signup" exact element={<SignUpContainer />} />
//         <Route path="/Verify" exact element={<Verify />} />
//         <Route path="/NewPassword" exact element={<NewPassword />} />
//         <Route path="/feeds" exact element={<ProtectedRoute><Viewprofile /></ProtectedRoute>} />
//         <Route path="/p/Viewprofile/:id" exact element={<ProtectedRoute><PublicViewprofile /></ProtectedRoute>} />
//         <Route path="/Editprofile" exact element={<ProtectedRoute><Editprofile /></ProtectedRoute>} />
//         <Route path="/ConnectionMain" exact element={<ProtectedRoute><ConnectionMain /></ProtectedRoute>} />
//         <Route path="/GroupMain" exact element={<GroupMain />} />
//         <Route path="/EventMain" exact element={<EventMain />} />
//         <Route path="/GalleryMain" exact element={<ProtectedRoute><GalleryMain /></ProtectedRoute>} />
//         <Route path="/SearchMain" exact element={<ProtectedRoute><SearchMain /></ProtectedRoute>} />
//         <Route path="/MyActivityMain/" exact element={<ProtectedRoute><MyActivityMain /></ProtectedRoute>} />
//         <Route path="/MyActivityMain/:postId" exact element={<ProtectedRoute><PostDetail /></ProtectedRoute>} />
//         <Route path="/UnivercityMain" exact element={<ProtectedRoute><UnivercityMain /></ProtectedRoute>} >
//         </Route>
//         <Route
//           path="/UnivercityMain/ViewDetailUnivercity/:univercityId/:univercityName"
//           element={<ProtectedRoute><ViewDetailUnivercity /></ProtectedRoute>}
//         />
//         <Route
//           path="/UnivercityMain/ViewDetailCourses/:courseId/:courseName"
//           element={<ProtectedRoute><ViewDetailCourses /></ProtectedRoute>}
//         />
//         <Route path="/IntroPage" exact element={<ProtectedRoute><IntroPage /></ProtectedRoute>} />
//         <Route path="/ApplyNow" exact element={<ProtectedRoute><ApplyNow /></ProtectedRoute>} />
//         <Route path="/PersonalPage" exact element={<ProtectedRoute><PerosonalPage /></ProtectedRoute>} />
//         <Route path="/WelcomePage" exact element={<ProtectedRoute><WelcomePage /></ProtectedRoute>} />
//         <Route
//           path="/StudyPreferencePage"
//           exact
//           element={<ProtectedRoute><StudyPreferencePage /></ProtectedRoute>}
//         />
//         <Route path="/ThankYouPage" exact element={<ProtectedRoute><ThankYouPage /></ProtectedRoute>} />

//         <Route path="*" exact element={<ErrorPage />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;

import React from "react";
import "./Screens/CSS file/scrollbar.css";
import "./Screens/CSS file/fonts.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./Screens/Signin/SignIn";
import ForgotPwd from "./Screens/Forgot Screen/ForgotPwd";
import Signup from "./Screens/Sign up/Signup";
import Verify from "./Screens/Verify/Verify";
import Viewprofile from "./Screens/View Profile/Viewprofile";
import Editprofile from "./Screens/Edit profile/EditProfile";
import ConnectionMain from "./Screens/Connection Screen/ConnectionMain";
import GroupMain from "./Screens/Group/GroupMain";
import EventMain from "./Screens/Events Screen/EventMain";
import UnivercityMain from "./Screens/Univercity&Course/UnivercityMain";
import ViewDetailUnivercity from "./Screens/Univercity&Course/ViewDetailUnivercity";
import ViewDetailCourses from "./Screens/Univercity&Course/Courses/ViewDetailCourses";
import ErrorPage from "./Screens/ErrorPage";
import SectionMain from "./Screens/Univercity&Course/SectionMain";
import WelcomePage from "./Screens/InitialPages/WelcomePage";
import IntroPage from "./Screens/InitialPages/IntroPage";
import PerosonalPage from "./Screens/InitialPages/PerosonalPage";
import StudyPreferencePage from "./Screens/InitialPages/StudyPreferencePage";
import ThankYouPage from "./Screens/InitialPages/ThankYouPage";
import GalleryMain from "./Screens/Gallery/GalleryMain";
import MyActivityMain from "./Screens/Activity/MyActivityMain";
import SearchMain from "./Screens/Search/SearchMain";
import ApplicationStepper from "./Screens/Stepper/ApplicationStepper";
import LoaderFullPage from "./Components/LoaderFullPage";
import ApplyNow from "./Screens/ApplyNow";
import FilterMain from "./Screens/Filter/FilterMain";
import NewPassword from "./Screens/NewPassword/NewPassword";
import PostDetail from "./Screens/PostDetail/PostDetail";
import PublicViewprofile from "./Screens/View Profile/Viewprofile copy";
import SignUpContainer from "./Screens/Sign up/SignUpContainer";
import ProtectedRoute from "./utils/ProtectedRoute";

function App() {
  const isAuth = localStorage.getItem("access_token");

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={isAuth ? <Navigate to="/feeds" /> : <SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/ForgotPwd" element={<ForgotPwd />} />
        <Route path="/Signup" element={<SignUpContainer />} />
        <Route path="/Verify" element={<Verify />} />
        <Route path="/NewPassword" element={<NewPassword />} />
        
        <Route element={<ProtectedRoute />}>
          <Route path="/SectionMain" element={<SectionMain />} />
          <Route path="/ApplicationStepper" element={<ApplicationStepper />} />
          <Route path="/FilterMain" element={<FilterMain />} />
          <Route path="/LoaderFullPage" element={<LoaderFullPage />} />
          <Route path="/feeds" element={<Viewprofile />} />
          <Route path="/p/Viewprofile/:id" element={<PublicViewprofile />} />
          <Route path="/Editprofile" element={<Editprofile />} />
          <Route path="/ConnectionMain" element={<ConnectionMain />} />
          <Route path="/GroupMain" element={<GroupMain />} />
          <Route path="/EventMain" element={<EventMain />} />
          <Route path="/GalleryMain" element={<GalleryMain />} />
          <Route path="/SearchMain" element={<SearchMain />} />
          <Route path="/MyActivityMain" element={<MyActivityMain />} />
          <Route path="/MyActivityMain/:postId" element={<PostDetail />} />
          <Route path="/UnivercityMain" element={<UnivercityMain />} />
          <Route path="/UnivercityMain/ViewDetailUnivercity/:univercityId/:univercityName" element={<ViewDetailUnivercity />} />
          <Route path="/UnivercityMain/ViewDetailCourses/:courseId/:courseName" element={<ViewDetailCourses />} />
          <Route path="/IntroPage" element={<IntroPage />} />
          <Route path="/ApplyNow" element={<ApplyNow />} />
          <Route path="/PersonalPage" element={<PerosonalPage />} />
          <Route path="/WelcomePage" element={<WelcomePage />} />
          <Route path="/StudyPreferencePage" element={<StudyPreferencePage />} />
          <Route path="/ThankYouPage" element={<ThankYouPage />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
