import { createSlice } from "@reduxjs/toolkit";
import { fetchCountries } from "../../Api/Api";

export const countrySlicer = createSlice({
  name: "country",
  initialState: {
    countries: [],
    token: "",
    isLoading: false,
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    storeCountry: (state, action) => {
      state.countries = action.payload.data;
      state.isLoading = false;
    },
   
  },
});

// Action creators are generated for each case reducer function
export const { storeCountry, toggleLoader } = countrySlicer.actions;

export const fetchCountriesData = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const countries = await fetchCountries();
  dispatch(storeCountry(countries));
};

export default countrySlicer.reducer;
