import React, { useEffect, useState } from "react";
import "./selectbox.css";

function SelectBox(props) {
  const [optionsToRender, setOptionsToRender] = useState([]);

  useEffect(() => {
    if (props?.options) {
      setOptionsToRender(props.options);
    }
  }, [props.options]);

  function handleSelectValue(data) {
    
    props.selectValue(data);
  }

  const renderCountry = optionsToRender.map((perOption) => {
    return (
      <option key={Math.random()} value={perOption.optionValue}>
        {perOption.optionName}
      </option>
    );
  });

  return (
    <div>
      <h6 className="select_label_text">{props.headTextInput}</h6>
      <select
        multiple={props.multiple}
        name="pets"
        id="pet-select"
        className="select_box_"
        onChange={(e) => {
         
          handleSelectValue(e.target.value);
        }}
        onBlur={props.onBlur}
        value={props.value}
        placeholder={props.placeholder}
       
      >
        <option>{props.initialValue}</option>

        {optionsToRender.length > 0 ? renderCountry : null}
      </select>
    </div>
  );
}

export default SelectBox;
