import React, { useState, useEffect } from "react";

import EditProfileHeader from "./EditProfileHeader";
import work_img from "../../assets/work_img.png";
import { BsPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import CustDialogBox from "../../Components/CustDialogBox";
import WorkExperienceDialogbox from "./Dialog Box Content/WorkExperienceDialogbox";
import "../CSS file/righteditprofile.css";
import EducationDialogbox from "./Dialog Box Content/EducationDialogbox";
// import { fetchEducationInfoById } from "../../Api/Api";
import AlertBox from "../../Components/AlertBox";

export default function Content2() {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [visible, setVisible] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalDelete, setToggleModalDelete] = useState(false);

  const [allWorkExperience, setAllWorkExperience] = useState([]);
  const [allEducationInfo, setAllEducationInfo] = useState([]);


  function handleToggle(e) {
    setToggleModal(e);
  }

  function handleDeleteDocument(e) {
  
  }

  useEffect(() => {
    if (user.user_work_experience) {
      if (user.user_work_experience.length > 0) {
        setAllWorkExperience(user.user_work_experience);
      }
      else {
        setAllWorkExperience([])
      }
    }

    if (user.user_education_background) {
      if (user.user_education_background.length > 0) {
        setAllEducationInfo(user.user_education_background);
      }
      else {
        setAllEducationInfo([])
      }
    }
  }, [user]);

  const handleOpenExperienceBox = (e) => {
    setToggleModal(true);
    setVisible(e);
  };

  const renderWorkExperiences = allWorkExperience.map((perWork) => {
    return (
      <div className="work_container" key={Math.random()}>
        <EditProfileHeader
          editHeaderName="Work Experience"
          edit="workInfo"
          workId={perWork.id}
        />

        <div className="work_under_things_container">
          <img
            loading="lazy"
            src={work_img}
            alt="work_img"
            className="work_img_self"
          />
          <div className="title_subtitle_location_time_contianer">
            <div className="title_time_contianer">
              {/* <h6 className="job_time_text">
                {perWork?.jobTime ? perWork?.jobTime : " Job type"}
              </h6> */}
            </div>
            <div className="title_time_contianer11">
              <h6 className="job_time_text" title={perWork?.company_name}>
                {perWork?.company_name
                  ? perWork?.company_name
                  : " Company name"}
              </h6>
              <h6 className="job_title_text">
                {perWork?.job_type === 0 ? "Part Time" : "Full Time"}
              </h6>
            </div>

            <h6 className="job_time_text">
              {perWork?.company_location
                ? perWork?.company_location
                : " Company location"}
            </h6>
            <h6 className="period_light_text">
              {perWork?.start_date && perWork?.end_date ? (
                <>
                  {perWork?.start_date} {perWork?.end_date}
                </>
              ) : (
                "Time period"
              )}
              {/* {perWork?.start_date ? perWork?.start_date : "Time period"}{" "}
              {perWork?.end_date ? perWork?.end_date : "Time period"} */}
            </h6>
            <h6 className="job_time_text">
              {perWork?.postal_zip_code
                ? perWork?.postal_zip_code
                : "Postal zip code"}
            </h6>
          </div>
        </div>
        <li className="point_job_content">
          {perWork?.details ? perWork?.details : " Job description"}
        </li>
      </div>
    );
  });

  const renderEducation = allEducationInfo.map((perEduInfo) => {
    return (
      <div className="education_info_contaier" key={Math.random()}>
        <EditProfileHeader
          editHeaderName="History"
          edit="educationInfo"
          educationId={perEduInfo.id}
          delete={handleDeleteDocument}
        />
        <div className="wrap_content_container">
          <div className="edu_under_info_contianer" key={Math.random()}>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Level of Education</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.level_of_education
                    ? perEduInfo?.level_of_education
                    : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Status</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.status ? perEduInfo?.status : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Study Area</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.study_area ? perEduInfo?.study_area : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">School Type</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.school_type ? perEduInfo?.school_type : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Specialization</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.specialization
                    ? perEduInfo?.specialization
                    : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Degree Awarded</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.degree_awarded
                    ? perEduInfo?.degree_awarded
                    : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Institute Name</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.institution_name
                    ? perEduInfo?.institution_name
                    : "----"}
                </h6>
              </div>
            </div>
            <div className="two_text_input_container">
              <div className="">
                <h6 className="date_of_title_text">Grade</h6>
                <h6 className="sub_text_date">
                  {perEduInfo?.grade_received
                    ? perEduInfo?.grade_received
                    : "----"}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="content_main_one_container1">
      {/* education info contianer */}
      <h5 className="heading_content_two_text">Education Details</h5>
      {allEducationInfo.length > 0 ? renderEducation : null}
      {visible === "workInfo" ? (
        <CustDialogBox
          dialogHeaderName="Add Work Experience"
          open={toggleModal}
          onClose={() => setToggleModal(false)}
        >
          <WorkExperienceDialogbox handleToggle={handleToggle} />
        </CustDialogBox>
      ) : (
        <CustDialogBox
          dialogHeaderName="Add education information"
          open={toggleModal}
          onClose={() => setToggleModal(false)}
        >
          <EducationDialogbox handleToggle={handleToggle} />
        </CustDialogBox>
      )}

      <div className="work_experience_container_border">
        <button
          className="squre_contianer"
          onClick={() => handleOpenExperienceBox("History")}
        >
          <BsPlus className="plus_icon" />
        </button>
        <span>Add education information</span>
      </div>

      {/* work experience */}

      <CustDialogBox
        dialogHeaderName="Delete"
        open={toggleModalDelete}
        onClose={() => setToggleModalDelete(false)}
      >
        <AlertBox />
      </CustDialogBox>

      {/* <div className="work_plus_container"> */}
      <h5 className="heading_content_two_text1">Work Experience Details</h5>
      <div className="work_experience_container">
        {allWorkExperience.length > 0 ? renderWorkExperiences : null}

        <div className="work_experience_container_border">
          <button
            className="squre_contianer"
            onClick={() => handleOpenExperienceBox("workInfo")}
          >
            <BsPlus className="plus_icon" />
          </button>
          <span>Add work experience</span>
        </div>
      </div>
    </div>
    // </div>
  );
}
