import { createSlice } from "@reduxjs/toolkit";
import { filterUniversityAndCourses } from "../../Api/Api";
export const filterSlicer = createSlice({
    name: "filter",
    
    


    initialState: {
        filterList: [],
        isLoading: false,
        filterData: {
            university: "",
            country: "",
            intakes: "",
            course: "",
            duration: "",
            study_area: "",
            program_level: "",
            requirement: "",
            limit: "",
            offset: "",
            study_area: "",
            fee_start: "",
            fee_end: "",
        }

    },
    reducers: {
        storeFilterList: (state, action) => {
            state.filterList = action.payload;
        },
        toggleLoader: (state, action) => {
            state.isLoading = action.payload;
        },
        storeFilterData: (state, action) => {
            state.filterData = action.payload;
        }


    },
});
export const { storeFilterList, toggleLoader,
    storeFilterData,

} = filterSlicer.actions;
export const fetchApplyFilterOnCourse = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoader(true));
        const response = await filterUniversityAndCourses(data);

        dispatch(storeFilterList(response.data));
        dispatch(toggleLoader(false));
    } catch (error) {
    }
};

export const storeFilterListSelector = (data) => async (dispatch) => {
   
        dispatch(toggleLoader(true));
        dispatch(storeFilterData(data))
};



export default filterSlicer.reducer;


