import React, { useState, useEffect } from "react";
import "../../CSS file/personaldialog.css";
import InputText from "../../../Components/InputTextBox/InputText";
import "../../../Components/InputTextBox/inputtext.css";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { helpAuthUserChangePassword } from "../../../StateManagement/slicers/authSlicer";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";
import { useAlert } from "react-alert";
// import {
//   emptyErrorMessage,
//   emptySuccessMessage,
//   helpUserSigningIn,
// } from "../../../StateManagement/slicers/userSlicer";
// import { Link, useNavigate } from "react-router-dom";

export default function AccDialogBox(props) {
  // const navigate = useNavigate();
  // const { errorMessage, successMessage } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => state.auth.isLoading);

  // useEffect(() => {
  //   if (successMessage != null && successMessage != "") {
  //     alert.show(successMessage, {
  //       type: "success",
  //     });
  //     dispatch(emptySuccessMessage());
  //     navigate("/Viewprofile");
  //   }
  //   if (errorMessage != null && errorMessage != "") {
  //     alert.show(errorMessage, {
  //       type: "error",
  //     });
  //     dispatch(emptyErrorMessage());
  //   }
  // }, [successMessage, errorMessage]);

  const [accountInfo, setAccountInfo] = useState({
    email: "",
    old_password: "",
    new_password: "",
    reentered_password: "",
  });
  const [showPwdInput, setShowPwdInput] = useState(false);
  const [showPwdId, setShowPwdId] = useState("");

  function showPwd(elementId) {
    var x = document.getElementById(elementId);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    setShowPwdId(elementId);
    setShowPwdInput(!showPwdInput);
  }

  function handleAccSubmit() {
    if (accountInfo.new_password !== accountInfo.reentered_password) {
      alert.show("New password and Repassword not match", {
        type: "error",
      });
    } else {
      dispatch(
        helpAuthUserChangePassword({
          email: user.email,
          old_password: accountInfo.old_password,
          new_password: accountInfo.new_password,
        })
      );
    }
    props.handleToggle(false);
  }

  let changeMessageStyle = (messageAfter) => {
    if (messageAfter) {
      return {
        color: "red",
        fontSize: 15,
      };
    } else {
      return {
        display: "none",
      };
    }
  };

  return (
    <form>
      <div className="acc_details_main_contianer">
        <InputText
          headTextInput="email / username"
          inputType="text"
          placeholderText="Enter email / username"
          inputId="accEmail"
          name="accEmail"
          defaultValue={user?.email}
          disabled={true}
        />

        <div className="pwd_cust_contianer">
          <h6 className="input_label_text">Old password</h6>
          <div className="pwd_btn_eye_container">
            <input
              type="password"
              placeholder="Enter new password"
              id="oldPwdId"
              name="oldPwd"
              required
              // value={props.inputValue}
              autoComplete="off"
              className="pwd_input_acc"
              value={accountInfo?.old_password}
              onChange={(e) =>
                setAccountInfo((preValue) => ({
                  ...preValue,
                  old_password: e.target.value,
                }))
              }
            />
            <button
              type="button"
              className="eye_btn_pwd"
              onClick={() => showPwd("oldPwdId")}
            >
              {showPwdInput && showPwdId === "oldPwdId" ? (
                <FaRegEyeSlash className="self_eye_pwd" />
              ) : (
                <FaRegEye className="self_eye_pwd" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="acc_details_main_contianer">
        <div className="pwd_cust_contianer">
          <h6 className="input_label_text">New password</h6>
          <div className="pwd_btn_eye_container">
            <input
              type="password"
              placeholder="Enter new password"
              id="pwdId"
              name="pwd"
              required
              // value={props.inputValue}
              value={accountInfo?.new_password}
              autoComplete="off"
              className="pwd_input_acc"
              onChange={(e) =>
                setAccountInfo((preValue) => ({
                  ...preValue,
                  new_password: e.target.value,
                }))
              }
            />
            <button
              type="button"
              className="eye_btn_pwd"
              onClick={() => showPwd("pwdId")}
            >
              {showPwdInput && showPwdId === "pwdId" ? (
                <FaRegEyeSlash className="self_eye_pwd" />
              ) : (
                <FaRegEye className="self_eye_pwd" />
              )}
            </button>
          </div>
        </div>

        <div className="pwd_cust_contianer">
          <h6 className="input_label_text">Re-password</h6>
          <div className="pwd_btn_eye_container">
            <input
              type="password"
              placeholder="Enter re-password"
              id="repwdId"
              name="repwd"
              required
              value={accountInfo?.reentered_password}
              // value={props.inputValue}
              autoComplete="off"
              className="pwd_input_acc"
              onChange={
                (e) =>
                  setAccountInfo((preValue) => ({
                    ...preValue,
                    reentered_password: e.target.value,
                  }))
                //   { let password = e.target.value;

                //     if(password !== accountInfo.new_password){
                //       setPasswordMessage("password wrong")
                //     }else{
                //       setAccountInfo((preValue) => ({
                //     ...preValue,
                //     reentered_password: e.target.value,
                //   }))

                //     }
              }
            />

            <button
              type="button"
              className="eye_btn_pwd"
              onClick={() => showPwd("repwdId")}
            >
              {showPwdInput && showPwdId === "repwdIdx`" ? (
                <FaRegEyeSlash className="self_eye_pwd" />
              ) : (
                <FaRegEye className="self_eye_pwd" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="btn_submit_cntainer">
        <button
          type="button"
          className="submit_btn_dialog_box"
          onClick={() => handleAccSubmit()}
        >
          {isLoading ? (
            <SpinnerCircularFixed
              enabled={isLoading}
              color="var(--theme-red)"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
}
