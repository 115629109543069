import React, { useState, useEffect } from "react";
import "../CSS file/eventleft.css";
import PaginationTab from "../../Components/Pagination tab/PaginationTab";
const event_img_self = require("../../assets/group.jpg");

const allEventsBox = [
  {
    eventImg: event_img_self,
    eventName: "Moto Rally Championship",
    eventDate: "Mar 22 - Mar 24",
    eventAddress: "Manila, Philippines",
    eventInterested: "6181",
  },
  {
    eventImg: event_img_self,
    eventName: "Moto Rally Championship",
    eventDate: "Mar 22 - Mar 24",
    eventAddress: "Manila, Philippines",
    eventInterested: "6181",
  },
  {
    eventImg: event_img_self,
    eventName: "Moto Rally Championship",
    eventDate: "Mar 22 - Mar 24",
    eventAddress: "Manila, Philippines",
    eventInterested: "6181",
  },
];

export default function EventLeftSide() {
  const [interestClick, setInterestClick] = useState([]);
  //  const[eventLists,setEventLists] = useState([])

  //   useEffect(()=>{
  //     eventListDetails()
  //   },[])

  //   async function eventListDetails(){
  //     let response = await userEventLists()
  //     setEventLists(response)
  //   }

  function handleInterest(index) {
    let emptyArray = [];
    if (!interestClick.includes(index)) {
      setInterestClick((state) => [...state, index]);
    } else {
      const indexFound = interestClick.indexOf(index);
      const currentValues = interestClick;
      currentValues.splice(indexFound, 1);
      currentValues.forEach((item) => {
        emptyArray.push(item);
      });
      setInterestClick(emptyArray);
    }
  }

  const renderEvents = allEventsBox.map((perEventBox, index) => {
    return (
      <div className="event_box_container" key={Math.random()}>
        <div className="event_img_contianer">
          <img
            loading="lazy"
            className="event_img_self"
            alt="event_img"
            src={perEventBox.eventImg}
          />
        </div>
        <div className="event_content_container">
          <h6 className="title_event_text">{perEventBox.eventName}</h6>
          <p className="address_event_text">{perEventBox.eventAddress}</p>
          <p className="subtitle_event_text">{perEventBox.eventDate}</p>
        </div>

        <div className="interested_contianer">
          <h6 className="total_event_interest_text">
            {perEventBox.eventInterested} people interested
          </h6>

          <button
            className={
              interestClick.includes(index)
                ? "uninterested_btn same_interest_btn"
                : "interested_btn same_interest_btn"
            }
            onClick={() => handleInterest(index)}
          >
            {interestClick.includes(index) ? "Not Interested" : "Interested"}
          </button>
        </div>
      </div>
    );
  });
  return (
    <div className="conectin_under_left_side_contianer">
      <div className="event_main_container">{renderEvents}</div>

      <div className="pagination_event_tab_container">
        <PaginationTab />
      </div>
    </div>
  );
}
