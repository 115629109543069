import React, { useState, useEffect, useRef } from "react";
import { BsPen, BsFileText } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  MdOutlineImage,
  MdOutlineVideocam,
  MdClose,
  MdPlayCircle,
  MdPauseCircle,
  MdFullscreen,
  MdPostAdd,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import PostMiddle from "./PostMiddle";
import Modal from "react-modal";
import upload_pic from "../../../assets/profile_pic.jpg";
import { userUploadPost } from "../../../StateManagement/slicers/userSlicer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.css";
import "../../CSS file/middleprofile.css";
import "@szhsin/react-menu/dist/index.css";
import "../../CSS file/uploadmodalStyle.css";
import { useAlert } from "react-alert";
import { SpinnerRoundOutlined } from "spinners-react";

const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function MiddleProfile() {
  const videoRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const [user, setUser] = useState([]);
  const userInfo = useSelector((state) => state.user.user);
  const userBySearch = useSelector((state) => state.user.storeUserBySearch);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [image, setImage] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectVideos, setSelectVideos] = useState([]);
  const [description, setDescription] = useState("");
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (location.state === "id") {
      setUser(userBySearch);
    } else {
      setUser(userInfo);
    }
  }, [userBySearch, userInfo]);
  function closeModal(e) {
    e.preventDefault()
    setSelectedFiles([]);
    setSelectVideos([]);
    setImage([]);
    setVideos([]);
    setDescription("");
    setIsOpen(false);
  }
  function handleUploadModal() {
    setIsOpen(true);
  }

  function handleCancelUpload() {
    closeModal();
    setIsOpen(false);
  }

  function handleFullScreen(videoRef) {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
    }
  }



  const handleImageChange = (e) => {
    const files = e.target.files;
    const isVideo = e.target.accept === "video/*";
  
    const maxImageSize = 15 * 1024 * 1024; // 15MB limit
    const maxVideoSize = 30 * 1024 * 1024; // 30MB limit
  
    // Function to clear file input
    const clearFileInput = () => {
      e.target.value = "";
    };
  
    if (files) {
      const filesArray = Array.from(files).map((file) => URL.createObjectURL(file));
      const valueArray = Array.from(files);
  
      if (isVideo) {
        const invalidFiles = valueArray.filter((file) => file.size > maxVideoSize);
        if (invalidFiles.length > 0) {
          alert.error('One or more videos exceed the size limit of 20MB.');
          clearFileInput();
          setSelectVideos([]);
          setVideos([]);
          return;
        }
  
        setVideos((prevVideos) => prevVideos.concat(valueArray));
        setSelectVideos((prevVideos) => prevVideos.concat(filesArray));
      } else {
        const invalidFiles = valueArray.filter((file) => file.size > maxImageSize);
        if (invalidFiles.length > 0) {
          alert.error('One or more images exceed the size limit of 30MB.');
          clearFileInput();
          setSelectedFiles([]);
          setImage([]);
          return;
        }
  
        setImage((prevImages) => prevImages.concat(valueArray));
        setSelectedFiles((prevImages) => prevImages.concat(filesArray));
      }
  
      Array.from(files).forEach((file) => URL.revokeObjectURL(file)); // Avoid memory leak
    }
  };
  

  function hanldeVideoPlayerButton(e) {
    if (e === "play") {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  function handleRemovePost(photo, video) {
    
    let result = selectedFiles.filter((item) => {
     
      return item !== photo;
    });
    setSelectedFiles(result);
  }

  function handleVideoRemove(photo, video) {
   
    let result = selectVideos.filter((item) => {
   
      return item !== photo;
    });
    setSelectVideos(result);
  }

  const renderPhotos = selectedFiles.map((photo,index) => {
    if (photo) {
      return (
        <SwiperSlide className="img_flex_wrap_container" key={index}>
          <button
            className="close_btn_photos_container"
            onClick={() => handleRemovePost(photo)}
          >
            <MdClose />
          </button>
          <img loading="lazy" src={photo} alt={photo} className="slider_post" />{" "}
        </SwiperSlide>
      );
    }
  });


  const renderVideo = selectVideos.map((video,index) => {
  
    if (video.length > 0) {
      return (
        <SwiperSlide key={index}>
          <div className="img_flex_wrap_container1">
            <button
              className="close_btn_photos_container"
              onClick={() => handleVideoRemove(video)}
            >
              <MdClose />
            </button>
            <video className="video_iframe_upload " ref={videoRef}>
              <source src={video} />
            </video>
            <button className="full_screen_icon" onClick={handleFullScreen}>
              <MdFullscreen />
            </button>

            <div className="ply_pause_btn_container">
              <button
                className="play_vdo_btn_container"
                onClick={() => hanldeVideoPlayerButton("play")}
              >
                <MdPlayCircle />
              </button>
              <button
                className="play_vdo_btn_container"
                onClick={() => hanldeVideoPlayerButton("pause")}
              >
                <MdPauseCircle />
              </button>
            </div>
          </div>
        </SwiperSlide>
      );
    }
  });
  
  function hanldeUploadUserPostDetails() {
    if (selectedFiles?.length === 0 && selectVideos?.length === 0 && !description?.trim()) {
      alert.error("Please fill in at least one field: image, video, or text.");
      return;
    }
    let formData = new FormData();

    for (let img of image) {
      if (img.size > 15 * 1024 * 1024) { // 15MB limit
        alert.error('One or more images exceed the size limit of 15MB.');
        return; // Stop further execution
      }
    }

    if (image.length > 0) {
      image.forEach((element, index) => {
        formData.append("files[" + index + "]", element);
      });
    }

    for (let video of videos) {
      if (video.size > 20 * 1024 * 1024) { // 20MB limit
        alert.error('One or more videos exceed the size limit of 20MB.');
        return; // Stop further execution
      }
    }

    if (videos.length > 0) {
      videos.forEach((element, index) => {
        formData.append("files[" + index + "]", element);
      });
    }

    if (description) {
      formData.append("content", description);
    }

    setLoading(true); // Show the spinner

    dispatch(userUploadPost(formData))
      .then((response) => {
        setLoading(false); // Hide the spinner
        if (response.flag) {
          alert.success(response.messages);
        } else {
          alert.error(response.messages);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide the spinner
        alert.error('An error occurred while uploading your post.');
      });
    // dispatch(userUploadPost(formData));
    setSelectedFiles([]);
    setSelectVideos([]);
    setImage([]);
    setVideos([]);
    setDescription("");
    setIsOpen(false);
  }

  return (
    <>
      <div className="two_under_main_container">
        <button className="upload_main_container" onClick={handleUploadModal}>
          <div className="upload_btn1">
            {" "}
            <BsPen className="icons_self" />
            <h6 className="share_text">Share an update</h6>
          </div>

          <div className="all_upload_btn_container">
           
            <div className="gallry_btn">
              <MdPostAdd   className="icons_self1" />
            </div>
          </div>
        </button>

        {/* post modal */}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="modal_main_container">
            <div className="heading_title_closebtn_container">
              <h6 className="heading_title">Create Post</h6>

              <button className="close_btn_contianer" onClick={closeModal}>
                <MdClose className="close_self2" />
              </button>
            </div>

            <div className="modal_posttitle_profiletext_another_container">
              <div className="modal_create_post_contaier">
                <div className="img_upload_contianer">
                  <img
                    loading="lazy"
                    src={
                      user?.user_personal_profile?.profile_pic
                        ? user?.user_personal_profile?.profile_pic
                        : upload_pic
                    }
                    alt="Upload"
                    className="upload_btn"
                  />
                </div>
                <h6 className="uplaod_perosna_name">
                  {" "}
                  {user?.user_personal_profile?.first_name
                    ? user?.user_personal_profile?.first_name
                    : "------"}{" "}
                  {user?.user_personal_profile?.last_name
                    ? user?.user_personal_profile?.last_name
                    : "------"}
                </h6>
              </div>

              <textarea
                name="textarea"
                rows="3"
                cols="50"
                placeholder="Write something here ...."
                className="text_area_input"
                onChange={(e) => setDescription(e.target.value)}
              />
              <Swiper
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                grabCursor={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper"
              >
                {selectedFiles.length > 0 ? renderPhotos : null}
                {selectVideos.length > 0 ? renderVideo : null}
              </Swiper>
            </div>
            {selectedFiles.length >= 3 ||
              selectVideos.length >= 3 ||
              selectedFiles.length + selectVideos.length >= 3 ? (
              <p>You can only upload a maximum of 3 files</p>
            ) : null}
            <div className="video_gallery_document_contianer">
              <div className="three_btn_container_upload">
                <label className="gallery_contianre" htmlFor="upload-button">
                  <MdOutlineImage className="icon_img_self" />
                </label>
                <input
                  multiple
                  accept="image/png, image/jpeg, image/jpg"
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  disabled={
                    selectedFiles.length >= 3 ||
                      selectVideos.length >= 3 ||
                      selectedFiles.length + selectVideos.length >= 3
                      ? true
                      : false
                  }
                  required
                />

                <label className="gallery_contianre" htmlFor="upload-video">
                  <MdOutlineVideocam className="icon_img_self" />
                </label>

                <input
                  multiple
                  accept="video/*"
                  type="file"
                  id="upload-video"
                  style={{ display: "none" }}
                  disabled={
                    selectedFiles.length >= 3 ||
                      selectVideos.length >= 3 ||
                      selectedFiles.length + selectVideos.length >= 3
                      ? true
                      : false
                  }
                  onChange={handleImageChange}
                />

            
              </div>
              <div className="post_cancel_btn_contaienr">
                <button
                  className="cancel_btn_contact"
                  onClick={handleCancelUpload}
                >
                  CANCEL
                </button>
                {selectedFiles.length >= 3 ||
                  selectVideos.length >= 3 ||
                  selectedFiles.length + selectVideos.length >= 3 ? null : (
                  <button
                    className="post_container_btn"
                    onClick={hanldeUploadUserPostDetails}
                  >
                    POST
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <div className="latest_activity_container">
          <div className="latest_activity_container1">
            <h6 className="latest_acitivity_text">Latest Activity</h6>
            <div style={{textAlign:"center"}}>{loading && <SpinnerRoundOutlined size={50} thickness={100} speed={100} color="#1b2e4b" secondaryColor="rgba(0, 0, 0, 0.44)" />}</div>
          </div>
          <PostMiddle />
        </div>
      </div>
    </>
  );
}
