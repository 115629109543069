import React, { useState,useEffect } from "react";
import "../CSS file/groupright.css";
import PeopleImgTitle from "../../Components/people you may know/PeopleImgTitle";
import TitleNumberFile from "../../Components/Title and number/TitleNumberFile";
const group_img_right = require("../../assets/poster.jpg");

export default function GroupRightSide() {
  //  const[groups,setGroups] = useState([])

  //   useEffect(()=>{
  //     groupDetails()
  //   },[])

  //   async function groupDetails(){  
  //     let response = await userGroupDetails()
  //     setGroups(response)
  //   }
 
  return (
    <div className="conectin_under_right_side_contianer">
      <h6 className="people_main_title_text">Popular Groups</h6>
      <PeopleImgTitle
        valueToRender={[
          {
            people_img: group_img_right,
            peopleTitle: "Human Resources",
            peopleSubTitle: "1,232,099 Members",
          },
          {
            people_img: group_img_right,
            peopleTitle: "Human Resources",
            peopleSubTitle: "1,232,099 Members",
          },
          {
            people_img: group_img_right,
            peopleTitle: "Human Resources",
            peopleSubTitle: "1,232,099 Members",
          },
          {
            people_img: group_img_right,
            peopleTitle: "Human Resources",
            peopleSubTitle: "1,232,099 Members",
          },
          {
            people_img: group_img_right,
            peopleTitle: "Human Resources",
            peopleSubTitle: "1,232,099 Members",
          },
        ]}
      />

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY POSITION</h6>

        <TitleNumberFile
          valueToRender={[
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
            {
              titlePostion: "Software Engineer",
              numberPostion: "20",
            },
          ]}
        />
      </div>

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY LOCATION</h6>

        <TitleNumberFile
          valueToRender={[
            {
              titlePostion: "San Francisco, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Los Angeles, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Las Vegas, Nevada",
              numberPostion: "20",
            },
            {
              titlePostion: "Austin, Texas",
              numberPostion: "20",
            },
            {
              titlePostion: "Arlington, Nebraska",
              numberPostion: "20",
            },
          ]}
        />
      </div>
    </div>
  );
}
