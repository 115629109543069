import {createSlice} from '@reduxjs/toolkit';



export const commonSlicer = createSlice({
    name: 'common',
    initialState: {
        isLoading: false,
        tabUniversity:1,
        error:""
    },
    reducers: {
        toggleLoader: (state, action) => {
            state.isLoading = action.payload;
        },
        setSuccessFull: (state, action) => {
            state.isLoading = action.payload;
            state.error = "";
        },
        setFailed: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        setTabUniversity: (state, action) => {
            state.tabUniversity = action.payload;
        }
    }
})

export const { toggleLoader,setTabUniversity ,setSuccessFull,setFailed} = commonSlicer.actions;

export const selectLoader = (data) => async (dispatch) => {
    dispatch(toggleLoader(data));
}

export const selectTabUniversity = (data) => async (dispatch) => {
    dispatch(setTabUniversity(data));
}

export const selectSuccessFull = (data) => async (dispatch) => {
    dispatch(setSuccessFull(data));
}

export const selectFailed = (data) => async (dispatch) => {
    dispatch(setFailed(data));
}




export default commonSlicer.reducer;
