import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  MdThumbUp,
  MdThumbUpOffAlt,
  MdInsertComment,
  MdOutlineChatBubble,
  MdOutlineChatBubbleOutline,
  MdOutlineSentimentSatisfied,
  MdSend,
} from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Picker from "emoji-picker-react";

const PostModal = ({
  selectedPostIndex,
  userGalleryList,
  handleClose,
  handleLike,
  handleCommentSubmit,
  userInfo,
}) => {
  const [currentPostIndex, setCurrentPostIndex] = useState(selectedPostIndex);
  const [comment, setComment] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);

  useEffect(() => {
    setCurrentPostIndex(selectedPostIndex);
  }, [selectedPostIndex]);

  if (currentPostIndex === null) return null;

  const handleNext = () => {
    setCurrentPostIndex((prevIndex) =>
      prevIndex === userGalleryList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentPostIndex((prevIndex) =>
      prevIndex === 0 ? userGalleryList.length - 1 : prevIndex - 1
    );
  };

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
  };

  const renderMediaContent = (files) => {
    return files.map((file, index) => (
      <SwiperSlide key={index}>
        {file.endsWith(".mp4") || file.endsWith(".webm") ? (
          <video src={file} controls />
        ) : (
          <img src={file} alt={`Media content ${index}`} />
        )}
      </SwiperSlide>
    ));
  };

  return (
    <div className="post-modal-overlay">
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      <div className="post-modal">
        <div className="post-content row">
          <div className="post-media col-md-6">
            <button className="nav-button prev-button" onClick={handlePrev}>
              <FaChevronLeft />
            </button>
            <Swiper
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
              spaceBetween={0}
              slidesPerView={1}
              className="h-100"
            >
              {renderMediaContent(
                JSON.parse(userGalleryList[currentPostIndex].files)
              )}
            </Swiper>
            <button className="nav-button next-button" onClick={handleNext}>
              <FaChevronRight />
            </button>
          </div>
          <div className="post-details col-md-6">
            <div className="post-header">
              <img
                src={
                  userGalleryList[currentPostIndex].feeds_uploaded_user_profile
                    .profile_pic
                }
                alt="User avatar"
                className="user-avatar"
              />
              <span className="username">
                {
                  userGalleryList[currentPostIndex].feeds_uploaded_user_profile
                    .first_name
                }{" "}
                {
                  userGalleryList[currentPostIndex].feeds_uploaded_user_profile
                    .last_name
                }
              </span>
            </div>
            <p className="post-text">
              {userGalleryList[currentPostIndex].text}
            </p>
            <div className="post-actions">
              <button
                onClick={() => handleLike(userGalleryList[currentPostIndex])}
              >
                {userGalleryList[currentPostIndex].if_liked ? (
                  <MdThumbUp />
                ) : (
                  <MdThumbUpOffAlt />
                )}
                <span>{userGalleryList[currentPostIndex].likes_count}</span>
              </button>
              <button>
                <MdInsertComment />
                <span>
                  {userGalleryList[currentPostIndex].feeds_has_comments?.length}
                </span>
              </button>
            </div>

            <div className="w-100">
              <div className="post-modal">
                <div className="post-content row">
                  <div className="post-details">
                    <p className="details_activity_text">
                      {userGalleryList[currentPostIndex].content}
                    </p>
                    <div className="like_comment_share_container">
                      <button
                        className="comment_contaier"
                        onClick={() =>
                          handleLike(userGalleryList[currentPostIndex])
                        }
                      >
                        <span
                          className={
                            userGalleryList[currentPostIndex].if_liked
                              ? "liked_text"
                              : "like_text"
                          }
                        >
                          {userGalleryList[currentPostIndex].if_liked
                            ? "Liked"
                            : "Like"}
                        </span>
                        {userGalleryList[currentPostIndex].if_liked ? (
                          <MdThumbUp className="like_icon" />
                        ) : (
                          <MdThumbUpOffAlt className="like_icon" />
                        )}
                      </button>
                      <button
                        className="comment_contaier"
                        onClick={toggleCommentSection}
                      >
                        <span className="like_text">Comment</span>
                        {isCommentSectionOpen ? (
                          <MdOutlineChatBubble className="like_icon" />
                        ) : (
                          <MdOutlineChatBubbleOutline className="like_icon" />
                        )}
                      </button>
                    </div>
                    {isCommentSectionOpen && (
                      <>
                        <div className="scroll_comment_section">
                          {userGalleryList[
                            currentPostIndex
                          ].feeds_has_comments?.map((item, index) => (
                            <div
                              className="profile_and_comment_container"
                              key={index}
                            >
                              <div className="pro_pic_container">
                                <div className="profile_container">
                                  <img
                                    loading="lazy"
                                    src={item.comment_by_user_name.profile_pic}
                                    alt="profile_img"
                                  />
                                </div>
                              </div>
                              <div className="text_content_container">
                                <strong>
                                  {item.comment_by_user_name.first_name}{" "}
                                  {item.comment_by_user_name.last_name}
                                </strong>
                                <p>{item.comment}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="profile_txtinput_emoiji_container">
                          <img
                            loading="lazy"
                            className="profile_avtar"
                            alt="profile"
                            src={userInfo.profile_pic}
                          />
                          <div className="input_happy_container">
                            <input
                              className="comment_text_input"
                              name="text"
                              type="text"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              placeholder="Write comment ..."
                            />
                            <button
                              className="emoji_btn"
                              onClick={() => setShowEmojis(!showEmojis)}
                            >
                              <MdOutlineSentimentSatisfied className="emoji_icon" />
                            </button>
                            <button
                              className="emoji_btn"
                              onClick={() =>
                                handleCommentSubmit(
                                  currentPostIndex,
                                  comment,
                                  setComment
                                )
                              }
                            >
                              <MdSend className="emoji_icon" />
                            </button>
                            {showEmojis && (
                              <div className="emoiji_contianer">
                                <Picker
                                  preload={true}
                                  pickerStyle={{ width: "100%" }}
                                  onEmojiClick={(e, emoji) =>
                                    setComment(comment + emoji.emoji)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModal;