import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProgramLevels,
  fetchUniversityIntakes,
  fetchIntakeYears,
  fetchSpecializations,
  fetchEnglishExams,
  fetchDuration,
  fetchEnglishExamsById,
} from "../../Api/Api";

export const studySlicer = createSlice({
  name: "study",
  initialState: {
    programLevels: [],
    intakes: [],
    intakeYears: [],
    specializations: [],
    englishExam: [],
    duration: [],
    englishExamsById: {},
    isLoading: false,
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    storeProgramLevels: (state, action) => {
      state.programLevels = action.payload;
      state.isLoading = false;
    },
    storeUniversityIntakes: (state, action) => {
      state.intakes = action.payload;
      state.isLoading = false;
    },
    storeIntakeYears: (state, action) => {
      state.intakeYears = action.payload;
      state.isLoading = false;
    },
    storeSpecializations: (state, action) => {
      state.specializations = action.payload;
      state.isLoading = false;
    },
    storeEnglishDetails: (state, action) => {
      state.englishExam = action.payload;
      state.isLoading = false;
    },
    storeDuration: (state, action) => {
      state.duration = action.payload;
      state.isLoading = false;
    },
    storeEnglishExamsById: (state, action) => {
      state.englishExamsById = action.payload;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeProgramLevels,
  storeUniversityIntakes,
  storeIntakeYears,
  storeSpecializations,
  toggleLoader,
  storeDuration,
  storeEnglishDetails,
  storeEnglishExamsById,
} = studySlicer.actions;



export const fetchPrograms = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const program_levels = await fetchProgramLevels();
  dispatch(storeProgramLevels(program_levels.data));
};

export const fetchEnglishExamsDetails = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const english_Exam = await fetchEnglishExams();

  dispatch(storeEnglishDetails(english_Exam.data));
};
export const fetchIntakes = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const intakes = await fetchUniversityIntakes();
  dispatch(storeUniversityIntakes(intakes.data));
};

export const fetchIntakesYear = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const intakes = await fetchIntakeYears();
  dispatch(storeIntakeYears(intakes.data));
};

export const fetchStudyAreas = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const specializations = await fetchSpecializations();
  dispatch(storeSpecializations(specializations.data));
};

export const fetchDurations = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const duration = await fetchDuration();
  dispatch(storeDuration(duration.data));
};

export const fetchEnglishExamsByIds = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const englishExamsById = await fetchEnglishExamsById(data);
  dispatch(storeEnglishExamsById(englishExamsById.data));
};
export default studySlicer.reducer;
