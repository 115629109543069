import React, { useState } from "react";
import "../../CSS file/rightslider.css";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import "./styles.css";

// import required modules
import { Autoplay, Pagination } from "swiper";
import posterImg4 from "../../../assets/poster.png";
import posterImg1 from "../../../assets/poster2.jpg";
import posterImg3 from "../../../assets/poster3.jpg";
import posterImg from "../../../assets/poster.png";

const allPosterData = [
  {
    posterImgName: posterImg3,
  },
  {
    posterImgName: posterImg1,
  },
  {
    posterImgName: posterImg4,
  },
];

export default function LeftSlider() {
  return (
    <div className="left_silder_main_contianer">
      <h5 className="score_text1"> Latest Offers </h5>{" "}
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {allPosterData.map((perPoster) => {
          return (
            <SwiperSlide key={Math.random()}>
              <img
                loading="lazy"
                src={perPoster.posterImgName}
                // className=""
                alt="poster"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {/* <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          >
          <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2000">
          <img src={posterImg} className="d-block w-100" alt="..." />
          </div>
          
          {allPosterData.map((perPoster) => (
            <div
            className="carousel-item"
            data-bs-interval="2000"
            key={Math.random()}
            >
            <img
            src={perPoster.posterImgName}
            className="d-block w-100"
            alt="poster"
            />
            </div>
            ))}
            </div>
            <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            >
            <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
            </button>
            <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            >
            <span
            className="carousel-control-next-icon"
            aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
            </button>
          </div> */}{" "}
    </div>
  );
}
