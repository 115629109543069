import React, { useState, useEffect } from "react";
import "./css files/filtermain.css";
import { FiFilter } from "react-icons/fi";
import CommonHeader from "../../Components/Common header/CommonHeader";

import { useMediaQuery } from "react-responsive";
import FilterLeftSide from "./FilterLeftSide";
import { useSelector, useDispatch } from "react-redux";
import FilterRightSide from "./FilterRightSide";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { fetchCountriesData } from "../../StateManagement/slicers/countrySlicer";
import { fetchDurations, fetchEnglishExamsDetails, fetchIntakes, fetchPrograms, fetchStudyAreas } from "../../StateManagement/slicers/studySlicer";
import { storeFilterListSelector } from "../../StateManagement/slicers/filterSlicer";
import { fetchCourse } from "../../StateManagement/slicers/courseSlicer";

import CustLoader from "../../Components/Loader/CustLoader";

export default function FilterMain() {
let dispatch = useDispatch()
  
  const { filterList, isLoading } = useSelector((state) => state.filter);


  useEffect(() => {
  
    dispatch(fetchApiBearerToken());
    dispatch(fetchCountriesData());
    dispatch(fetchStudyAreas());
    dispatch(fetchPrograms());
    dispatch(fetchIntakes());
    dispatch(fetchDurations());
    dispatch(fetchEnglishExamsDetails());
    dispatch(fetchCourse())
  }, [dispatch]);
  const [filter, setFilter] = useState(false);
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const isBigScreen = useMediaQuery({
    query: "(min-width: 767px)",
  });
  function handleFilter() {
    setFilter(!filter);
  }
  return (
    <>
    {isLoading ?     <CustLoader /> : null}

      <div className="main_connection_contianer">
        <CommonHeader />
        <div className="filter_search_clear_main_container">
          <div className="filter_text_container">
            <h6>Filter</h6>
          </div>
          <div className="search_result_clear_all_container">
            <h6>Search Results ( {filterList?.length} )</h6>
            <button onClick={()=>{
              dispatch(storeFilterListSelector())
                window.location.reload()
              }}
              >Clear all</button>
          </div>
        </div>

        <hr className="conn_line" />

        <div className="filter_search_clear_main_container">
          {isSmallScreen && (
            <button onClick={handleFilter}> 
              <FiFilter /> Filter
            </button>
          )}

          {filter ? (
            <>
              {isSmallScreen && (
                <div
                  className={filter ? "all_width_filters_container" : "hide"}
                >
                  <FilterLeftSide />
                </div>
              )}
            </>
          ) : null}

          {isBigScreen && (
            <div className="all_width_filters_container">
              <FilterLeftSide />
            </div>
          )}

          <div className="result_width_section_container">
            <FilterRightSide />
          </div>
        </div>
      </div>
    </>
  );
}
