import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import location from '../../assets/location.png'

// Define a custom icon
const customIcon = L.icon({
  iconUrl: location, // URL to your custom icon image
  iconSize: [30, 30], // Size of the icon
  iconAnchor: [15, 30], // Anchor point of the icon [left/right, top/bottom]
});

const ChangeMapCenter = ({ center }) => {
  const map = useMap();
  map.flyTo(center, map.getZoom(), {
    duration: 1,  // Duration of animation in seconds
  });
  return null;
};

const MapComponent = ({ center, markers, onLocationClick }) => {
  return (
    <div>
     
        <MapContainer center={center} zoom={8} style={{ height: "70vh", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            icon={customIcon}
            eventHandlers={{
              click: () => {
                onLocationClick(marker.position);
              }
            }}
          >
            <Popup>{marker.text}</Popup>
          </Marker>
        ))}
        <ChangeMapCenter center={center} />
      </MapContainer>
    </div>
  );
};

export default MapComponent;