import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userInterestUpdateValue } from "../../../StateManagement/slicers/userSlicer";
import "../../CSS file/personaldialog.css";
import SelectBox from "../../../Components/SelectBox";
import "../../CSS file/toggle.css";

export default function UnivercitiDialogbox() {
  const dispatch = useDispatch();
  const [toggleValue, setToggleValue] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();

  }

  return (
    <form>
      <div className="univerciti_main_contianer">
        <span className="text_no">No</span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={() => setToggleValue(!toggleValue)}
          />
          <span className="slider"></span>
        </label>
        <span className="text_no">Yes</span>
      </div>
      <div className="acc_details_main_contianer">
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Institute name 1"
            initialValue="Select Institute"
            options={[
              { optionName: "Toronto university", optionValue: "can1" },
              { optionName: "Toronto university", optionValue: "can" },
            ]}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Area"
            initialValue="Select area"
            options={[
              { optionName: "Toronto university", optionValue: "can1" },
              { optionName: "Toronto university", optionValue: "can" },
            ]}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Institute name 2"
            initialValue="Select Institute"
            options={[
              { optionName: "Toronto university", optionValue: "can1" },
              { optionName: "Toronto university", optionValue: "can" },
            ]}
          />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Area"
            initialValue="Select area"
            options={[
              { optionName: "Toronto university", optionValue: "can1" },
              { optionName: "Toronto university", optionValue: "can" },
            ]}
          />
        </div>
      </div>

      <div className="btn_submit_cntainer">
        <button className="submit_btn_dialog_box" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </form>
  );
}
