import React from "react";
import "../../CSS file/sharedialog.css";
const socialImg1 = require("../../../assets/social/fb.png");
const socialImg2 = require("../../../assets/social/insta.png");
const socialImg3 = require("../../../assets/social/linkedin.png");
const socialImg4 = require("../../../assets/social/twitter.png");

const allSocialImg = [
  {
    socialImg: socialImg1,
  },
  {
    socialImg: socialImg2,
  },
  {
    socialImg: socialImg3,
  },
  {
    socialImg: socialImg4,
  },
];

function ShareDialogBox(props) {
  return (
    <div className="share_main_dialog_box_container">
      <div className="share_container">
        {allSocialImg.map((perSocialImg) => {
          return (
            <a href="link" className="share_link_tab">
              <img
                loading="lazy"
                src={perSocialImg.socialImg}
                alt="social_img"
              />
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default ShareDialogBox;
