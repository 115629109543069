import React from "react";
import "../CSS file/applicationcontent.css";
import { BiLoader } from "react-icons/bi";
const clg_img = require("../../assets/clg_profile_pic.png");

function ApplicationContent(props) {
  const allDataOfAplication = [
    {
      universityImage: clg_img,
      universityName: `University of alberta `,
      universityDate: `Aug 2022`,
      courseName: `MBA Business and Management`,
      stageName: `Application Fee Pending`,
    },
    {
      universityImage: clg_img,
      universityName: `University of alberta `,
      universityDate: `Aug 2022`,
      courseName: `MBA Business and Management`,
      stageName: `Application Fee Pending`,
    },
  ];

  const renderDataOfApplication = allDataOfAplication.map((perApkData) => {
    return (
      <tr className="data_table_content">
        <td className="data_content_container">
          <div className="clg_img_container_circle">
            <img
              src={perApkData.universityImage}
              alt="clg_pic"
              className="clg_img_self"
              loading="lazy"
            />
          </div>
          <div className="univ_date_container">
            <p className="univ_title_text">{perApkData.universityName}</p>
            <p className="date_text_">Starting :{perApkData.universityDate} </p>
          </div>
        </td>
        <td>
          {" "}
          <p className="stage_text">{perApkData.courseName}</p>
        </td>
        <td>
          <div className="icon_progress_text">
            <BiLoader className="yellow_color_text" />{" "}
            <p className="progress_text yellow_color_text">In Progress</p>
          </div>
        </td>
        <td>
          <p className="stage_text">{perApkData.stageName}</p>
        </td>
        <td>
          <button className="view_btn_container">View</button>
        </td>
      </tr>
    );
  });
  return (
    <div className="application_content_box_container">
      <div className="application_main_content_table">
        <table className="heading_container">
          <tr className="data_table_content">
            <th>University</th>
            <th>Course</th>
            <th>Status</th>
            <th>Stage</th>
            <th>Action</th>
          </tr>
          {renderDataOfApplication}
        </table>
      </div>
    </div>
  );
}

export default ApplicationContent;
