// export function formatURLName(Name) {
//   return Name
//     .toLowerCase()
//     .replace(/ /g, "-") // Replace spaces with hyphens
//     .replace(/&/g, "and") // Replace '&' with 'and'
//     .replace(/[^\w-]+/g, ""); // Remove all non-word characters except hyphens
// }

export function formatURLName(Name) {
  return Name
    .toLowerCase()
    .replace(/ /g, "-") // Replace spaces with hyphens
    .replace(/&/g, "and") // Replace '&' with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters except hyphens
    .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
}