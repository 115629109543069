import React, { useEffect } from "react";
import { MdOutlineSearch } from "react-icons/md";
import { fetchsearchIntoCategoriesData } from "../StateManagement/slicers/userSlicer";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearch, fetchSearchCourse } from "../StateManagement/slicers/universitySlicer";

function CategorySearch(props) {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const { tabToRender } = useSelector((state) => state.university);

  const {universityTypeToRender} = useSelector(
    (state) => state.university
  );
  

  useEffect(() => {
    if(tabToRender){
      setSearch("")
    }
  }, [tabToRender]);




  useEffect(() => { 
    if (search.length > 0) {
      if(universityTypeToRender === "Universities"){

      dispatch(fetchSearch(true))
      }
       if(universityTypeToRender === "Courses"){
        dispatch(fetchSearchCourse(true))
      }
      if(universityTypeToRender === "Shortlisted Univercities"){
        
        dispatch(fetchSearch(true))

      }
    
    }else{
      if(universityTypeToRender === "Universities"){

        dispatch(fetchSearch(false))
        }else if(universityTypeToRender === "Courses"){
          dispatch(fetchSearchCourse(false))

          
    }
    if(universityTypeToRender === "Shortlisted Univercities"){
        
      dispatch(fetchSearch(false))
  }
    }
   

  }, [search,universityTypeToRender,]);

  


  function handleText(e) {
  
  if(universityTypeToRender === "Universities"){
    dispatch(
      fetchsearchIntoCategoriesData({
        search_text: e,
        search_into_category: "universities",
        
      }, universityTypeToRender)
    );
  }else if(universityTypeToRender === "Courses"){
   
    dispatch(
      fetchsearchIntoCategoriesData({
        search_text: e,
        search_into_category: "courses",
        
      }, universityTypeToRender)
    );
  }
  else if(universityTypeToRender === "Shortlisted Univercities"){
   
    dispatch(
      fetchsearchIntoCategoriesData({
        search_text: e,
        search_into_category: "shortlist_universities",
        
      }, universityTypeToRender)
    );
  }
  
  
  }
  return (
    <form className="connec_search_bar_contianer">
      <input
        type="search"
        className="input_search_conn"
        placeholder={`Search ${universityTypeToRender}...`}
        value={search}
        autoComplete="off"
        onChange={(e) => {setSearch(e.target.value)
          handleText(e.target.value);

        }}
      />
      <button
        className="btn_conn_search"
        
      >
        <MdOutlineSearch className="search_conn_self" />
      </button>
    </form>
  );
}

export default CategorySearch;
