// import React from "react"
// import { Navigate } from "react-router-dom"

// const ProtectedRoute = ({ children }) => {
//     const isAuth = localStorage.getItem("access_token") || undefined;
//     return isAuth ? children : <Navigate to="/" />;
// }
// export default ProtectedRoute;

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
    const isAuth = localStorage.getItem("access_token");
    return isAuth ? <Outlet /> : <Navigate to="/signin" />;
}

export default ProtectedRoute;
