import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { sendOtpRegister, verifyOtpRegister } from "../../Api/Api";
import Spinner from "../spinner/Spinner";
import OtpVerification from "./OtpVerification";
import sign_img from "../../assets/signup.png";

const OtpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export default function EmailVerification({ setShowSignUpForm, setVerifiedEmail }) {
  const [otpSent, setOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleSendOtp = async (values, { setSubmitting, setErrors }) => {
    setIsLoading(true);
    try {
      const response = await sendOtpRegister(values.email);
      if (response.flag) {
        setOtpSent(true);
        setEmail(values.email);
      } else {
        setErrors({ email: response.messages.email[0] });
      }
    } catch (error) {
      setErrors({ email: error.messages.email[0] });
    }
    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <>
      {!otpSent ? (
        <div className="main_container_sign_up">
          <div className="left_sign_up_container">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={OtpSchema}
              onSubmit={handleSendOtp}
            >
              {({ isSubmitting , errors , touched }) => (
                <Form>
                  <h6 className="create_text">Verify Your Email</h6>
                  <p className="create_extra_text">
                    We will send an OTP to your email
                  </p>

                  <h6 className="title_of_input">Email address</h6>
                  <Field
                    type="email"
                    name="email"
                    // className="sign_up_input"
                    className={`sign_up_input my-3 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />

                  <button
                    type="submit"
                    disabled={isSubmitting || isLoading}
                    className="btn_sign_in same_btn_sign_in"
                  >
                    {isSubmitting || isLoading ? <Spinner /> : "Send OTP"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="right_sign_up_container">
            <img
              className="img-fluid right_sing_up"
              src={sign_img}
              alt="img_sign_up"
              loading="lazy"
            />
          </div>
        </div>
      ) : (
        <OtpVerification email={email} setShowSignUpForm={setShowSignUpForm} setVerifiedEmail={setVerifiedEmail} />
      )}
    </>
  );
}