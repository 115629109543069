import React, { useState, useRef, useEffect } from "react";
import "../../CSS file/personaldialog.css";
import "../../../Components/InputTextBox/inputtext.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  updateUser,
  userInterestUpdateValue,
} from "../../../StateManagement/slicers/userSlicer.js";
import { useAlert } from "react-alert";

export default function PreffereDialogbox(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [times, setTimes] = useState([]);
  const [modes, setModes] = useState([])
  const [prefferedTimeContact, setPrefferedTimeContact] = useState("");
  const [prefferedModeContact, setPrefferedModeContact] = useState([]);

  const allTimeOfContact = [
    {
      timeLabel: "Morning (8 AM to Noon)",

    },
    {
      timeLabel: "Afternoon (12.15 PM to 4.45 PM)",

    },
    {
      timeLabel: "Evening (5PM to 10PM)",

    },
  ];

  const allModeOfContact = [
    {
      modeContacttxt: "Email",

    },
    {
      modeContacttxt: "Chat",

    },
    {
      modeContacttxt: "Call",

    },
  ];


  const handleSubmit = (e) => {
    e.preventDefault();
    // let arr = []

    if (prefferedTimeContact.length > 0) {
      dispatch(
        userInterestUpdateValue({
          time_of_contact: JSON.stringify(prefferedTimeContact),
        })
      );
      alert.success("Edit Preffered Details and Mode of contact Successfully")
    }
    if (prefferedModeContact.length > 0) {
      dispatch(
        userInterestUpdateValue({
          mode_of_contact: prefferedModeContact,
        })
      );
      // alert.success("Edit Mode of contact Successfully")
    }
    props.handleToggle(false);
  };

  useEffect(() => {
    setTimes(allTimeOfContact);
    setModes(allModeOfContact)

  }, []);




  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempTime = times.map((time) => {
        return { ...time, isChecked: checked };
      });
      if (checked === true) {
        let arr = []
        tempTime.map((time) => {

          arr.push(time.timeLabel)
        })
        setPrefferedTimeContact(arr)
      } else {
        setPrefferedTimeContact([])
      }
      setTimes(tempTime);
    } else {
      let tempTime = times.map((time) =>
        time.timeLabel === name ? { ...time, isChecked: checked } : time
      );

      setTimes(tempTime)
      if (checked === true) {
        setPrefferedTimeContact([...prefferedTimeContact, e.target.name])
      } else {
        let arr = prefferedTimeContact.filter((item) => {
          return item !== name
        })
        setPrefferedTimeContact(arr)

      }

    }
  };
  const handleModeChange = (e) => {

    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempMode = modes.map((mode) => {
        return { ...mode, isChecked: checked };
      });
      if (checked === true) {
        let arr = []
        tempMode.map((time) => {

          arr.push(time.modeContacttxt)
        })
        setPrefferedModeContact(arr)
      } else {
        setPrefferedModeContact([])
      }
      setModes(tempMode);
    } else {
      let tempUser = modes.map((mode) =>
        mode.modeContacttxt === name ? { ...mode, isChecked: checked } : mode
      );

      setModes(tempUser)
      if (checked === true) {
        setPrefferedModeContact([...prefferedModeContact, e.target.name])
      } else {
        let arr = prefferedModeContact.filter((item) => {
          return item !== name
        })
        setPrefferedModeContact(arr)

      }

    }
  };



  return (
    <form onSubmit={handleSubmit}>
      <div className="main_preffered_contianer">
        <div className="left_time_container">
          <div className="multibox_time_of_contact_contianer">
            <h6 className="heading_text_time">Time of contact</h6>
           

            {times.map((time, index) => (
              <div key={index}>
                <input
                  id={time.id}
                  type="checkbox"
                  name={time.timeLabel}
                  className="checkbox_self"
                  checked={time?.isChecked || false}
                  onChange={handleChange}
                  defaultChecked={time?.isChecked || false}
                />
                <label className="checkbox_label" htmlFor={time.id}>
                  {time.timeLabel}
                </label>
              </div>
            ))}
            <input
              type="checkbox"
              name="allSelect"
              className="checkbox_self"
              checked={!times.some((time) => time?.isChecked !== true)}
              onChange={handleChange}
            />
            <label className="checkbox_label">Anytime</label>
          </div>
        </div>
        <div className="right_time_container">
          <h6 className="heading_text_time">Mode of contact</h6>
          <div className="wrap_container">
            {modes.map((mode) => {
              return (
                <div key={Math.random()} className="checkbox_label_container1">
                  <input
                    id={mode.modeId}
                    type="checkbox"
                    checked={mode?.isChecked || false}
                    name={mode.modeContacttxt}
                    className="checkbox_self"
                    onChange={handleModeChange}
                  />
                  <label
                    htmlFor={mode.modeId}
                    className="checkbox_label"
                  >
                    {mode.modeContacttxt}
                  </label>
                  <br />
                </div>
              );
            })}
            <input
              type="checkbox"
              name="allSelect"
              className="checkbox_self"
              checked={!modes.some((mode) => mode?.isChecked !== true)}
              onChange={handleModeChange}
            />
            <label className="checkbox_label">ALL</label>

          </div>
        </div>
      </div>
      <div className="btn_submit_cntainer">
        <button type="submit" className="submit_btn_dialog_box">
          Submit
        </button>
      </div>
    </form>
  );
}
