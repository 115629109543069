import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCourses,
  fetchShortlistedCourses,
  fetchTopCourses,
  fetchPopularCourses,
  fetchFeaturedCourses,
  fetchPreferredCourses,
  fetchRecommendedCourses,
  fetchCoursesById,
} from "../../Api/Api";
import { selectLoader } from "./commonSlicer";

export const courseSlicer = createSlice({
  name: "course",
  initialState: {
    course: [],
    isLoading: false,
    shortlistedCourses: [],
    courseTypeToRender: [],
    featuredCourses: [],
    popularCourses: [],
    recommendedCourses: [],
    courseById: {},
    tabToRender: 2,
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    fetchCourseData: (state, action) => {
      state.course = action.payload.data;
      state.isLoading = false;
    },
    storeRenderingCourse: (state, action) => {
      state.courseToRender = action.payload;
    },
    storeShortlistedCourses: (state, action) => {
      state.shortlistedCourses = action.payload;
      state.isLoading = false;
    },
    storeCourseTypeToRender: (state, action) => {
      // state.courseTypeToRender = action.payload;
      state.courseTypeToRender = action.payload.type;
      state.tabToRender = action.payload.tab;
      state.isLoading = false;
      
    },
    storeTopCourses: (state, action) => {
    
      state.topCourses = action.payload;
      state.isLoading = false;
    },
    storeRecommendedCourses: (state, action) => {
    
      state.recommendedCourses = action.payload.data;
      state.isLoading = false;
    },
    storeFeaturedCourses: (state, action) => {
    
      state.featuredCourses = action.payload.data;
      state.isLoading = false;
    },
    storePopularCourses: (state, action) => {
    
      state.popularCourses = action.payload.data;
      state.isLoading = false;
    },
 
    storePreferredCourses: (state, action) => {
    
      state.preferred = action.payload;
      state.isLoading = false;
    },
    storeUniversityTypeToRender: (state, action) => {
      state.universityTypeToRender = action.payload.type;
      state.tabToRender = action.payload.tab;
    },
    storeCourseById: (state, action) => {
      state.courseById = action.payload;
    },
  },
});

export const {
  fetchCourseData,
  toggleLoader,
  storeRenderingCourse,
  storeShortlistedCourses,
  storeCourseTypeToRender,
  storeTopCourses,
  storeRecommendedCourses,
  storeFeaturedCourses,
  storePopularCourses,
  storePreferredCourses,
  storeUniversityTypeToRender,
  storeCourseById,
} = courseSlicer.actions;

export const fetchCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchCourses();
  dispatch(fetchCourseData(data));
};

export const getShortlistedCourse = () => async (dispatch) => {
  dispatch(selectLoader(true));
  const data = await fetchShortlistedCourses();
  dispatch(storeShortlistedCourses(data));
  dispatch(selectLoader(false));
};

export const getTopCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchTopCourses();

  dispatch(storeTopCourses(data));
};
export const getPopularCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchPopularCourses();
  dispatch(storePopularCourses(data));
};
export const getFeaturedCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchFeaturedCourses();

  dispatch(storeFeaturedCourses(data));
};
export const getRecommendedCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchRecommendedCourses();
  dispatch(storeRecommendedCourses(data));
};
export const getPreferredCourse = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchPreferredCourses();

  dispatch(storePreferredCourses(data));
};

export const fetchUniversityCoursesById = (id, name) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchCoursesById(id , name);

  dispatch(storeCourseById(data.data));
};



export default courseSlicer.reducer;
