import { configureStore, createListenerMiddleware } from "@reduxjs/toolkit";
import tokenSlicer from "./slicers/tokenSlicer";
import userSlicer from "./slicers/userSlicer";
import universitySlicer from "./slicers/universitySlicer";
import authSlicer from "./slicers/authSlicer";
import countrySlicer from "./slicers/countrySlicer";
import courseSlicer from "./slicers/courseSlicer";
import generalSlicer from "./slicers/generalSlicer";
import studySlicer from "./slicers/studySlicer";
import filterSlicer from "./slicers/filterSlicer";
import commonSlicer from "./slicers/commonSlicer";

export default configureStore({
  reducer: {
    common: commonSlicer,
    auth: authSlicer,
    user: userSlicer,
    token: tokenSlicer,
    university: universitySlicer,
    country: countrySlicer,
    course: courseSlicer,
    general: generalSlicer,
    study: studySlicer,
    filter: filterSlicer,
  },

});
