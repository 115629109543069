import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadUserDocuments,
  deleteUploadDocumentById,
} from "../StateManagement/slicers/userSlicer";
import "../Screens/CSS file/alertboxstyle.css";

function AlertBox(props) {
  const dispatch = useDispatch();
  function handleDelete() {
    props.toggleModal(false);
    dispatch(deleteUploadDocumentById(props.id));
  }

  return (
    <div className="alert_box_content_container">
      <p>Are you sure want to delete ??</p>

      <div className="two_btn_container">
        <button onClick={() => props.toggleModal(false)}>Cancel</button>
        <button onClick={handleDelete}>Yes, delete it</button>
      </div>
    </div>
  );
}

export default AlertBox;
