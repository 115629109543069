import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputText from "../../../Components/InputTextBox/InputText";
import "../../CSS file/personaldialog.css";
import SelectBox from "../../../Components/SelectBox";
import {
  addUserWorkExperience,
  fetchUserWorkExperienceById,
  updateUserWorkExperience,
} from "../../../StateManagement/slicers/userSlicer";
import { useAlert } from "react-alert";

function WorkExperienceDialogbox(props) {
  const alert = useAlert();
  const getWorkExperienceById = useSelector(
    (state) => state.user.getWorkExperienceById
  );

  const [userWorkExperience, setUserWorkExperience] = useState({});
  const [jobType, setJobType] = useState("");

  const [userWorkExperienceToUpdate, setUserWorkExperienceToUpdate] = useState(
    {}
  );

  const [userWorkExperienceValue, setUserWorkExperienceValue] = useState({});

  const [updateWorkExperience, setUpdateWorkExperience] = useState({});

  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserWorkExperienceById(props.id));
  }, []);

  useEffect(() => {
  }, [userWorkExperience]);

  useEffect(() => {
    setUserWorkExperience(user);
    setUserWorkExperienceValue(getWorkExperienceById);
  }, [user, getWorkExperienceById]);

  function handleUpdateValueSubmit(e) {
    e.preventDefault();
    dispatch(updateUserWorkExperience(updateWorkExperience, props.id));
    alert.success("Updated Work Experience");
  }

  function handleAddValueSubmit(e) {
    e.preventDefault();
    dispatch(addUserWorkExperience(userWorkExperienceToUpdate));
    props.handleToggle(false);
    alert.success("Added Work Experience")
  }

  if (props.id) {
    return (
      <div className="aspiration_dialogbox_container">
        <form onSubmit={handleUpdateValueSubmit}>
          <div className="two_input_container">
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Job title"
                inputType="text"
                placeholderText="Enter job"
                inputId="fname"
                name="fname"
                value={
                  userWorkExperienceValue?.job_title
                    ? userWorkExperienceValue?.job_title
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    job_title: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    job_title: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Company name"
                inputType="text"
                placeholderText="Enter Company name"
                inputId="lname"
                name="lname"
                value={
                  userWorkExperienceValue?.company_name
                    ? userWorkExperienceValue?.company_name
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    company_name: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    company_name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Company location"
                inputType="text"
                placeholderText="Enter Company location"
                inputId="compannyId"
                name="compannyId"
                value={
                  userWorkExperienceValue?.company_location
                    ? userWorkExperienceValue?.company_location
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    company_location: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    company_location: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Period"
                inputType="text"
                placeholderText="Enter (ex. 2016-2020)"
                inputId="pno"
                inputPattern="^-?[0-9]\d*\.?\d*$"
                name="number"
                value={
                  userWorkExperienceValue?.period
                    ? userWorkExperienceValue?.period
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    period: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    period: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Postal Zip code"
                inputType="number"
                placeholderText="Enter zip code"
                inputId="pno"
                inputPattern="^-?[0-9]\d*\.?\d*$"
                name="number"
                value={
                  userWorkExperienceValue?.postal_zip_code
                    ? userWorkExperienceValue?.postal_zip_code
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    postal_zip_code: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    postal_zip_code: e.target.value,
                  }));
                }}
              />
            </div>
            {/* <InputText
              headTextInput="Job type"
              inputType="text"
              placeholderText="Enter job type"
              inputId="jtype"
              name="job type"
              value={
                userWorkExperienceValue?.period
                  ? userWorkExperienceValue?.period
                  : ""
              }
              onChange={(e) => {
                setUserWorkExperienceValue((preValue) => ({
                  ...preValue,
                  period: e.target.value,
                }));
                setUpdateWorkExperience((preValue) => ({
                  ...preValue,
                  period: e.target.value,
                }));
              }}
            /> */}
            <div className="input_textarea_container">
              <h6 className="input_label_text">Details</h6>
              <textarea
                id="aboutId"
                name="about"
                placeholder="Details about work"
                className="textarea_about_style"
                value={
                  userWorkExperienceValue?.details
                    ? userWorkExperienceValue?.details
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceValue((preValue) => ({
                    ...preValue,
                    details: e.target.value,
                  }));
                  setUpdateWorkExperience((preValue) => ({
                    ...preValue,
                    details: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="btn_submit_cntainer">
            <button type="submit" className="submit_btn_dialog_box">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return (
      <div className="aspiration_dialogbox_container">
        <form onSubmit={handleAddValueSubmit}>
          <div className="two_input_container">
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Job title"
                inputType="text"
                placeholderText="Enter job (ex. Full stack developer)"
                inputId="fname"
                name="fname"
                value={
                  userWorkExperience?.user_work_experience?.job_title_id
                    ? userWorkExperience?.user_work_experience?.job_title_id
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    job_title_id: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      job_title_id: e.target.value,
                    },
                  }));
                }}
                // onChange={(e) =>
                // setUserWorkExperience((preValue) => ({
                //   ...preValue,
                //   jobTitle: e.target.value,
                // }))
              />
            </div>
            <div className="select_input_text_contianer">
              <SelectBox
                headTextInput="Job Type"
                initialValue={jobType ? jobType : "Select Job type"}
                selectValue={(e) => {
                  if (e === "Part Time") {
                    setUserWorkExperience((preValue) => ({
                      ...preValue,
                      job_type: e,
                    }));
                    setJobType(e);
                    setUserWorkExperienceToUpdate((preValue) => ({
                      ...preValue,
                      job_type: 0,
                    }));
                  } else {
                    setUserWorkExperience((preValue) => ({
                      ...preValue,
                      job_type: 1,
                    }));
                    setJobType(e);
                    setUserWorkExperienceToUpdate((preValue) => ({
                      ...preValue,
                      job_type: e,
                    }));
                  }
                }}
                options={[
                  { optionName: "Part Time", optionValue: "Part Time" },
                  { optionName: "Full Time", optionValue: "Full Time" },
                ]}
              />
            </div>
            {/* <InputText
              headTextInput="Job Type"
              inputType="text"
              placeholderText="Enter job type (ex. Full / Part time)"
              inputId="jtype"
              name="jtype"
              value={
                userWorkExperience?.user_work_experience?.job_type
                  ? userWorkExperience?.user_work_experience?.job_type
                  : ""
              }
              onChange={(e) => {
                setUserWorkExperienceToUpdate((preValue) => ({
                  ...preValue,
                  job_type: e.target.value,
                }));
                setUserWorkExperience((preValue) => ({
                  ...preValue,
                  user_work_experience: {
                    ...preValue.user_work_experience,
                    job_type: e.target.value,
                  },
                }));
              }}
            /> */}
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Company name"
                inputType="text"
                placeholderText="Enter Company name  (ex. google)"
                inputId="lname"
                name="lname"
                value={
                  userWorkExperience?.user_work_experience?.company_name
                    ? userWorkExperience?.user_work_experience?.company_name
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    company_name: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      company_name: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Company location"
                inputType="text"
                placeholderText="Enter Company location (ex. USA)"
                inputId="compannyId"
                name="compannyId"
                value={
                  userWorkExperience?.user_work_experience?.company_location
                    ? userWorkExperience?.user_work_experience?.company_location
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    company_location: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      company_location: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            {/* <InputText
              headTextInput="Period"
              inputType="text"
              placeholderText="Enter (ex. 2016-2020)"
              inputId="pno"
              inputPattern="^-?[0-9]\d*\.?\d*$"
              name="number"
              value={
                userWorkExperience?.user_work_experience?.period
                  ? userWorkExperience?.user_work_experience?.period
                  : ""
              }
              onChange={(e) => {
                setUserWorkExperienceToUpdate((preValue) => ({
                  ...preValue,
                  period: e.target.value,
                }));
                setUserWorkExperience((preValue) => ({
                  ...preValue,
                  user_work_experience: {
                    ...preValue.user_work_experience,
                    period: e.target.value,
                  },
                }));
              }}
            /> */}
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Start Date"
                inputType="date"
                placeholderText="Enter (ex. 2016-2020)"
                inputId="pno"
                inputPattern="^-?[0-9]\d*\.?\d*$"
                name="number"
                value={
                  userWorkExperience?.user_work_experience?.start_date
                    ? userWorkExperience?.user_work_experience?.start_date
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    start_date: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      start_date: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="End Date"
                inputType="date"
                placeholderText="Enter (ex. 2016-2020)"
                inputId="pno"
                inputPattern="^-?[0-9]\d*\.?\d*$"
                name="number"
                value={
                  userWorkExperience?.user_work_experience?.end_date
                    ? userWorkExperience?.user_work_experience?.end_date
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    end_date: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      end_date: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Postal Zip code"
                inputType="number"
                placeholderText="Enter zip code (ex. 142475)"
                inputId="pno"
                inputPattern="^-?[0-9]\d*\.?\d*$"
                name="number"
                value={
                  userWorkExperience?.user_work_experience?.postal_zip_code
                    ? userWorkExperience?.user_work_experience?.postal_zip_code
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    postal_zip_code: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      postal_zip_code: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input_textarea_container">
              <h6 className="input_label_text">Details</h6>
              <textarea
                id="aboutId"
                name="about"
                placeholder="Details about work"
                className="textarea_about_style"
                value={
                  userWorkExperience?.user_work_experience?.details
                    ? userWorkExperience?.user_work_experience?.details
                    : ""
                }
                onChange={(e) => {
                  setUserWorkExperienceToUpdate((preValue) => ({
                    ...preValue,
                    details: e.target.value,
                  }));
                  setUserWorkExperience((preValue) => ({
                    ...preValue,
                    user_work_experience: {
                      ...preValue.user_work_experience,
                      details: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
          <div className="btn_submit_cntainer">
            <button type="submit" className="submit_btn_dialog_box">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default WorkExperienceDialogbox;
