import React, { useState, useEffect } from "react";
import "../CSS file/editprofile.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import EditProfileLeftSide from "./EditProfileLeftSide";
import EditProfileRightSide from "./EditProfileRightSide";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoggedInUser } from "../../StateManagement/slicers/userSlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { fetchCountriesData } from "../../StateManagement/slicers/countrySlicer";
import FloatingShortlistedBtn from "../../Components/FloatingShortlistedBtn";

export default function Editprofile() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);

  useEffect(() => {
    dispatch(fetchApiBearerToken());
    dispatch(fetchCountriesData());
  }, []);

  useEffect(() => {
    if (token != "" && token != undefined && token != null) {
      dispatch(fetchLoggedInUser());
    }
  }, [token]);

  return (
    <div className="main_edit_profile_contianer">
      <CommonHeader />
      <div className="edit_profile_main_container">
        <div className="left_side_edit_profile_contianer">
          <EditProfileLeftSide />
        </div>
        <div className="right_side_edit_profile_contianer">
          <EditProfileRightSide />
        </div>
      </div>
      <FloatingShortlistedBtn />
    </div>
  );
}
