import React, { useState, useEffect } from "react";
import ApplicationContent from "./ApplicationContent";
import VisaContent from "./VisaContent";

function ApplicationVisaMain(props) {
  const [toggleState, setToggleState] = useState(1);
  const [totalTabs, setTotalTabs] = useState([
    {
      tab: 1,
      tabText: "Application Management",
    },
    {
      tab: 2,
      tabText: "Visa Management",
    },
  ]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const renderTabs = totalTabs.map((perTab) => {
    return (
      <button

        className={toggleState === perTab.tab ? "tabs1 active-tabs1" : "tabs1"}
        onClick={() => toggleTab(perTab.tab)}
        key={Math.random()}
      >
        {perTab.tabText}
      </button>
    );
  });
  return (
    <div className="application_visa_container">
      <div className="">
        <div className="bloc-tabs1">{renderTabs}</div>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <ApplicationContent />
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <VisaContent />
        </div>
        <hr />
      </div>
    </div>
  );
}

export default ApplicationVisaMain;
