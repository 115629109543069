import React, { useEffect, useState } from "react";
import forgot_img from "../../assets/forgot.png";
import "../CSS file/forgot.css";
import SignHeader from "../../Components/Sign Header/SignHeader";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { sendOtpResetPassword } from "../../Api/Api";

export default function ForgotPwd() {
  const navigate = useNavigate();
  const alert = useAlert();
  const [resetEmailInput, setResetEmailInput] = useState("");
  const [allResetData, setAllResetData] = useState([]); //   when all entry are saved in array
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();

    try {
      const response = await sendOtpResetPassword(email);

      if (response.code === 422) {
        alert.show(response.messages.email[0], { type: 'error' });
      } else {
        alert.show(response.messages, { type: 'success' });
        navigate('/Verify', { state: { email } });
      }
    } catch (error) {
      setError('Failed to send OTP. Please try again.');
      alert.show('Failed to send OTP. Please try again.', { type: 'error' });
    }
  };


  function handleResetInput(e) {
    setResetEmailInput(e.target.value);
  }

  async function resetBtn(e) {
    e.preventDefault();

    const resetEntry = { resetEmail: resetEmailInput };
    setAllResetData([...allResetData, resetEntry]);


    setResetEmailInput("");
    navigate("/Verify");
  }

  return (
    <>
      <SignHeader />

      <div className="forgot_main_container">
        <div className="forgot_under_contianer">
          <img
            loading="lazy"
            src={forgot_img}
            className="img-fluid forgot_img"
          />

          <div className="reset_content_contianer">
            <h6 className="reset_pwd_text">Reset your password</h6>
            {/* {error && <div>{error}</div>} */}
            <p className="reset_extra_content">
              Enter your username or email address and we will send you a link
              to reset your password.
            </p>

            {/* <form className="text_input_container" onSubmit={resetBtn}> */}
            <form className="text_input_container" onSubmit={handleSendOtp}>
              <input
                type="text"
                id="resetEmail"
                placeholder="Enter username or email address"
                className="reset_input"
                name="resetEmail"
                required
                autoComplete="off"
                // value={resetEmailInput}
                // onChange={(e) => handleResetInput(e)}
                value={email}
                onChange={handleEmailChange}
              />
              <button type="submit" className="reset_btn_self">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
