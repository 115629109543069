import React, { useState } from "react";
import "./commonheader.css";

import { BsBell } from "react-icons/bs";

import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./notification.css";
import avtar_notify from "../../assets/profile_pic.jpg";

const allNotifyItems = [
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
  {
    notifyTitle: "Socrates Itumay",
    notifySubtitle:
      "nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so..nam libero tempore cum so...",
    notifyTime: "Mar 15 12:32pm",
  },
];

export default function Notification() {
  const renderNotifyItems = allNotifyItems.map((perNotify) => {
    return (
      <MenuItem key={Math.random()}>
        <div className="item_notfication_container">
          <div className="avtar_contianer">
            <img
              className="notifi_avtar"
              alt="avtar"
              loading="lazy"
              src={avtar_notify}
            />
          </div>

          <div className="title_subtitle_container">
            <h6 className="title_notifiy_text">{perNotify.notifyTitle}</h6>
            <p className="sub_tiite_notify_text">{perNotify.notifySubtitle}</p>
            <span className="time_notify_text">{perNotify.notifyTime}</span>
          </div>
        </div>
      </MenuItem>
    );
  });
  return (
    <Menu
      menuButton={
        <button className="search_btn">
          <BsBell className="icon_search_self" />
        </button>
      }
      transition
      direction="bottom"
    >
      <div className="menu_contaier">
        <header>
          <div className="header_notification_main_container">
            <h6 className="notification_title">Notification</h6>

            <button className="mark_as_container">
              <h6 className="mark_all_read_text">Mark All Read</h6>
            </button>
          </div>
          <hr className="line_notifcation" />
        </header>

        {renderNotifyItems}
      </div>
    </Menu>
  );
}
