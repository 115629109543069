import React, { useEffect, useState, useRef } from "react";
import SelectBox from "../../../Components/SelectBox";
import "../../CSS file/personaldialog.css";
import { fetchStudyAreas } from "../../../StateManagement/slicers/studySlicer";
import {
  fetchPrograms,
  fetchIntakes,
  fetchIntakesYear,
} from "../../../StateManagement/slicers/studySlicer";
import {
  fetchSpecializationsById,
  fetchUniversityIntakesById,
  fetchIntakeYearsById,
} from "../../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  updateUser,
  userInterestUpdateValue,
} from "../../../StateManagement/slicers/userSlicer.js";
import LoaderFullPage from "../../../Components/LoaderFullPage";
import { useAlert } from "react-alert";

function AspirationDialogbox(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { user, isLoading } = useSelector((state) => state.user);
  const [userProfileUpdate, setUserProfileUpdate] = useState({});
  const { programLevels, intakes, intakeYears } = useSelector(
    (state) => state.study
  );
  const specializations = useSelector((state) => state.study.specializations);
  const userDetailsToUpdate = useRef({});
  const [programLevelsToRender, setProgramLevelsToRender] = useState([]);
  const [intakesToRender, setIntakesToRender] = useState([]);
  const [intakeYearsToRender, setIntakeYearsToRender] = useState([]);
  const [desiredSpecialization, setDesiredSpecialization] = useState("");
  const [loaderValue, setLoaderValue] = useState(props.handleLoader);
  const [desiredYear, setDesiredYear] = useState("");
  const [desiredIntake, setDesiredIntake] = useState("");
  const [studyLevel, setStudyLevel] = useState([]);

  useEffect(() => {
    setUserProfileUpdate(user);
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user?.desired_study_level) {
        let arr = [];
        user?.desired_study_level.forEach((item) => {
          arr.push(item.program_level_name + " " + ",");
        });
        
        setStudyLevel(JSON.stringify(arr));
      }
    }
    if (user) {
      if (user?.desired_intake) {
        let arr = [];
        user?.desired_intake.forEach((item) => {
          arr.push(item.intake_name + " " + ",");
        });
        
        setDesiredIntake(JSON.stringify(arr));
      }
    }
    if (user) {
      if (user?.desired_intake) {
        let arr = [];
        user?.desired_intake.forEach((item) => {
          arr.push(item.intake_name + " " + ",");
        });
        
        setDesiredIntake(JSON.stringify(arr));
      }
    }
  }, [user]);

  useEffect(() => {
    async function checkSpecialization() {
      if (user?.user_interests_and_background?.desired_specialization) {
        let value = user?.user_interests_and_background?.desired_specialization;
        let res = await fetchSpecializationsById(value);
        setDesiredSpecialization(res.data.name);
        setLoaderValue(false);
      }
    }

    async function checkYear() {
      if (user?.user_interests_and_background?.desired_year_of_admission) {
        let value =
          user?.user_interests_and_background?.desired_year_of_admission;
        let res = await fetchIntakeYearsById(value);
        setDesiredYear(res.data.year_numeric);
      }
    }

    checkSpecialization();
    checkYear();
  }, []);
  useEffect(() => {
    if (programLevels.length > 0) {
      setProgramLevelsToRender(programLevels);
    }
    if (intakes.length > 0) {
      setIntakesToRender(intakes);
    }
    if (intakeYears.length > 0) {
      setIntakeYearsToRender(intakeYears);
    }
  }, [programLevels, intakes, intakeYears]);
  useEffect(() => {
    dispatch(fetchPrograms());
    dispatch(fetchIntakes());
    dispatch(fetchIntakesYear());
    dispatch(fetchStudyAreas());
  }, [dispatch]);


  const aspirationInfo = useRef({
    desired_study_level: "",
    desired_year_of_admission: "",
    desired_specialization: "",
    desired_intake: "",
  });

  const handleSubmitAspiration = (e) => {
    e.preventDefault();

    dispatch(userInterestUpdateValue(userDetailsToUpdate.current));
    alert.success("Updated study aspirations details")
    props.handleToggle(false);
  };
  return (
    <>
      {loaderValue ? <LoaderFullPage /> : null}
      <div className="aspiration_dialogbox_container">
        <form onSubmit={handleSubmitAspiration}>
          <div className="acc_details_main_contianer">
            <div className="select_input_text_contianer">
              <SelectBox
                headTextInput="Study Level"
                initialValue={
                  studyLevel.length > 0
                    ? JSON.parse(studyLevel)
                    : "Select study level"
                }
                selectValue={(e) => {
                  aspirationInfo.current["desired_study_level"] = e;
                  userDetailsToUpdate.current["desired_study_level"] = e;
                }}
                options={
                  programLevelsToRender.length > 0
                    ? programLevelsToRender.map((program) => {
                      return {
                        optionName: program.program_level_name,
                        optionValue: program.program_level_name,
                      };
                    })
                    : []
                }
              />
            </div>

            <div className="select_input_text_contianer">
              <SelectBox
                headTextInput="Year Of Admission"
                initialValue={desiredYear ? desiredYear : "Select year"}
                options={
                  intakeYearsToRender.length > 0
                    ? intakeYearsToRender.map((year) => {
                      return {
                        optionName: year.year_numeric,
                        optionValue: year.year_numeric,
                      };
                    })
                    : []
                }
                selectValue={(e) => {
                  aspirationInfo.current["desired_year_of_admission"] = e;
                  userDetailsToUpdate.current["desired_year_of_admission"] = e;
                }}
              />
            </div>

            <div className="select_input_text_contianer">
              <SelectBox
                headTextInput="Desired Intake"
                initialValue={
                  desiredIntake.length > 0
                    ? JSON.parse(desiredIntake)
                    : "Select intake"
                }
                // options={[
                //   { optionName: "Open", optionValue: "Open" },
                //   { optionName: "Regular", optionValue: "Regular" },
                // ]}
                options={
                  intakesToRender.length > 0
                    ? intakesToRender.map((program) => {
                      return {
                        optionName: program.intake_name,
                        optionValue: program.intake_month,
                      };
                    })
                    : []
                }
                selectValue={(e) => {
                  aspirationInfo.current["desired_intake"] = e;
                  userDetailsToUpdate.current["desired_intake"] = e;
                }}
              />
            </div>

            <div className="select_input_text_contianer">
              <SelectBox
                headTextInput="Desired Specialization"
                initialValue={
                  desiredSpecialization
                    ? desiredSpecialization
                    : "Select option"
                }
                options={
                  specializations.length > 0
                    ? specializations.map((specialization) => {
                      return {
                        optionName: specialization.name,
                        optionValue: specialization.name,
                      };
                    })
                    : []
                }
                selectValue={(e) => {
                  aspirationInfo.current["desired_specialization"] = e;
                  userDetailsToUpdate.current["desired_specialization"] = e;
                }}
              />
            </div>
          </div >
          <div className="btn_submit_cntainer">
            <button className="submit_btn_dialog_box">Submit</button>
          </div>
        </form >
      </div >
    </>
  );
}

export default AspirationDialogbox;
