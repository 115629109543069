import { verifyOtpRegister } from "../../Api/Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import Spinner from "../spinner/Spinner";
import sign_img from "../../assets/signup.png";

const OtpVerificationSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

function OtpVerification({ email, setShowSignUpForm, setVerifiedEmail }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleVerifyOtp = async (values, { setSubmitting, setErrors }) => {
    setIsLoading(true);
    try {
      const response = await verifyOtpRegister(email, values.otp);
      if (response.flag) {
        setVerifiedEmail(email);
        setShowSignUpForm(true);
      } else {
        setErrors({ otp: "OTP is invalid" });
      }
    } catch (error) {
      setErrors({ otp: error.messages });
    }
    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <div className="main_container_sign_up">
      <div className="left_sign_up_container">
        <Formik
          initialValues={{ otp: "" }}
          validationSchema={OtpVerificationSchema}
          onSubmit={handleVerifyOtp}
        >
          {({ isSubmitting , errors ,touched }) => (
            <Form>
              <h6 className="create_text">Enter OTP</h6>
              <p className="create_extra_text">Check your email for the OTP</p>

              <h6 className="title_of_input">OTP</h6>
              <Field
                type="text"
                name="otp"
                // className="sign_up_input"
                className={`sign_up_input my-3 ${errors.otp && touched.otp ? 'is-invalid' : ''}`}
                placeholder="Enter OTP"
              />
              <ErrorMessage
                name="otp"
                component="div"
                className="error-message"
              />

              <button
                type="submit"
                disabled={isSubmitting || isLoading}
                className="btn_sign_in same_btn_sign_in"
              >
                {isSubmitting || isLoading ? <Spinner /> : "Verify OTP"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="right_sign_up_container">
        <img
          className="img-fluid right_sing_up"
          src={sign_img}
          alt="img_sign_up"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default OtpVerification;