import React, { useState, useEffect } from "react";
import "../CSS file/viewdetailsunivercity.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchUniversityById } from "../../StateManagement/slicers/universitySlicer";
import FactBox from "./FactBox";
import FactboxSkeleton from "../../Components/skeleton loader/FactboxSkeleton";
import MapComponent from "./Map";
const canada_img = require("../../assets/canada_img.png");
const univer_img = require("../../assets/school.png");
const established_img = require("../../assets/flag.png");
const global_rank_img = require("../../assets/Group 16.png");
const quick_fact_img = require("../../assets/iconsDetailPage/univ1.png");
const quick_fact_img1 = require("../../assets/iconsDetailPage/univ2.png");
const quick_fact_img2 = require("../../assets/iconsDetailPage/univ3.png");
const quick_fact_img3 = require("../../assets/iconsDetailPage/univ4.png");
const quick_fact_img4 = require("../../assets/iconsDetailPage/univ5.png");
const attention_img = require("../../assets/attention.png");

function DetailsOfUnivercityLeftRight(props) {
  let dispatch = useDispatch();
  const initialCenter = [53.2638, -113.5568]; 
  const [center, setCenter] = useState(initialCenter);

  const handleLocationClick = (position) => {
    setCenter(position);
  };
  const markers = [
    { position: [53.5461, -113.4938], text: "Edmonton" },
    
  ];
  const universityToRender = useSelector(
    (state) => state.university.universityToRender
  );
  useEffect(() => {
    if (localStorage.getItem("university_id")) {
      let id = localStorage.getItem("university_id");
      dispatch(fetchUniversityById(id));
    }
  }, []);

  const allSummaryUnniverData = [
    {
      summaryUniverData: universityToRender?.data?.university_description
        ? universityToRender?.data?.university_description
        : "-------",
    },
  ];

  const allDetailsData = [
    {
      imgName:
        universityToRender?.data?.university_locations[0]
          ?.university_in_country[0]?.country_flag_icon,
      contentName:
        universityToRender?.data?.university_locations[0]
          ?.university_in_country[0]?.country_name,
    },
    {
      imgName: global_rank_img,
      contentName:
        "Global Rating " +
        "(" +
       ( universityToRender?.data?.global_rating ? universityToRender?.data?.global_rating : 0) +
        "/5)",
    },
    {
      imgName: established_img,
      contentName: "Established in " + universityToRender?.data?.founded,
    },
    {
      imgName: univer_img,
      contentName: (universityToRender?.data?.institution_type ? universityToRender?.data?.institution_type : "-") + "University",
      // ) : (
      //   <div className="img_text_contaier_unive_view_details">
      //     <div className="canada_img_univ_view_details skeleton skeleton_text skeleton_width" />
      //     <div className="text_sub_uiver_view_details skeleton skeleton_text skeleton_width"></div>
      //   </div>
      // ),
    },
  ];

  const renderDetailsOfUniversity = allDetailsData.map((perdetail) => {
    return (
      <>
        <div className="img_text_contaier_unive_view_details">
          <img
            loading="lazy"
            src={perdetail.imgName}
            alt="countryLogo"
            className="canada_img_univ_view_details "
          />
          <div style={{ width: "90%" }} className="text_sub_uiver_view_details">
            {perdetail.contentName}
          </div>
        </div>
      </>
    );
  });

  return (
    <div className="left_right_view_details_contianer">
      <div className="left_view_details_container">
        <h5 className="view_details_heading_text">Details of College</h5>

        {renderDetailsOfUniversity}


        <div className="summary_text_content_view_details">
          <h5 className="view_details_heading_text">Summary</h5>
          {allSummaryUnniverData.map((perSummaryUniver) => {
            return (
              <div
                className="paragraph_view_details_text"
                key={Math.random()}
                dangerouslySetInnerHTML={{
                  __html: perSummaryUniver?.summaryUniverData
                    ? perSummaryUniver.summaryUniverData
                    : "Loading ...",
                }}
              />
            );
          })}
        </div>
      </div>

      <p>{universityToRender?.university_locations?.location_link} l</p>
      <div className="right_view_details_container">
        <h5 className="view_details_heading_text">Quick facts</h5>
        <FactBox
          valueToRender={[
            {
              factBoxImg: quick_fact_img,
              factBoxTitle: "Scolarship",
              factBoxSubTitle: universityToRender?.data?.scholarship_available
                ? universityToRender?.data?.scholarship_available
                : "---",
            },
            {
              factBoxImg: quick_fact_img1,
              factBoxTitle: "Test Accepted",
              factBoxSubTitle: "GRE / IELTS / TOEFEL",
            },
            {
              factBoxImg: quick_fact_img2,
              factBoxTitle: "Intake",
              factBoxSubTitle: "JUL, JAN , MAR",
            },
            {
              factBoxImg: quick_fact_img3,
              factBoxTitle: "Avg living cost / year",
              factBoxSubTitle: "$ 25053",
            },
            {
              factBoxImg: quick_fact_img4,
              factBoxTitle: "Avg tution / year",
              factBoxSubTitle: "$ 35053",
            },
          ]}
        />


        <h5 className="view_details_heading_text">Location</h5>
     
                  <MapComponent center={center} markers={markers} onLocationClick={handleLocationClick} />

      </div>
    </div>
  );
}

export default DetailsOfUnivercityLeftRight;
{/* {universityToRender?.data?.university_locations[0]
          ?.university_in_country[0]?.country_flag_icon ? (
          <div className="img_text_contaier_unive_view_details">
            <img
              src={
                universityToRender?.data?.university_locations[0]
                  ?.university_in_country[0]?.country_flag_icon
                  ? universityToRender?.data?.university_locations[0]
                      ?.university_in_country[0]?.country_flag_icon
                  : "----"
              }
              alt="countryLogo"
              className="canada_img_univ_view_details"
            />
            <h6 className="text_sub_uiver_view_details">
              {universityToRender?.data?.university_locations[0]
                ?.university_in_country[0]?.country_name
                ? universityToRender?.data?.university_locations[0]
                    ?.university_in_country[0]?.country_name
                : "----"}
            </h6>
          </div>
        ) : (
          <div className="img_text_contaier_unive_view_details">
            <div className="canada_img_univ_view_details skeleton skeleton_text skeleton_width" />
            <div className="text_sub_uiver_view_details skeleton skeleton_text skeleton_width"></div>
          </div>
        )} */}

{/* <div className="img_text_contaier_unive_view_details">
          <img
            src={global_rank_img}
            alt="global_rank_img"
            className="canada_img_univ_view_details"
          />
          <h6 className="text_sub_uiver_view_details">
            Global Ranking (
            {universityToRender?.data?.student_on_app_rating
              ? universityToRender?.data?.student_on_app_rating
              : "-"}
            /5)
          </h6>
        </div>
        <div className="img_text_contaier_unive_view_details">
          <img
            src={established_img}
            alt="established_img"
            className="canada_img_univ_view_details"
          />
          <h6 className="text_sub_uiver_view_details">
            {universityToRender?.data?.founded
              ? "Established in " + universityToRender?.data?.founded
              : "Established in " + "------"}
          </h6>
        </div>
        <div className="img_text_contaier_unive_view_details">
          <img
            src={univer_img}
            alt="univer_img"
            className="canada_img_univ_view_details"
          />
          <h6 className="text_sub_uiver_view_details">
            {universityToRender?.data?.institution_type
              ? universityToRender?.data?.institution_type
              : "-----"}{" "}
            University
          </h6>
        </div> */}

