import React, { useEffect } from "react";
import "../CSS file/welcomepage.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoggedInUser,
  fetchLoggedInUser,
  selectUserFullNameParams
} from "../../StateManagement/slicers/userSlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";

const robo_icon = require("../../assets/robo_hi.png");

function WelcomePage(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.token.token);
  const fullNameParams = useSelector(selectUserFullNameParams);

  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, [dispatch]);

  useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      dispatch(fetchLoggedInUser());
    }
  }, [token, dispatch]);

  return (
    <section className="main_welcome_page_main_container">
      <div className="welcome_page_under_main_container">
        <div className="welcome_page_robo_icon_all_content_container">
          <div className="robo_icon_container">
            <img src={robo_icon} alt="robo_icon" loading="lazy" />
          </div>
          <div className="content_text_container_wc">
            <p className="title_for_welcome_text">
              Hello {user?.username || user?.email} , good to have you <br />{" "}
              here &#128075;
            </p>
            <p className="subtitle_for_wc_text">
              My name is Rainbow and I'm here to help you with your student
              journey. To <br /> do this, I'll need some basic information about
              you and study <br /> aspirations.
            </p>

            <strong className="ready_text">
              Ready to aim for the stars? &#128640;
            </strong>
          </div>
          <button
            type="button"
            className="sure_btn_self"
            onClick={() => {
              dispatch(updateLoggedInUser({ no_of_steps_followed: 1 }));
              navigate("/PersonalPage");
            }}
          >
            Sure,let's start!
          </button>

          <button
            type="button"
            className="later_btn_self"
            onClick={() => {
              dispatch(
                updateLoggedInUser({
                  no_of_steps_followed: 1,
                  is_fresh_user: false,
                })
              );
              // navigate("/Viewprofile");
              // navigate(`/Viewprofile/${fullNameParams}`);
              navigate(`/feeds`);
            }}
          >
            No,I"ll do it later
          </button>
        </div>
      </div>
    </section>
  );
}

export default WelcomePage;
