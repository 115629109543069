import React, { useEffect, useState } from "react";
import "../CSS file/connright.css";
import PeopleImgTitle from "../../Components/people you may know/PeopleImgTitle";
import TitleNumberFile from "../../Components/Title and number/TitleNumberFile";
import { useSelector } from "react-redux";
const people_img = require("../../assets/profile_pic.jpg");

export default function ConnectionRightSide() {
  const { tabToRender, userConnectionList } = useSelector((state) => state.user);
  console.log('userConnectionList', userConnectionList)

  useEffect(()=>{
    // if(userConnectionList.data.people_you_may_know
     
    // ){

    // }
    console.log('userConnectionList.data.people_you_may_know', userConnectionList?.data?.people_you_may_know)
  },[userConnectionList])



  return (
    <div className="conectin_under_right_side_contianer">
      <h6 className="people_main_title_text">People You May Know</h6>
      <PeopleImgTitle
        valueToRender={
          userConnectionList?.data?.people_you_may_know.map((i)=>(
            {
              people_img: i?.user_personal_profile?.profile_pic || people_img,
              peopleTitle: i?.user_personal_profile?.first_name,
              peopleSubTitle: i?.role,
            }
          ))
           
      }

      />

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY POSITION</h6>

        <TitleNumberFile
       
          valueToRender={
            userConnectionList?.data?.people_you_may_know?.map((i)=>(
              {
                titlePostion: i?.user_personal_profile?.first_name,
              }
            ))
             
        }
        />
      </div>

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">DISCOVER BY LOCATION</h6>

        <TitleNumberFile
      

          valueToRender={
            userConnectionList?.data?.discover_by_location?.map((i)=>(
              {
                titlePostion: i?.user_personal_profile?.first_name,
              }
            ))
             
        }
        />
      </div>
    </div>
  );
}
