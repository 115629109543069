import React, { useEffect, useState } from "react";
import "./titlenumber.css";

function TitleNumberFile(props) {
  const [positionTitleNumber, setPositionTitleNumber] = useState([]);

  useEffect(() => {
    if (props.valueToRender?.length > 0) {
      setPositionTitleNumber(props.valueToRender);
    }
  }, [props.valueToRender]);

  function handleDiscover() {
    
  }

  const renderAllTitleNumber = positionTitleNumber.map((perTitleNumbber) => {
    return (
      <button
        className="title_number_conn_contianer"
        key={Math.random()}
        onClick={handleDiscover}
      >
        <h6 className="title_conn_text">{perTitleNumbber.titlePostion}</h6>
        <span className="subtitle_conn_text">
          {perTitleNumbber.numberPostion}
        </span>
      </button>
    );
  });
  return <>{positionTitleNumber.length > 0 ? renderAllTitleNumber : null}</>;
}

export default TitleNumberFile;
