import React, { useState, useRef } from "react";
import "../../CSS file/budgetdialogbox.css";
import { useDispatch, useSelector } from "react-redux";
import { userInterestUpdateValue } from "../../../StateManagement/slicers/userSlicer";
import { useAlert } from "react-alert";

function BudgetDialogbox(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [studyBudget, setStudyBudget] = useState("");

  const allBudget = [
    {
      budgetLabel: "Below 25,000 USD",
      id: "1",
    },
    {
      budgetLabel: "25,000 - 50,000 USD",
      id: "2",
    },
    {
      budgetLabel: "50,000 - 100,000 USD",
      id: "3",
    },
    {
      budgetLabel: "Above 100,000 USD",
      id: "4",
    },
  ];

  function handleSubmit(e) {
    e.preventDefault();

    dispatch(userInterestUpdateValue({ budget: studyBudget }));
    alert.success("Updated study budget details");
    props.handleToggle(false);
  }
 

  function handleRadioClick(budgetLabel) {
    setStudyBudget(budgetLabel);
  }

  return (
    <div className="aspiration_dialogbox_container">
      <form>
        <h6 className="heading_text_time">Select desired destination</h6>
        <div className="main_destination_contianer">
          {allBudget.map((perDestination) => {
            return (
              <div key={Math.random()} className="checkbox_label_container3">
                <label className="row_contianer_budget">
                  {perDestination.budgetLabel}
                  <input
                    type="radio"
                    id={perDestination.id}
                    name="radio"
                    checked={studyBudget === perDestination.budgetLabel}
                    onClick={() => handleRadioClick(perDestination.budgetLabel)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            );
          })}
        </div>

        <div className="btn_submit_cntainer">
          <button
            type="submit"
            className="submit_btn_dialog_box"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default BudgetDialogbox;
