import React, { useState, useEffect } from "react";
import "../CSS file/univermain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import UniverCourseLeftSide from "./UniverCourseLeftSide";
import UniverRightSide from "./UniverRightSide";
import CourseRightSide from "./Courses/CourseRightSide";
import {
  fetchUniversity,
  fetchUniversityCourses,
  getFeaturedUniversity,
  getTopUniversity,
  getRecommendedUniversity,
  getPopularUniversity,
  getShortlistedUniversity,
} from "../../StateManagement/slicers/universitySlicer";

import { useSelector, useDispatch } from "react-redux";
import CategorySearch from "../../Components/CategorySearch";
import FloatingShortlistedBtn from "../../Components/FloatingShortlistedBtn";
import { useNavigate } from "react-router-dom";

export default function UnivercityMain() {
 
  const navigate = useNavigate();


  const universityTypeToRender = useSelector(
    (state) => state.university.universityTypeToRender
  );
  const [tabData, setTabData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUniversity());
    dispatch(fetchUniversityCourses());
    dispatch(getTopUniversity());
    dispatch(getPopularUniversity());
    dispatch(getFeaturedUniversity());
    dispatch(getRecommendedUniversity());
    dispatch(getShortlistedUniversity());
  }, []);


  return (
    <>
      <div className="main_connection_contianer">
        <CommonHeader />
        <div className="conn_title_search_bar_contianer">
          <h4 className="conn_title">{universityTypeToRender}</h4>

          <CategorySearch />
        </div>
        <div className="filter_container_univercity">
          <button
            className="filter_btn_univercity"
            onClick={() => navigate("/Filtermain")}
          
          >
            Filter
          </button>
        </div>

        <hr className="conn_line" />

        <div className="connection_under_main_container">
          <div className="left_side_connection_contianer">
            <UniverCourseLeftSide />
          </div>
          <div className="right_side_connection_contianer">
            {tabData === 2 ? <CourseRightSide /> : <UniverRightSide />}
          </div>
        </div>

        <FloatingShortlistedBtn />
      </div>
    </>
  );
}
