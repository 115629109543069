import React from "react";
import { BsThreeDots } from "react-icons/bs";
import {
  MdThumbUpOffAlt,
  MdThumbUp,
  MdOutlineChatBubbleOutline,
  MdIosShare,
} from "react-icons/md";
import "../../";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";

import BoxTextSubTextSkeletonLoader from "./BoxTextSubTextSkeletonLoader";

export default function PostMiddleSkeleton(props) {
  return (
    <>
      {" "}
      <div className="" key={Math.random()}>
        <div className="latest_profile_name_subname_timeperiod_container">
          <BoxTextSubTextSkeletonLoader />
          <span className="five_hour_text">
            <Menu
              direction="left"
              menuButton={
                <MenuButton>
                  {" "}
                  <BsThreeDots />
                </MenuButton>
              }
            >
              <div>
                <MenuItem value="red">Remove post</MenuItem>
              </div>
            </Menu>
          </span>
        </div>
        <div style={{ marginLeft: "5%" }}>
          <div className=" skeleton skeleton_text skeleton_width" />
          <div className=" skeleton skeleton_text skeleton_width" />
          <div className=" skeleton skeleton_text skeleton_width_short" />
        </div>
        <div className="skeleton skeleton_img skeleton skeleton_text" />
        <div
          style={{ width: "10%", marginBottom: "2%" }}
          className="like_count_modal_container skeleton skeleton_text "
        />
        <div className="like_comment_share_container">
          <button className="comment_contaier">
            <span className="like_text">Like</span>

            <MdThumbUpOffAlt className="like_icon" />
          </button>

          <button className="comment_contaier">
            <span className="like_text">Comment</span>

            <MdOutlineChatBubbleOutline className="like_icon" />
          </button>

          <button className="comment_contaier">
            <span className="like_text">Share</span>
            <MdIosShare className="like_icon" />
          </button>
        </div>{" "}
        <div className="space_box" />
      </div>
    </>
  );
}
