// // old
// import React, { useState } from "react";
// import verify_img from "../../assets/verify.png";
// import "../CSS file/forgot.css";
// import "../CSS file/verifystyle.css";
// import SignHeader from "../../Components/Sign Header/SignHeader";

// export default function Verify() {
//   return (
//     <>
//       <SignHeader />

//       <div className="forgot_main_container">
//         <div className="forgot_under_contianer">
//           <img
//             loading="lazy"
//             src={verify_img}
//             className="img-fluid forgot_img"
//           />

//           <div className="reset_content_contianer">
//             <h6 className="reset_pwd_text">Verify your email address</h6>
//             <p className="reset_extra_content">
//               Please check your email and click the verify button or link to
//               verify your account.
//             </p>

//             <form className="text_input_container1">
//               <button className="resend_btn_self same_btn_style">
//                 Resend Verification
//               </button>
//               <button className="contact_btn_self same_btn_style">
//                 Contact Support
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState } from "react";
import verify_img from "../../assets/verify.png";
import "../CSS file/forgot.css";
import "../CSS file/verifystyle.css";
import { useAlert } from "react-alert";
import SignHeader from "../../Components/Sign Header/SignHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtpResetPassword } from "../../Api/Api";

export default function Verify() {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();
  const email = location.state?.email || ""; // Get email from state or set default
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    try {
      const response = await verifyOtpResetPassword(email, otp);

      if (response.code === 400) {
        alert.show(response.messages, { type: "error" });
      } else {
        alert.show("OTP verified successfully", { type: "success" });
        navigate("/NewPassword", { state: { email, otp } }); // Pass email and otp as state
      }
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
      alert.show("Failed to verify OTP. Please try again.", { type: "error" });
    }
  };
  return (
    <>
      <SignHeader />

      <div className="forgot_main_container">
        <div className="forgot_under_contianer">
          <img
            loading="lazy"
            src={verify_img}
            className="img-fluid forgot_img"
          />

          <div className="reset_content_contianer">
            <h6 className="reset_pwd_text">Verify OTP</h6>
            <p className="reset_extra_content">
              Please check your email and click the verify button or link to
              verify your OTP.
            </p>
            {/* {error && <div>{error}</div>} */}
            <form onSubmit={handleVerifyOtp} className="w-100">
              <input
                type="email"
                placeholder="Enter your email"
                className="sign_up_input"
                value={email}
                disabled
              />
              <div className="d-lg-flex my-3">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="sign_up_input"
                  value={otp}
                  onChange={handleOtpChange}
                  required
                />
                <button type="submit" className="reset_btn_self">
                  Verify OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
