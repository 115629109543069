import React from "react";

function CourseSkeletonLoader(props) {
  return (
    <>
      <div className="course_box_container" key={Math.random()}>
        <div className="course_box_under_container skeleton_padding_content_height_univercity">
          <div className="course_text_title skeleton  skeleton_text skeleton_width" />

          <div
            style={{ marginBottom: 5 }}
            className="course_univer_txt skeleton  skeleton_text skeleton_width "
          ></div>
          <div className="course_country_text   skeleton  skeleton_text skeleton_width_short" />

          <div className="start_date_text skeleton  skeleton_text skeleton_width_short" />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton  skeleton_text skeleton_width_short"
          />
        </div>

        <div className="view_details_contianer skeleton_padding_content_height_univercity">
          <div className=" skeleton_width_short  skeleton skeleton_text" />

          <button className="view_detail_btn_univercity skeleton skeleton_button skeleton_text skeleton_remove_border" />
        </div>

        <div
          style={{ height: 20 }}
          className=" skeleton_remove_border apply_now_btn skeleton skeleton_button  "
        ></div>
      </div>
    </>
  );
}

export default CourseSkeletonLoader;
