import React, { useState, useEffect } from "react";
import "../CSS file/studypreferencepage.css";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLoggedInUser,
  fetchLoggedInUser,
  userInterestUpdateValue,
} from "../../StateManagement/slicers/userSlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { fetchStudyAreas } from "../../StateManagement/slicers/studySlicer";
import { fetchCountriesData } from "../../StateManagement/slicers/countrySlicer";
import { fetchSpecializationsById, fetchUniversityIntakesById, fetchIntakeYearsById } from '../../Api/Api'

const robo_icon = require("../../assets/bot.png");

function StudyPreferencePage(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personalPageData = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.token.token);
  const specializations = useSelector((state) => state.study.specializations);
  const countries = useSelector((state) => state.country.countries);
  const [destination, setDestination] = useState([]);
  const [desiredSpecialization, setDesiredSpecialization] = useState("DEFAULT");

  useEffect(() => {
    dispatch(fetchApiBearerToken());
    dispatch(fetchCountriesData());
  }, [dispatch]);

  useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      dispatch(fetchLoggedInUser());
      dispatch(fetchStudyAreas());
    }
  }, [token, dispatch]);

  useEffect(() => {
    async function checkSpecialization() {
      if (personalPageData?.user_interests_and_background
        ?.desired_specialization) {
        let value = personalPageData?.user_interests_and_background
          ?.desired_specialization;
        let res = await fetchSpecializationsById(value)
        setDesiredSpecialization(res.data.name)
      }
    }

    checkSpecialization();

  }, [personalPageData]);


  useEffect(() => {
    if (personalPageData?.user_interests_and_background?.desired_destinations) {
      let value = personalPageData?.user_interests_and_background?.desired_destinations;
      setDestination(JSON.parse(value))
    }

  }, [personalPageData]);

  const handleSubmit = (e) => {
    e.preventDefault();



    if (
      destination.length > 0 &&
      desiredSpecialization !== "" &&
      desiredSpecialization !== "DEFAULT"
    ) {
      dispatch(
        updateLoggedInUser({
          no_of_steps_followed: 4,
        })
      );
      dispatch(
        userInterestUpdateValue({
          desired_destinations: JSON.stringify(destination),
          desired_specialization: desiredSpecialization,
        })
      );
      navigate("/ThankYouPage");
    } else {
      alert.show("Please Fill all the details! So that we can help You out!", {
        type: "error",
      });
    }
  };

  return (
    <section className="intro_page_main_container">
      <div className="intro_under_page_container">
        <div className="intro_all_content_container">
          <div className="intro_robo_container">
            <img src={robo_icon} alt="robo_icon" loading="lazy" />
          </div>
          <div className="all_under_content_container_intro">
            <strong className="well_done_text">Well Done ! </strong>
            <p className="extra_text_of_study">
              Time to your admission will past faster than you think .<br />
              Can you give me more details about your study preferences?
            </p>
            {/* <span className="lightname_text_ps_">
              But first things first - we've been interrupted last time. Will
              you find a moment to tell me about yourself?
            </span> */}
            <strong className="title_btn_text_intro">
              Preffered specialization
            </strong>
            <br />
            <select
              className="text_input_ps1"
              name="country"
              id="country-select"
              value={desiredSpecialization}
              onChange={(e) => {
                setDesiredSpecialization(e.target.value);
              }}
            >
              <option value="DEFAULT">{desiredSpecialization}</option>
              {specializations.length > 0 ? (
                specializations.map((specialization) => {
                  return (
                    <option value={specialization.id} key={Math.random()}>
                      {specialization.name}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
            <br />
            <strong className="title_btn_text_intro">
              Preffered Global Ed Destination(s)
            </strong>
            <div className="all_btn_container_intro">
              {countries.length > 0 ? (
                countries.map((perGlobalPrefferedBtn) => {
                  return (
                    <button
                      key={Math.random()}
                      type="button"
                      onClick={() => {
                        if (!destination.includes(perGlobalPrefferedBtn.id)) {
                          setDestination((preValue) => [
                            ...preValue,
                            perGlobalPrefferedBtn.id,
                          ]);
                        } else {
                          let emptyArray = [];
                          destination.map((item) => {
                            if (item !== perGlobalPrefferedBtn.id) {
                              emptyArray.push(item);
                            }
                          });
                          setDestination(emptyArray);
                        }
                      }}
                      className={
                        destination.includes(perGlobalPrefferedBtn.id)
                          ? "select_btn_intro1_selected"
                          : "select_btn_intro1"
                      }
                    >
                      {perGlobalPrefferedBtn.country_name}
                    </button>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="next_btn_container_ps">
            <div className="btn_continue_later_text_container">
              <button
                type="button"
                className="sure_btn_self"
                onClick={(e) => handleSubmit(e)}
              >
                Continue
              </button>
              <button
                type="button"
                className="later_btn_self"
                onClick={() => {
                  dispatch(updateLoggedInUser({ is_fresh_user: false }));
                  navigate("/ApplicationStepper");
                }}
              >
                Maybe later
              </button>

              <p className="remember_text_all">
                Remember you can always change all information on{" "}
                <span className="highlight_text_blue_intro">your Profile</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudyPreferencePage;
