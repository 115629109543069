import React, { useRef, useEffect, useState } from "react";
import "../../CSS file/personaldialog.css";
import InputText from "../../../Components/InputTextBox/InputText";
import SelectBox from "../../../Components/SelectBox";
import {
  fetchUserEducation,
  updateUserEducation,
  addUserEducationalBackground,
} from "../../../StateManagement/slicers/userSlicer.js";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import { useAlert } from "react-alert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  level_of_education: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  study_area: Yup.string().required("Required"),
  school_type: Yup.string().required("Required"),
  specialization: Yup.string().required("Required"),
  degree_awarded: Yup.string().required("Required"),
  institution_name: Yup.string().required("Required"),
  grade: Yup.string().required("Required"),
});

export default function EducationDialogbox(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.user);

  const getEducationById = useSelector((state) => state.user.getEducationById);

  const userEducation = useRef({});
  const userDetailsToUpdate = useRef({
    level_of_education: "",
    status: "",
    study_area: "",
    school_type: "",
    specialization: "",
    degree_awarded: "",
    institution_name: "",
    grade: "",
  });

  const [educationValue, setEducationValue] = useState({});
  const [updateEducation, setUpdateEucations] = useState({});

  useEffect(() => {
    dispatch(fetchUserEducation(props.id));
  }, []);

  useEffect(() => {
    setEducationValue(getEducationById);
  }, [getEducationById]);

  function handleAddEducation(e) {
    e.preventDefault();
    props.handleToggle(false);
    dispatch(addUserEducationalBackground(userDetailsToUpdate.current));
    alert.success("Added education information")
  }
  function handleUpdateEducation(e) {
    e.preventDefault();
    dispatch(updateUserEducation(updateEducation, props.id));
    alert.success("updated education information")
  }

  // function hanlde
  const initialValues = {
    level_of_education: educationValue.level_of_education || "",
    status: educationValue.status || "",
    study_area: educationValue.study_area || "",
    school_type: educationValue.school_type || "",
    specialization: educationValue.specialization || "",
    degree_awarded: educationValue.degree_awarded || "",
    institution_name: educationValue.institution_name || "",
    grade: educationValue.grade || "",
  };

  if (props.id) {
    return (
      <form>
        <div className="acc_details_main_contianer">
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Level of Education "
              initialValue={educationValue?.level_of_education}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  level_of_education: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  level_of_education: e,
                }));
              }}
              options={[
                { optionName: "10th grade", optionValue: "10th grade" },
                { optionName: "12th grade", optionValue: "12th grade" },
                { optionName: "Under graduate", optionValue: "Under graduate" },
                { optionName: "Post graduate", optionValue: "Post graduate" },
                { optionName: "PhD", optionValue: "PhD" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Status"
              initialValue={educationValue?.status}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  status: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  status: e,
                }));
              }}
              options={[
                { optionName: "Graduate", optionValue: "graduate" },
                { optionName: "Pursuing", optionValue: "pursuing" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Study Area"
              // initialValue="Select area "
              initialValue={educationValue?.study_area}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  study_area: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  study_area: e,
                }));
              }}
              options={[
                {
                  optionName: "Arts & Social Science",
                  optionValue: "Arts & Social Science",
                },
                {
                  optionName: "Computer Science & IT",
                  optionValue: "Computer Science & IT",
                },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="School Type"
              initialValue={educationValue?.school_type}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  school_type: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  school_type: e,
                }));
              }}
              options={[
                { optionName: "Open", optionValue: "Open" },
                { optionName: "Regular", optionValue: "Regular" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Specialization"
              initialValue={educationValue?.specialization}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  specialization: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  specialization: e,
                }));
              }}
              options={[
                {
                  optionName: "Arts & Social Science",
                  optionValue: "Arts & Social Science",
                },
                {
                  optionName: "Computer Science & IT",
                  optionValue: "Computer Science & IT",
                },
              ]}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Degree Awarded"
              inputType="text"
              placeholderText="Enter awarded"
              inputId="fname"
              name="fname"
              value={educationValue?.degree_awarded}
              // // onChange={(e)=>userEducation.current(preValue=>({...preValue,degree_awarded:e.target.value}))}
              onChange={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  degree_awarded: e.target.value,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  degree_awarded: e.target.value,
                }));
              }}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Institute Name "
              inputType="text"
              placeholderText="Enter institute name"
              inputId="instituteId"
              name="instituteName"
              value={educationValue?.institution_name}
              onChange={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  institution_name: e.target.value,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  institution_name: e.target.value,
                }));
              }}
            />
          </div>

          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Grade"
              initialValue={educationValue?.grade_received}
              selectValue={(e) => {
                setEducationValue((preValue) => ({
                  ...preValue,
                  grade_received: e,
                }));
                setUpdateEucations((preValue) => ({
                  ...preValue,
                  grade_received: e,
                }));
              }}
              options={[
                {
                  optionName: "GDP 10 points",
                  optionValue: "GDP 10 points",
                },
                {
                  optionName: "GDP 5 points",
                  optionValue: "GDP 5 points",
                },
              ]}
            />
          </div>
        
        </div>

        <div className="btn_submit_cntainer">
          <button
            onClick={handleUpdateEducation}
            type="submit"
            className="submit_btn_dialog_box"
          >
            {isLoading ? <SpinnerDotted /> : "Submit"}
          </button>
        </div>
      </form>
    );
  } else {
    return (
      // <form>
      <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.id ? handleUpdateEducation : handleAddEducation}
      enableReinitialize={true}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form>
        <div className="acc_details_main_contianer">
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Level of Education "
              initialValue="Select education"
             
              selectValue={(e) => {
                userEducation.current["level_of_education"] = e;
                userDetailsToUpdate.current["level_of_education"] = e;
              }}
              options={[
                { optionName: "10th grade", optionValue: "10th" },
                { optionName: "12th grade", optionValue: "12th" },
                { optionName: "Under graduate", optionValue: "under" },
                { optionName: "Post graduate", optionValue: "post" },
                { optionName: "PhD", optionValue: "phd" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Status"
              initialValue="Select status"
              selectValue={(e) => {

                if (e === "graduate") {
                  userDetailsToUpdate.current["status"] = e;
                  userDetailsToUpdate.current["if_pursuing"] = false;
                } else {
                  userDetailsToUpdate.current["status"] = e;
                  userDetailsToUpdate.current["if_pursuing"] = true;
                }

                userEducation.current["current_status"] = e;
              }}
              options={[
                { optionName: "Graduate", optionValue: "graduate" },
                { optionName: "Pursuing", optionValue: "pursuing" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Study Area"
              initialValue="Select status"
              selectValue={(e) => {
                userEducation.current["study_area"] = e;
                userDetailsToUpdate.current["study_area"] = e;
              }}
              options={[
                {
                  optionName: "Arts & Social Science",
                  optionValue: "Arts & Social Science",
                },
                {
                  optionName: "Computer Science & IT",
                  optionValue: "Computer Science & IT",
                },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="School Type"
              // initialValue="Select type"
              initialValue="Select status"
              selectValue={(e) => {
                userEducation.current["school_type"] = e;
                userDetailsToUpdate.current["school_type"] = e;
              }}
              options={[
                { optionName: "Open", optionValue: "Open" },
                { optionName: "Regular", optionValue: "Regular" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Specialization"
              // initialValue="Select specialization"
              initialValue="Select status"
              selectValue={(e) => {
                userEducation.current["specialization"] = e;
                userDetailsToUpdate.current["specialization"] = e;
              }}
              options={[
                {
                  optionName: "Arts & Social Science",
                  optionValue: "Arts & Social Science",
                },
                {
                  optionName: "Computer Science & IT",
                  optionValue: "Computer Science & IT",
                },
              ]}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Degree Awarded"
              inputType="text"
              placeholderText="Enter awarded"
              inputId="fname"
              name="fname"
              onChange={(e) => {
                userEducation.current["degree_awarded"] = e;
                userDetailsToUpdate.current["degree_awarded"] = e.target.value;
              }}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Institute Name "
              inputType="text"
              placeholderText="Enter institute name"
              inputId="instituteId"
              name="instituteName"
              
              onChange={(e) => {
                userEducation.current["institution_name"] = e;
                userDetailsToUpdate.current["institution_name"] =
                  e.target.value;
               
              }}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Grade"
              inputType="text"
              placeholderText="Enter grade"
              inputId="gradeId"
              name="gradeName"
             
              onChange={(e) => {
                userEducation.current["grade_received"] = e;
                userDetailsToUpdate.current["grade_received"] = e.target.value;
              }}
            />
          </div>
        </div>

        <div className="btn_submit_cntainer">
          <button
            type="submit"
            className="submit_btn_dialog_box"
            onClick={handleAddEducation}
          >
            {isLoading ? <SpinnerDotted color="primary" /> : "Submit"}
          </button>
        </div>
      </Form>
      )}
    </Formik>
    );
  }
}
