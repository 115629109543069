import React from "react";

function InsideCoursePartSkeleton() {
  return (
    <>
      <div className="view_course_univercity_box skeleton_boxshadow skeleton_remove_border">
        <div className="view_course_univer_button_container skeleton_width">
          <div className="course_text_title skeleton skeleton_text skeleton_width" />

          <div className="start_date_text skeleton skeleton_text skeleton_width_short " />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton skeleton_text skeleton_width_short"
          />
        </div>
        <div
          style={{ marginTop: 10 }}
          className="view_details_contianer_course"
        >
          <div className="like_icon_container skeleton skeleton_text skeleton_width_short"></div>
          <div
            style={{ width: 70, height: 20 }}
            className="view_detail_btn_course skeleton_remove_border skeleton skeleton_button skeleton_width"
          />
        </div>
      </div>
      <div className="view_course_univercity_box skeleton_boxshadow skeleton_remove_border">
        <div className="view_course_univer_button_container skeleton_width">
          <div className="course_text_title skeleton skeleton_text skeleton_width" />

          <div className="start_date_text skeleton skeleton_text skeleton_width_short " />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton skeleton_text skeleton_width_short"
          />
        </div>
        <div
          style={{ marginTop: 10 }}
          className="view_details_contianer_course"
        >
          <div className="like_icon_container skeleton skeleton_text skeleton_width_short"></div>
          <div
            style={{ width: 70, height: 20 }}
            className="view_detail_btn_course skeleton_remove_border skeleton skeleton_button skeleton_width"
          />
        </div>
      </div>
      <div className="view_course_univercity_box skeleton_boxshadow skeleton_remove_border">
        <div className="view_course_univer_button_container skeleton_width">
          <div className="course_text_title skeleton skeleton_text skeleton_width" />

          <div className="start_date_text skeleton skeleton_text skeleton_width_short " />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton skeleton_text skeleton_width_short"
          />
        </div>
        <div
          style={{ marginTop: 10 }}
          className="view_details_contianer_course"
        >
          <div className="like_icon_container skeleton skeleton_text skeleton_width_short"></div>
          <div
            style={{ width: 70, height: 20 }}
            className="view_detail_btn_course skeleton_remove_border skeleton skeleton_button skeleton_width"
          />
        </div>
      </div>
      <div className="view_course_univercity_box skeleton_boxshadow skeleton_remove_border">
        <div className="view_course_univer_button_container skeleton_width">
          <div className="course_text_title skeleton skeleton_text skeleton_width" />

          <div className="start_date_text skeleton skeleton_text skeleton_width_short " />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton skeleton_text skeleton_width_short"
          />
        </div>
        <div
          style={{ marginTop: 10 }}
          className="view_details_contianer_course"
        >
          <div className="like_icon_container skeleton skeleton_text skeleton_width_short"></div>
          <div
            style={{ width: 70, height: 20 }}
            className="view_detail_btn_course skeleton_remove_border skeleton skeleton_button skeleton_width"
          />
        </div>
      </div>
      <div className="view_course_univercity_box skeleton_boxshadow skeleton_remove_border">
        <div className="view_course_univer_button_container skeleton_width">
          <div className="course_text_title skeleton skeleton_text skeleton_width" />

          <div className="start_date_text skeleton skeleton_text skeleton_width_short " />
          <div
            style={{ marginTop: 5 }}
            className="sub_start_text skeleton skeleton_text skeleton_width_short"
          />
        </div>
        <div
          style={{ marginTop: 10 }}
          className="view_details_contianer_course"
        >
          <div className="like_icon_container skeleton skeleton_text skeleton_width_short"></div>
          <div
            style={{ width: 70, height: 20 }}
            className="view_detail_btn_course skeleton_remove_border skeleton skeleton_button skeleton_width"
          />
        </div>
      </div>
    </>
  );
}

export default InsideCoursePartSkeleton;
