import React, { useState } from "react";
import "../CSS file/eventmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import EventLeftSide from "./EventLeftSide";
import EventRightSide from "./EventRightSide";
import { MdOutlineSearch } from "react-icons/md";
import CategorySearch from "../../Components/CategorySearch";

export default function EventMain() {

  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">Discover Events</h4>

        <CategorySearch />
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="left_side_connection_contianer">
          <EventLeftSide />
        </div>
        <div className="right_side_connection_contianer">
          <EventRightSide />
        </div>
      </div>
    </div>
  );
}
