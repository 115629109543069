import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  universitiesDetails,
  universitiesCoursesDetails,
  fetchUniversitiesId,
  fetchFeaturedUniversities,
  fetchPopularUniversities,
  fetchRecommendedUniversities,
  fetchShortlistedUniversities,
  fetchPreferredUniversities,
  fetchTopUniversities,
} from "../../Api/Api";
import { selectLoader } from "./commonSlicer";


export const universitySlicer = createSlice({
  name: "university",
  initialState: {
    university: [],
    ifUpdated: null,
    isLoading: false,
    universityToRender: {},
    universitiesCourses: [],
    universityCoursesToRender: {},
    recommendedUniversities: [],
    topUniversities: [],
    popularUniversities: [],
    featuredUniversities: [],
    shortlistedUniversities: [],
    preferredUniversities: [],
    universityTypeToRender: "",
    tabToRender: 1,
    isSearch:false,
    isSearchCourse:false
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;

    },
    fetchUniversityData: (state, action) => {
      state.university = action.payload.data;
      state.isLoading = false;
    },
    storeRenderingUniversity: (state, action) => {
      state.universityToRender = action.payload;
      state.isLoading = false;
    },
    updateUniversityCourses: (state, action) => {
      state.universitiesCourses = action.payload.data;
      state.isLoading = false;
    },
    storeRenderingUniversityCourses: (state, action) => {
      state.universityCoursesToRender = action.payload;
      state.isLoading = false;
    },
    storeTopUniversities: (state, action) => {
      state.topUniversities = action.payload;
      state.isLoading = false;
    },
    storeRecommendedUniversities: (state, action) => {
      state.recommendedUniversities = action.payload;
      state.isLoading = false;
    },
    storeFeaturedUniversities: (state, action) => {
      state.featuredUniversities = action.payload.data;
      state.isLoading = false;
    },
    storePopularUniversities: (state, action) => {
      state.popularUniversities = action.payload.data;
      state.isLoading = false;
    },
    storeShortlistedUniversities: (state, action) => {
      state.shortlistedUniversities = action.payload;
      state.isLoading = false;
    },
    storePreferredUniversities: (state, action) => {
      state.preferred = action.payload;
      state.isLoading = false;
    },
    storeUniversityTypeToRender: (state, action) => {
      state.universityTypeToRender = action.payload.type;
      state.tabToRender = action.payload.tab;
      state.isLoading = false;
    },
    storeIsSearch: (state, action) => {
      state.isSearch = action.payload
      
    },
    storeIsSearchCourse: (state, action) => {
      state.isSearchCourse = action.payload
      
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchUniversityData,
  toggleLoader,
  storeRenderingUniversity,
  updateUniversityCourses,
  storeRenderingUniversityCourses,
  storePreferredUniversities,
  storeShortlistedUniversities,
  storePopularUniversities,
  storeTopUniversities,
  storeRecommendedUniversities,
  storeFeaturedUniversities,
  storeUniversityTypeToRender,
  storeIsSearch,
  storeIsSearchCourse
} = universitySlicer.actions;

export const fetchUniversity = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const res = await universitiesDetails(data);
  dispatch(fetchUniversityData(res));
};
export const fetchUniversityById = (id,name) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchUniversitiesId(id , name)
  dispatch(storeRenderingUniversity(data));
};

export const fetchUniversityCourses = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await universitiesCoursesDetails()
  dispatch(updateUniversityCourses(data));
};
export const getTopUniversity = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchTopUniversities();

  dispatch(storeTopUniversities(data));
};
export const getPopularUniversity = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchPopularUniversities();
  dispatch(storePopularUniversities(data));
};
export const getFeaturedUniversity = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchFeaturedUniversities();
  dispatch(storeFeaturedUniversities(data));
};
export const getRecommendedUniversity = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchRecommendedUniversities();
  dispatch(storeRecommendedUniversities(data));
};
export const getPreferredUniversity = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const data = await fetchPreferredUniversities();

  dispatch(storePreferredUniversities(data));
};
export const getShortlistedUniversity = () => async (dispatch) => {
  dispatch(selectLoader(true));
  const data = await fetchShortlistedUniversities();

  dispatch(storeShortlistedUniversities(data));
  dispatch(selectLoader(false));
};


export const fetchSearch = (data) => async (dispatch) => {
  
  dispatch(storeIsSearch(data))
};
export const fetchSearchCourse = (data) => async (dispatch) => {
  
  dispatch(storeIsSearchCourse(data))
};

export default universitySlicer.reducer;
