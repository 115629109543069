import React, { useState } from 'react';
import Signup from './Signup'; // Your existing signup form
import EmailVerification from './EmailVerification';

export default function SignUpContainer() {
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState('');

  return (
    <div>
      {/* {showSignUpForm ? <Signup /> : <EmailVerification setShowSignUpForm={setShowSignUpForm} />} */}
      {showSignUpForm ? (
        <Signup verifiedEmail={verifiedEmail} />
      ) : (
        <EmailVerification setShowSignUpForm={setShowSignUpForm} setVerifiedEmail={setVerifiedEmail} />
      )}
    </div>
  );
}
