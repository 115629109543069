import React from "react";
import "./inputtext.css";

function InputText(props) {
  return (
    <div>
      <h6 className="input_label_text">{props.headTextInput}</h6>
      <input
        type={props.inputType}
        placeholder={props.placeholderText}
        id={props.inputId}
        name={props.name}
        defaultValue={props.defaultValue}
        // required
        value={props.value}
        disabled={props.disabled ? props.disabled : false}
        autoComplete="off"
        className="input_dialog_style"
        patterns={props.inputPattern}
        onChange={props.onChange}
        max={props.max}
        onBlur={props.onBlur}
      />
    </div>
  );
}

export default InputText;
