import React from "react";
import "../CSS file/thankyoupage.css";
import { useNavigate } from "react-router-dom";
import { selectUserFullNameParams } from "../../StateManagement/slicers/userSlicer";
import { useSelector } from "react-redux";

const robo_icon = require("../../assets/bot.png");

function ThankYouPage(props) {
  const navigate = useNavigate();
  const fullNameParams = useSelector(selectUserFullNameParams);
  return (
    <section className="intro_page_main_container">
      <div className="intro_under_page_container">
        <div className="intro_all_content_container">
          <div className="intro_robo_container">
            <img src={robo_icon} alt="robo_icon" loading="lazy" />
          </div>
          <div className="all_under_content_container_intro">
            <strong className="title_ty_page">
              Thanks again! You are Awesome!
            </strong>
            <p className="extra_text_ty">
              I know this is a lot of typing and clicking... but hey,this is
              your future we're talking about your educational and history will
              useful for me to find the right courses and uiversities for you.
            </p>

            <br />
            <p className="title_ty_page">
              Actually..I've already found something about you!{" "}
            </p>
            <p className="extra_text_ty">Wanna see?</p>
          </div>

          <div className="next_btn_container_ps">
            <div className="btn_continue_later_text_container">
              <button
                type="button"
                className="sure_btn_self"
                // onClick={() => navigate("/ViewProfile")}
                // onClick={() => navigate(`/Viewprofile/${fullNameParams}`)}
                onClick={() => navigate(`/feeds`)}
              >
                Yes,Show me!
              </button>
              <button type="button" className="later_btn_self">
                No,I'll explore it myself
              </button>

              <p className="remember_text_all">
                Remember you can always change all information on{" "}
                <span className="highlight_text_blue_intro">your Profile</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThankYouPage;
