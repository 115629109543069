import React, { useState, useEffect } from "react";
import "../CSS file/connectionmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import { MdOutlineSearch } from "react-icons/md";
import SearchResult from "./SearchResult";
import "../CSS file/searchresult.css";

export default function SearchMain() {
  function handleConnSearch() {}
  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">Search results</h4>
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="search_main_container">
          <SearchResult />
        </div>
      </div>
    </div>
  );
}
