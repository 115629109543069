import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchUserGalleryPhotos,
  userAddCommentOnFeeds,
  userSendInteractionOnPost,
} from "../../StateManagement/slicers/userSlicer";
import {
  MdThumbUp,
  MdPlayCircle,
  MdInsertComment,
  MdThumbUpOffAlt,
  MdOutlineSentimentSatisfied,
  MdSend,
  MdOutlineChatBubbleOutline,
  MdOutlineChatBubble,
} from "react-icons/md";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import "../CSS file/content6.css";
import "./GalleryPagination.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Picker from "emoji-picker-react";
import ".././CSS file/comment.css";
import ".././CSS file/middleprofile.css";
import "../../Screens/View Profile/middle profile/styles.css";
import PostModal from "../../test/PostModal";

function DataOfGallery() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userGallery = useSelector((state) => state.user.userGallery?.feeds);
  const [userGalleryList, setUserGalleryList] = useState([]);
  const [selectedPostIndex, setSelectedPostIndex] = useState(null);
  const [comment, setComment] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(12);
  const [loading, setLoading] = useState(true);
  const userInfo = useSelector((state) => state.user.user);
  const [showEmojis, setShowEmojis] = useState(false);
  const [isCommentSectionOpen, setIsCommentSectionOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUserGalleryPhotos())
      .then(() => setLoading(false))
      .catch((error) => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    setUserGalleryList(userGallery);
  }, [userGallery]);

  const offset = currentPage * perPage;
  const currentItems = userGalleryList?.slice(offset, offset + perPage);
  const pageCount = Math.ceil(userGalleryList?.length / perPage);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handlePostClick = (index) => {
    setSelectedPostIndex(index);
  };

  const handleClose = () => {
    setSelectedPostIndex(null);
  };




  const handleNext = () => {
    setSelectedPostIndex((prevIndex) =>
      prevIndex === userGalleryList.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setSelectedPostIndex((prevIndex) =>
      prevIndex === 0 ? userGalleryList.length - 1 : prevIndex - 1
    );
  };

  const renderMediaContent = (files) => {
    return files.map((file, index) => {
      const isVideo = file.endsWith(".mp4") || file.endsWith(".webm");
      return (
        <SwiperSlide key={index}>
          {isVideo ? (
            <video
              src={file}
              controls
              className="img-fluid w-100 h-100 object-fit-cover"
            />
          ) : (
            <img
              src={file}
              alt={`Slide ${index + 1}`}
              className="img-fluid w-100 object-fit-cover"
            />
          )}
        </SwiperSlide>
      );
    });
  };
  // ******************
  const handleLike = (post) => {
    dispatch(userSendInteractionOnPost({ if_liked: !post.if_liked }, post.id))
      .then(() => {
        // Optionally update local state or refetch gallery data
        dispatch(fetchUserGalleryPhotos());
      })
      .catch((error) => {
      });
  };

  const onEmojiClick = (event, emojiObject) => {
    setComment((prevComment) => prevComment + emojiObject.emoji);
    setShowEmojis(false);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (comment.trim()) {
      const postId = userGalleryList[selectedPostIndex].id;
      dispatch(
        userAddCommentOnFeeds({
          user_id: userInfo.id,
          feed_id: postId,
          comment: comment,
        })
      )
        .then(() => {
          setComment("");
          setShowEmojis(false);
          dispatch(fetchUserGalleryPhotos());
        })
        .catch((error) => {
        });
    }
  };

  const toggleCommentSection = () => {
    setIsCommentSectionOpen(!isCommentSectionOpen);
  };

  return (
    <div className="gallery-container">
      <div className="gallery-grid">
        {/* {loading ? (
          <p>Loading...</p>
        ) : userGalleryList.length > 0 ? (
          currentItems?.map((post, index) => {
            const files = JSON.parse(post.files);
            const fileUrl = files[0];
            const isVideo = fileUrl.endsWith('.mp4') || fileUrl.endsWith('.webm');

            return (
              <div key={post.id} className="gallery-item" onClick={() => handlePostClick(index + offset)}>
                {isVideo ? (
                  <div className="video-thumbnail">
                    <img src={post.thumbnail || fileUrl} alt="Video thumbnail" />
                    <MdPlayCircle className="play-icon" />
                  </div>
                ) : (
                  <img src={fileUrl} alt={post.text} />
                  // <p>helllo</p>
                )}
              </div>
            );
          })
        ) : (
          <p>No posts found</p>
        )} */}
        {loading ? (
          <p>Loading...</p>
        ) : userGalleryList.length > 0 ? (
          currentItems?.map((post, index) => {
            const files = JSON.parse(post.files);
            const fileUrl = files[0];
            const isVideo =
              fileUrl.endsWith(".mp4") || fileUrl.endsWith(".webm");

            return (
              <div key={post.id} className="">
                <div
                  className="gallery-item position-relative"
                  onClick={() => handlePostClick(index + offset)}
                >
                  {isVideo ? (
                    <div className="video-thumbnail">
                      <img
                        src={post.thumbnail || fileUrl}
                        alt="Video thumbnail"
                        className="img-fluid w-100 h-100 object-fit-cover"
                      />
                      <MdPlayCircle className="play-icon" />
                    </div>
                  ) : (
                    <img
                      src={fileUrl}
                      alt={post.text}
                      className="img-fluid w-100 h-100 object-fit-cover"
                    />
                  )}
                  <div className="gallery-item-info">
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="me-3">
                        <MdThumbUp className="text-white" />
                        <span className="text-white ms-1">
                          {post.likes_count}
                        </span>
                      </div>
                      <div>
                        <MdInsertComment className="text-white" />
                        <span className="text-white ms-1">
                          {post.feeds_has_comments?.length}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No posts found</p>
        )}
      </div>

      <PostModal
        selectedPostIndex={selectedPostIndex}
        userGalleryList={userGalleryList}
        handleClose={handleClose}
        handleLike={handleLike}
        handleCommentSubmit={handleCommentSubmit}
        userInfo={userInfo}
      />

      <ReactPaginate
        previousLabel={<FaChevronLeft />}
        nextLabel={<FaChevronRight />}
        breakLabel="..."
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        activeClassName="active"
      />
    </div>
  );
}

export default DataOfGallery;
