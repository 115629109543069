import React, { useState, useRef, useEffect } from "react";
import "./ShortlistedBtn.css";
import { useDispatch, useSelector } from "react-redux";
import { formatURLName } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import { storeRenderingUniversityCourses } from "../StateManagement/slicers/universitySlicer";

function FloatingShortlistedBtn(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(11);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const { shortlistedCourses } = useSelector((state) => state.course);
  const shortlistedUniversities = useSelector(
    (state) => state.university.shortlistedUniversities
  );

  const coursesArray = shortlistedCourses?.data?.shortlisted_courses || [];
  const univercitiesArray =
    shortlistedUniversities?.data?.filtered_universities || [];

  // Close the popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  function handleViewDetailsunivercitis(e) {

    localStorage.setItem("cource_id", e.id);
    const formattedName = formatURLName(e.u_name);
   
    navigate(`/UnivercityMain/ViewDetailUnivercity/${formattedName}`);
  }

  function handleViewDetailsCourse(e) {
    dispatch(storeRenderingUniversityCourses(e));
    const formattedName = formatURLName(e.course_has_entries_in_course.name);
    navigate(`/UnivercityMain/ViewDetailCourses/${formattedName}`);
  }

  return (
    <div className="floating_shortlisted_icon" ref={popoverRef}>
      <button className="shortlisted-button" onClick={togglePopover}>
        Shortlisted
        {count > 10 ? (
          <span className="more_than_no_count">10+</span>
        ) : (
          <span className="one_number_count">{count}</span>
        )}
      </button>
      <br />
      {isPopoverOpen && (
        <div className="shortlisted-popover">
          <div className="popover-content">
            <h5 className="h3">Shortlisted Course</h5>
            {coursesArray.slice(0, 5).map((item, index) => (
              <div className="d-flex justify-content-between">
                <p key={index}>{item?.course_has_entries_in_course.name}</p>
                <button className="view_detail_btn_univercity" onClick={() => handleViewDetailsCourse(item)}>Details</button>
              </div>
            ))}
            <h5>Shortlisted Univercity</h5>
            {univercitiesArray?.map((item, index) => (
              <div className="d-flex justify-content-between">
                <p key={index}>{item?.u_name}</p>
                <button className="view_detail_btn_univercity" onClick={() => handleViewDetailsunivercitis(item)}>Details</button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FloatingShortlistedBtn;
