import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userLogin, userSignUp, changePassword } from "../../Api/Api";
import { storeUser } from "./userSlicer"
export const authSlicer = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    errorMessage: null,
    successMessage: null,
    user: {},
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    storeUserData: (state, action) => {
      state.user = action.payload;
    },
    removeUserData: (state, action) => {
      state.user = {};
    },
    emptyErrorMessage: (state) => {
      state.errorMessage = null;
    },
    emptySuccessMessage: (state) => {
      state.successMessage = null;
    },
    storeSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    storeErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleLoader,
  emptyErrorMessage,
  emptySuccessMessage,
  storeErrorMessage,
  storeSuccessMessage,
  storeUserData,
  removeUserData,
} = authSlicer.actions;

export const helpUserSigningIn = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  let response = await userLogin(data);
  if (response.data === "Email Id Not Found Or Phone Number Not registered") {
    dispatch(storeErrorMessage("Email Id Not Registered"));
   
  }
  if (response.data?.access_token) {
    localStorage.setItem("access_token", response.data.access_token);
    dispatch(storeUserData(response.data.user_id));
    dispatch(storeUser(response.data.user_id))
    dispatch(storeSuccessMessage("Welcome Back!!!"));
  }
  if (response?.response?.status === 400) {
    dispatch(storeErrorMessage(response?.response?.data?.message));
   
  }

  dispatch(toggleLoader(false));
};

export const helpUserSigningUp = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await userSignUp(data);
  
  if (response.access_token) {
    localStorage.setItem("access_token", response.access_token);
    dispatch(storeUserData(response.user_id));
    dispatch(storeUser(response.user_id))
    dispatch(storeSuccessMessage("Great to have You here!!!"));
  }
  if (response.code === 305) {
    dispatch(storeErrorMessage(response?.messages));
  }
  dispatch(toggleLoader(false));

};
export const helpUserLogout = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  dispatch(toggleLoader(false));
};

export const helpAuthUserChangePassword = (data) => async (dispatch) => {
  dispatch(toggleLoader(true));
  const response = await changePassword(data);
  
  dispatch(toggleLoader(false));
};

// new action to handle user logout
export const logoutUser = () => (dispatch) => {
  // Clear user data from local storage
  localStorage.removeItem("access_token");
  // Dispatch action to remove user data from the store
  dispatch(removeUserData());
};

export default authSlicer.reducer;
