import React from "react";

function BoxTextSubTextSkeletonLoader(props) {
  return (
    <button
      className="profile_event_title_sub_title_container skeleton_width"
      key={Math.random()}
    >
      <div className="event_box_img_contianer">
        <div
          // alt="popularUniversities"
          className="univer_img_popular_self skeleton skeleton_remove_border"
        />
      </div>
      <div className="univer_title_text_subtitle_contianer">
        <div className=" skeleton skeleton_text skeleton_width">
          {/* It is a long established fact. */}
        </div>
        <div className="skeleton skeleton_text skeleton_width_short"></div>
      </div>
    </button>
  );
}

export default BoxTextSubTextSkeletonLoader;
