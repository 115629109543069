import React, { useState, useEffect } from "react";
import CommonHeader from "../../../Components/Common header/CommonHeader";
import "../../CSS file/viewdetailcourse.css";
import FactBox from "../FactBox";
import { useSelector } from "react-redux";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import FloatingShortlistedBtn from "../../../Components/FloatingShortlistedBtn";
const univercity_img = require("../../../assets/school.png");
const univer_country_img = require("../../../assets/canada_img.png");
const fact_img = require("../../../assets/Group 48.png");
const couseDetaiImg = require("../../../assets/iconsDetailPage/school.png");
const couseDetaiImg1 = require("../../../assets/iconsDetailPage/Group 60.png");
const couseDetaiImg2 = require("../../../assets/iconsDetailPage/Group 66.png");
const couseDetaiImg3 = require("../../../assets/iconsDetailPage/Group 62.png");
const couseDetaiImg4 = require("../../../assets/iconsDetailPage/Group 63.png");
const couseDetaiImg5 = require("../../../assets/iconsDetailPage/Group 67.png");
const couseDetaiImg6 = require("../../../assets/filtericon/intake.svg");
const couseDetaiImg7 = require("../../../assets/filtericon/withdegree.svg");
const couseDetaiImg8 = require("../../../assets/filtericon/langtext.svg");
const couseDetaiImg9 = require("../../../assets/filtericon/duration.svg");

export default function ViewDetailCourses(props) {
  const navigate = useNavigate();
  const {courseId , courseName}  = useParams();
  const specializations = useSelector((state) => state.study.specializations);
  const duration = useSelector((state) => state.study.duration);
  const intakes = useSelector((state) => state.study.intakes);
  const programLevel = useSelector((state) => state.study.programLevels);
  // const universityCoursesToRender = useSelector(
  //   (state) => state.course.universityCoursesToRender
  // );

  const { universityCoursesToRender } = useSelector(
    (state) => state.university
  );





  function handleMoreInfo() {
    navigate("/ViewDetailUnivercity");
  }
  // const alluniversityCoursesData = [
  //   {
  //     clgUniverImg:
  //       universityCoursesToRender?.course_given_by_university?.university_logo,
  //     clgUniverTitle: universityCoursesToRender?.course_given_by_university
  //       ?.u_name
  //       ? universityCoursesToRender?.course_given_by_university?.u_name
  //       : "------",
  //   },
  //   {
  //     clgUniverImg: univer_country_img,
  //     clgUniverTitle: universityCoursesToRender?.universities_location[0]
  //       ?.university_in_country[0]?.country_name
  //       ? universityCoursesToRender?.universities_location[0]
  //         ?.university_in_country[0]?.country_name
  //       : null,
  //   },

  // ];
  const alluniversityCoursesData = [
    {
      clgUniverImg:
        universityCoursesToRender?.course_given_by_university?.university_logo,
      clgUniverTitle: universityCoursesToRender?.course_given_by_university?.u_name ?? "------",
    },
    {
      clgUniverImg: univer_country_img,
      clgUniverTitle: universityCoursesToRender?.universities_location?.[0]?.university_in_country?.[0]?.country_name ?? "------",
    },
  ];
  const allEntryRequirement = [
    {
      entryReqImg: fact_img,
      entryReqExamTitle: "IELTS",
      entryReqExamMark: universityCoursesToRender
        ?.requirements_dependent_on_course_and_university
        ?.ielts_minimum_requirement
        ? universityCoursesToRender
          ?.requirements_dependent_on_course_and_university
          ?.ielts_minimum_requirement
        : "------",
    },
    {
      entryReqImg: fact_img,
      entryReqExamTitle: "GRE",
      entryReqExamMark: universityCoursesToRender
        ?.requirements_dependent_on_course_and_university
        ?.gre_minimum_requirement
        ? universityCoursesToRender
          ?.requirements_dependent_on_course_and_university
          ?.gre_minimum_requirement
        : "------",
    },
  ];

  const allAcademicRequirement = [
    {
      entryReqImg: fact_img,
      entryReqExamTitle: "XII (CBSE)",
      entryReqExamMark: "65%",
    },
  ];

  const renderUniverCourseData = alluniversityCoursesData.map(
    (peruniverData) => {
    
      return (
        <div className="univer_img_title_text_container" key={Math.random()}>
          <img
            loading="lazy"
            className="univer_img"
            alt="univercity"
            src={
              peruniverData?.clgUniverImg
                ? peruniverData?.clgUniverImg
                : univercity_img
            }
          />
          <h6 className="univer_title_course_text">
            {/* {peruniverData?.clgUniverTitle
              ? peruniverData?.clgUniverTitle
              : "------"} */}
               {peruniverData?.clgUniverTitle ?? "------"}
          </h6>
        </div>
      );
    }
  );




  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="view_details_main_container">
        <div className="course_header_contianer">
          <button
            className="back_aeerow_btn_container"
            onClick={() => navigate(-1)}
          >
            <BsFillArrowLeftCircleFill className="back_aerrow_icon" />
          </button>
          <h5 className="view_details_heading_text2">Course details</h5>
        </div>
        <h1 className="main_title_couse_text">
          {/* Bachelor of Business (Majoring in International Business) */}
          {universityCoursesToRender?.course_has_entries_in_course?.name
            ? universityCoursesToRender?.course_has_entries_in_course?.name
            : "Course Name"}
        </h1>



        <hr />
        <div className="view_course_main_container">
          <div className="view_coure_left_contianer">

            {renderUniverCourseData}
            <hr />
            <h6 className="entry_requirement_tetx" style={{ margin: ".6rem 0 0 0" }}>Specialization </h6>
            <h6 className="score_exam_title_tetx" style={{ margin: ".6rem 0 0 0" }}>
              {universityCoursesToRender.course_has_entries_in_course?.study_area_id.split(",").map((item) => {
                const perSpecialization = specializations.find((specialization) => specialization.id === parseInt(item));
                return perSpecialization ? perSpecialization.name : null;
              }).filter(Boolean).join(", ")}
            </h6>

            <hr />

            <h6 className="education_title_text">↪ Education Qualification</h6>


            <div className="entry_requirement_container">
              <div className="entry_academeiv_requirement_contianer">
                <div className="width_equal_part_container">
                  <h6 className="entry_requirement_tetx">Entry requirements</h6>



                  {allEntryRequirement.map((perEntryReq) => {
                    return (
                      <div
                        className="entry_img_text_contianer"
                        key={Math.random()}
                      >
                        <img
                          loading="lazy"
                          src={perEntryReq.entryReqImg}
                          alt="entry"
                          className="true_require_img"
                        />
                        <h6 className="score_exam_title_tetx">
                          {perEntryReq.entryReqExamTitle}:{" "}
                          <span className="score_text_exam">
                            {perEntryReq.entryReqExamMark} Overall
                          </span>
                        </h6>
                      </div>
                    );
                  })}
                </div>

                <div className="entry_right_requirement_container">
                  <h6 className="entry_requirement_tetx">
                    Academic requirements
                  </h6>

                  {allAcademicRequirement.map((perEntryReq) => {
                    return (
                      <div
                        className="entry_img_text_contianer"
                        key={Math.random()}
                      >
                        <img
                          loading="lazy"
                          src={perEntryReq.entryReqImg}
                          alt="entry"
                          className="true_require_img"
                        />
                        <h6 className="score_exam_title_tetx">
                          {perEntryReq.entryReqExamTitle}:{" "}
                          <span className="score_text_exam">
                            {perEntryReq.entryReqExamMark} Overall
                          </span>
                        </h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <h6 className="education_title_text">
              ↪ About James Cook University(JCU) Singapore
            </h6>
            <button className="more_about_info_btn" onClick={handleMoreInfo}>
              More about the univercities
            </button>
          </div>
          <div className="view_coure_right_contianer">
            <h5 className="view_details_heading_text">Quick facts</h5>

            <FactBox
              valueToRender={[
                // {
                //   factBoxImg: couseDetaiImg,
                //   factBoxTitle: "Campus",
                //   // factBoxSubTitle: "----",
                //   factBoxSubTitle: universityCoursesToRender
                //     ?.universities_location[0]?.campus_name
                //     ? universityCoursesToRender?.universities_location[0]
                //       ?.campus_name
                //     : "------",
                // },
                // {
                //   factBoxImg: couseDetaiImg6.default,
                //   factBoxTitle: "Intakes",
                //   factBoxSubTitle: universityCoursesToRender.intake_ids.split(",").map((item) => {
                //     const perIntake = intakes.find((intake) => intake.id === parseInt(item));

                //     return perIntake ? perIntake.intake_name.substring(0, 3) : null;
                //   }).filter(Boolean).join(", ")

                // },
                {
                  factBoxImg: couseDetaiImg,
                  factBoxTitle: "Campus",
                  factBoxSubTitle: universityCoursesToRender?.universities_location?.[0]?.campus_name ?? "------",
                },
                {
                  factBoxImg: couseDetaiImg6.default,
                  factBoxTitle: "Intakes",
                  factBoxSubTitle: universityCoursesToRender?.intake_ids?.split(",").map((item) => {
                    const perIntake = intakes.find((intake) => intake.id === parseInt(item));
                    return perIntake ? perIntake.intake_name.substring(0, 3) : null;
                  }).filter(Boolean).join(", "),
                },

                {
                  factBoxImg: couseDetaiImg8.default,
                  factBoxTitle: "Language Test",
                  factBoxSubTitle: ""
                  // factBoxSubTitle: "2 Years",
                },
                {
                  factBoxImg: couseDetaiImg7.default,
                  factBoxTitle: "With a degree",
                  factBoxSubTitle: universityCoursesToRender.course_has_entries_in_course?.study_area_id.split(",").map((item) => {
                    const perDegree = programLevel.find((programLevel) => programLevel.id === parseInt(item))
                    return perDegree ? perDegree.program_level_name : null;
                  }).filter(Boolean)

                },


                {
                  factBoxImg: couseDetaiImg9.default,
                  factBoxTitle: "Duration",
                  // factBoxSubTitle:
                  //   universityCoursesToRender.course_has_entries_in_course.duration_id.split(",").map((item) => {
                  //     const durationId = parseInt(item, 10);
                  //     if (isNaN(durationId)) {
                  //       return null; // or any default value if needed
                  //     }

                  //     const perDuration = duration.find((duration) => duration.id === durationId);

                  //     if (!perDuration || !perDuration.duration_range || typeof perDuration.duration_range !== 'string') {
                  //       return null; // or any default value if needed
                  //     }

                  //     return perDuration.duration_range.substring(0, 20);
                  //   }).filter(Boolean)
                  factBoxSubTitle: Array.isArray(duration) 
              ? universityCoursesToRender.course_has_entries_in_course?.duration_id.split(",").map((item) => {
                  const durationId = parseInt(item, 10);
                  if (isNaN(durationId)) return null;
                  const perDuration = duration.find((d) => d.id === durationId);
                  if (!perDuration || !perDuration.duration_range || typeof perDuration.duration_range !== "string") return null;
                  return perDuration.duration_range.substring(0, 20);
                }).filter(Boolean).join(", ")
              : "------",


                  // factBoxSubTitle: universityCoursesToRender?.course_has_entries_in_course?.duration_id 
                  // factBoxSubTitle: "2 Years",
                },
                // {
                //   factBoxImg: couseDetaiImg2,
                //   factBoxTitle: "Start date",
                //   // factBoxSubTitle: "Nov-2020",
                //   factBoxSubTitle: "----",
                // },
                {
                  factBoxImg: couseDetaiImg3,
                  factBoxTitle: "Avg Tuition/Year",
                  factBoxSubTitle: universityCoursesToRender?.fee_structure_of_university_on_given_course?.tuition_fees,
                  // factBoxSubTitle: universityCoursesToRender
                  //   ?.fee_structure_of_university_on_given_course[0]
                  //   ?.tuition_fees
                  //   ? universityCoursesToRender
                  //       ?.fee_structure_of_university_on_given_course[0]
                  //       ?.tuition_fees
                  //   : "------",
                },
                {
                  factBoxImg: couseDetaiImg4,
                  factBoxTitle: "Internship",
                  // factBoxSubTitle: "NO",
                  factBoxSubTitle: "----",
                },
                {
                  factBoxImg: couseDetaiImg5,
                  factBoxTitle: "Application Deadline",
                  factBoxSubTitle: "---",
                  // universityCoursesToRender?.in_take_DeadLines.length > 0
                  //   ? universityCoursesToRender?.in_take_DeadLines
                  //   : "No data",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <FloatingShortlistedBtn />
    </div>
  );
}
