import React, { useState } from "react";
import "../CSS file/eventright.css";
import PeopleImgTitle from "../../Components/people you may know/PeopleImgTitle";
import TitleNumberFile from "../../Components/Title and number/TitleNumberFile";
const group_img_right = require("../../assets/group.jpg");

const allPopularEvent = [
  {
    event_img: group_img_right,
    eventTitle: "My 32th Birthday",
    eventSubTitle: "Today, April 21, 5:00pm",
  },
  {
    event_img: group_img_right,
    eventTitle: "My 32th Birthday",
    eventSubTitle: "Today, April 21, 5:00pm",
  },
  {
    event_img: group_img_right,
    eventTitle: "My 32th Birthday",
    eventSubTitle: "Today, April 21, 5:00pm",
  },
  {
    event_img: group_img_right,
    eventTitle: "My 32th Birthday",
    eventSubTitle: "Today, April 21, 5:00pm",
  },
  {
    event_img: group_img_right,
    eventTitle: "My 32th Birthday",
    eventSubTitle: "Today, April 21, 5:00pm",
  },
];

export default function EventRightSide() {
  //  const[events,setEvents] = useState([])

  //   useEffect(()=>{
  //     eventDetails()
  //   },[])

  //   async function eventDetails(){
  //     let response = await userEventDetails()
  //     setEvents(response)
  //   }
  const renderEventsPopular = allPopularEvent.map((perPopular) => {
    return (
      <button
        className="profile_event_title_sub_title_container"
        key={Math.random()}
      >
        <div className="event_box_img_contianer">
          <img
            loading="lazy"
            src={perPopular.event_img}
            alt="people"
            className="event_img_popular_self"
          />
        </div>
        <div className="event_title_text_subtitle_contianer">
          <h6 className="event_title_text">{perPopular.eventTitle}</h6>
          <p className="event_sub_title_text">{perPopular.eventSubTitle}</p>
        </div>
      </button>
    );
  });
  return (
    <div className="conectin_under_right_side_contianer">
      <h6 className="people_main_title_text">Popular Events</h6>
      {renderEventsPopular}

      <div className="dicover_by_postion_container">
        <h6 className="people_main_title_text">EVENTS BY LOCATION</h6>

        <TitleNumberFile
          valueToRender={[
            {
              titlePostion: "San Francisco, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Los Angeles, California",
              numberPostion: "20",
            },
            {
              titlePostion: "Las Vegas, Nevada",
              numberPostion: "20",
            },
            {
              titlePostion: "Austin, Texas",
              numberPostion: "20",
            },
            {
              titlePostion: "Arlington, Nebraska",
              numberPostion: "20",
            },
          ]}
        />
      </div>
    </div>
  );
}
