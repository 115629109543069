import React, { useState } from "react";
import "../CSS file/groupmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import GroupLeftSide from "./GroupLeftSide";
import GroupRightSide from "./GroupRightSide";
import { MdOutlineSearch } from "react-icons/md";
import CategorySearch from "../../Components/CategorySearch";

export default function GroupMain() {

  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">Discover Groups</h4>

        <CategorySearch />
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="left_side_connection_contianer">
          <GroupLeftSide />
        </div>
        <div className="right_side_connection_contianer">
          <GroupRightSide />
        </div>
      </div>
    </div>
  );
}
