import React, { useState } from "react";
import ds_logo from "../../assets/ds_logo.png";
import "./signheader.css";

export default function SignHeader() {
  return (
    <>
      <nav className="navbar navbar-expand-sm border-bottom">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={ds_logo}
              alt="DWEC_logo"
              loading="lazy"
              className="img-responsive logo_dwec_img"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </>
  );
}
