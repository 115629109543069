import React, { useState, useEffect, useRef } from "react";
import "../../CSS file/personaldialog.css";
import InputText from "../../../Components/InputTextBox/InputText";
import SelectBox from "../../../Components/SelectBox";
import { fetchCountryById } from "../../../Api/Api";
import {
  fetchUser,
  updateUser,
  updateLoggedInUser,
} from "../../../StateManagement/slicers/userSlicer.js";
import { fetchGenderClassificatons } from "../../../StateManagement/slicers/generalSlicer";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import LoaderFullPage from "../../../Components/LoaderFullPage";
import { useAlert } from "react-alert";

export default function BasicInfoDialogbox(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const genders = useSelector((state) => state.general.genders);
  const { user, isLoading } = useSelector((state) => state.user);
  const [userProfileUpdate, setUserProfileUpdate] = useState({});
  const userDetailsToUpdate = useRef({});
  const countries = useSelector((state) => state.country.countries);
  const [maxSupportedDate, setMaxSupportedDate] = useState("");
  const [countryName, setCountryName] = useState("");
  const [loaderValue, setLoaderValue] = useState(props.handleLoader);
  const [citizenShip, setCitizenShip] = useState("");

  useEffect(() => {
    dispatch(fetchGenderClassificatons());
  }, []);
  useEffect(() => {
    let years = 7; // Days you want to subtrac
    let today = new Date();
    let maxYear = new Date(today.setFullYear(today.getFullYear() - years));
    const [onlyDate] = maxYear.toISOString().split("T");
    setMaxSupportedDate(onlyDate);
  }, []);

  useEffect(() => {
    async function checkCountry() {
      if (userProfileUpdate?.user_personal_profile?.ethnicity) {
        let res = await fetchCountryById(
          userProfileUpdate?.user_personal_profile?.ethnicity
        );
        setCountryName(res.data.country_name);
        setLoaderValue(false);
      }
    }
    async function checkCitizenShipCountry() {
      if (user?.user_personal_profile?.selected_citizenship_id) {
        let res = await fetchCountryById(
          user?.user_personal_profile?.selected_citizenship_id
        );
        setCitizenShip(res.data.country_name);
      }
    }

    checkCitizenShipCountry();
    checkCountry();
  
  }, [userProfileUpdate?.user_personal_profile?.ethnicity]);

  useEffect(() => {
    setUserProfileUpdate(user);
  }, [user]);

  const basicInfo = useRef({
    // examDate: "",
    country: "",
    ethnicity: "",
    maritalStatus: "",
    gender: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(updateLoggedInUser(userDetailsToUpdate.current));
    alert.success("Your Details Edit Successfully✅")
    props.handleToggle(false);
  }

  return (
    <>
      {loaderValue === true ? <LoaderFullPage /> : null}

      <form onSubmit={handleSubmit}>
        <div className="acc_details_main_contianer">
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Birth Date"
              inputType="date"
              inputId="accExamDate"
              name="exam date"
              autoComplete="off"
              max={maxSupportedDate}
              value={
                userProfileUpdate?.user_personal_profile?.date_of_birth
                  ? userProfileUpdate?.user_personal_profile?.date_of_birth
                  : ""
              }
              onChange={(e) => {
                userDetailsToUpdate.current["date_of_birth"] = e.target.value;
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    date_of_birth: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Country of Citizenship"
              initialValue={citizenShip ? citizenShip : "Select option"}
              selectValue={(e) => {
                basicInfo.current["citizenship"] = e;
                userDetailsToUpdate.current["citizenship"] = e;
              }}
              options={
                countries.length > 0
                  ? countries.map((country) => {
                      return {
                        optionName: country.country_name,
                        optionValue: country.id,
                      };
                    })
                  : []
              }
            />
          </div>

          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Ethnicity"
              initialValue={
                userProfileUpdate?.user_personal_profile?.ethnicity
                  ? countryName
                  : "Select option"
              }
              // initialValue="Select ethnicity"
              selectValue={(e) => {
                basicInfo.current["ethnicity"] = e;
                userDetailsToUpdate.current["ethnicity"] = e;
              }}
              options={
                countries.length > 0
                  ? countries.map((country) => {
                      return {
                        optionName: country.country_name,
                        optionValue: country.id,
                      };
                    })
                  : []
              }
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Marital status"
              initialValue={
                userProfileUpdate?.user_personal_profile?.marital_status
                  ? userProfileUpdate?.user_personal_profile?.marital_status
                  : "Select option"
              }
              selectValue={(e) => {
                basicInfo.current["marital_status"] = e;
                userDetailsToUpdate.current["marital_status"] = e;
              }}
              options={[
                { optionName: "Single", optionValue: "Single" },
                { optionName: "Married", optionValue: "Married" },
              ]}
            />
          </div>
          <div className="select_input_text_contianer">
            <SelectBox
              headTextInput="Gender"
              initialValue={
                userProfileUpdate?.user_personal_profile?.gender
                  ? userProfileUpdate?.user_personal_profile?.gender
                  : "Select option"
              }
              selectValue={(e) => {
                basicInfo.current["gender"] = e;
                userDetailsToUpdate.current["gender"] = e;
              }}
              options={
                genders.length > 0
                  ? genders.map((gender) => {
                      return {
                        optionName: gender.name,
                        optionValue: gender.id,
                      };
                    })
                  : []
              }
            />
          </div>
        </div>

        <div className="btn_submit_cntainer">
          <button className="submit_btn_dialog_box">
            {isLoading ? (
              <SpinnerDotted
                enabled={isLoading}
                color="var(--theme-red)"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
    </>
  );
}
