import React, { useEffect, useState, useRef } from "react";
import "../../CSS file/personaldialog.css";
import "../../../Components/InputTextBox/inputtext.css";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchCountriesData } from "../../../StateManagement/slicers/countrySlicer.js";
import { userInterestUpdateValue } from "../../../StateManagement/slicers/userSlicer.js";
import { useAlert } from "react-alert";

function DestinationDialogbox(props) {
  const alert = useAlert();
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.country.countries);

  const [allDestination, setAllDestination] = useState([]);
  const selectDesiredCountry = useRef([]);

  useEffect(() => {
    if (countries.length > 0) {
      setAllDestination(countries);
    }
  }, [countries]);

 


  function handleSubmit(e) {
    e.preventDefault();
    let uniqueChars = [];
    selectDesiredCountry.current.forEach((c) => {
      if (!uniqueChars.includes(c)) {
        uniqueChars.push(c);
      }
    });


    dispatch(
      userInterestUpdateValue({
        preferred_country: JSON.stringify(uniqueChars),
      })
    );
    alert.success("Updated desired destination details")
    props.handleToggle(false);
  }
  return (
    <div className="aspiration_dialogbox_container">
      <form onSubmit={handleSubmit}>
        <h6 className="heading_text_time">Select desired destination</h6>
        <div className="main_destination_contianer">
          {allDestination.map((perDestination) => {
            return (
              <div key={Math.random()} className="checkbox_label_container2">
                <input
                  type="checkbox"
                  id={perDestination.id}
                  name="timeOfcontact"
                  value="checkValue"
                  className="checkbox_self"
                  onChange={() =>
                    selectDesiredCountry.current.push(
                      perDestination.country_name
                    )
                  }
                />
                <label htmlFor={perDestination.id} className="checkbox_label">
                  {perDestination.country_name}
                </label>
                <br />
              </div>
            );
          })}
        </div>

        <div className="btn_submit_cntainer">
          <button type="submit" className="submit_btn_dialog_box">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default DestinationDialogbox;
