import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchPostDetail } from "../../Api/Api";
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";

const PostDetail = () => {
  const { postId } = useParams();
  const [detail, setDetail] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (postId) {
        try {
          const data = await fetchPostDetail(postId);
          setDetail(data.data);
          // Handle the fetched data
        } catch (error) {
        }
      }
    };

    fetchData();
  }, [postId]);


  return (
    <div>
      <div>{detail?.id}</div>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
        target="_blank"
      >
        <FaFacebookF style={{ color: "#4267B2", fontSize: "16px" }} />
      </a>
      <li>
        <a
          href={`https://api.whatsapp.com/send?text=${window.location.href}`}
          target="_blank"
        >
          <FaWhatsapp style={{ color: "	#25D366", fontSize: "16px" }} />
        </a>
      </li>

          <hr />
    </div>
  );
};

export default PostDetail;