import React, { useState } from "react";
import "../CSS file/righteditprofile.css";

export default function HeaderSubtitle(props) {
  return (
    <div className="">
      <h6 className="date_of_title_text">{props.titleHeader}</h6>
      <h6 className="sub_text_date">{props.subTitleHeader}</h6>
    </div>
  );
}
