import React, { useState, useEffect } from "react";
import "../CSS file/univerleft.css";
import ContentUnivercity from "./ContentUnivercity";
import PaginationTab from "../../Components/Pagination tab/PaginationTab";
const univercity_img = require("../../assets/image 1.png");

export default function SectionUnivercitiesLeftSide(props) {
  return (
    <div className="conectin_under_left_side_contianer">
      <div className="content_tabs_univer_container">
        <div className="univer_wrap_container">
          <ContentUnivercity
            valueToRender={[
              {
                univercityImg: univercity_img,
                nameUnivercity: "Mohawk College",
                subnameUnivercity: "Canada",
              },
              {
                univercityImg: univercity_img,
                nameUnivercity: "Mohawk College",
                subnameUnivercity: "Canada",
              },

              {
                univercityImg: univercity_img,
                nameUnivercity: "Mohawk College",
                subnameUnivercity: "Canada",
              },
              {
                univercityImg: univercity_img,
                nameUnivercity: "Mohawk College",
                subnameUnivercity: "Canada",
              },

              {
                univercityImg: univercity_img,
                nameUnivercity: "Mohawk College",
                subnameUnivercity: "Canada",
              },
            ]}
          />
        </div>

        <div className="pagination_event_tab_container">
          <PaginationTab />
        </div>
      </div>
    </div>
  );
}
