import React, { useState, useEffect } from "react";
import edit_poster from "../../assets/poster_img.png";
import "../CSS file/righteditprofile.css";
import "../CSS file/tabbarstyle.css";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";
import Content6 from "./Content6";
import { profileUpdate, userLoginInfo, fetchToken } from "../../Api/Api";
import { useDispatch, useSelector } from "react-redux";

export default function Editprofile() {
  const [toggleState, setToggleState] = useState(1);
  const user = useSelector((state) => state.user.user);
  const [totalTabs, setTotalTabs] = useState([
    {
      tab: 1,
      tabText: "Personal",
    },
    {
      tab: 2,
      tabText: "Education & Work Experience",
    },
    {
      tab: 3,
      tabText: "Profile Eligibility",
    },
    {
      tab: 4,
      tabText: "Test",
    },
    {
      tab: 5,
      tabText: "Documents",
    },
  ]);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setUserDetails(user);
  }, [user]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const renderTabs = totalTabs.map((perTab) => {
    return (
      <button
        className={toggleState === perTab.tab ? "tabs active-tabs" : "tabs"}
        onClick={() => toggleTab(perTab.tab)}
        key={Math.random()}
      >
        {perTab.tabText}
      </button>
    );
  });

  return (
    <div className="right_side_under_edit_profile_">
      <div className="right_side_poster_profile_container">
        <div className="right_poster_extra_text">
          <h6 className="poster_editpro_text">
            Hi ,{" "}
            {userDetails?.user_personal_profile?.first_name ||
            userDetails?.user_personal_profile?.last_name
              ? userDetails?.user_personal_profile?.first_name +
                " " +
                userDetails?.user_personal_profile?.last_name
              : "User"}
          </h6>
          <p className="poster_extra_editpro_text">
            Let’s start your profile , create your dream{" "}
          </p>
        </div>
        <div className="poster_container_img">
          <img
            src={edit_poster}
            alt="poster"
            loading="lazy"
            className="img-fluid pooster_img_self"
          />
        </div>
      </div>

      <div className="tab_main_contianer">
        <h6 className="heading_text">Profile details</h6>

        <div className="">
          <div className="bloc-tabs">{renderTabs}</div>
        </div>
      </div>

      <div className="content-tabs">
        <div
          className={toggleState === 1 ? "content  active-content" : "content"}
        >
          <Content1 />
        </div>

        <div
          className={toggleState === 2 ? "content  active-content" : "content"}
        >
          <Content2 />
        </div>

        <div
          className={toggleState === 3 ? "content  active-content" : "content"}
        >
          <Content3 />
        </div>

        <div
          className={toggleState === 4 ? "content  active-content" : "content"}
        >
          <Content4 />
        </div>

        <div
          className={toggleState === 5 ? "content  active-content" : "content"}
        >
          <Content5 />
        </div>
        {/* <div
          className={toggleState === 6 ? "content  active-content" : "content"}
        >
          <Content6 />
        </div> */}
      </div>
    </div>
  );
}
