import React, { useState } from "react";
import "../CSS file/applynow.css";
import InputText from "../../Components/InputTextBox/InputText";
import SelectBox from "../../Components/SelectBox";
import { useFormik } from "formik";
import * as Yup from "yup";

function ApplicationDialogContent(props) {
  const allStages = [
    {
      stageName: "stage 1",
    },
    {
      stageName: "stage 2",
    },
  ];

  const allPaymentStatus = [
    {
      paymentStatus: "paid",
    },
    {
      paymentStatus: "unpaid",
    },
  ];

  const allCheckBoxText = [
    {
      requiredmentName: "LOR - Letter of recommendation",
    },
    {
      requiredmentName: "SOP - Statement of purpose",
    },
    {
      requiredmentName: "HighSchool marksheet",
    },
    {
      requiredmentName: "Intermediate marksheet",
    },
    {
      requiredmentName: "Graduation",
    },
    {
      requiredmentName: "Post graduation",
    },
    {
      requiredmentName: "Diploma",
    },
    {
      requiredmentName: "Score card",
    },
    {
      requiredmentName: "Work Experience",
    },
    {
      requiredmentName: "Bank Statement",
    },
    {
      requiredmentName: "Passport Front",
    },
    {
      requiredmentName: "Passport Back",
    },
    {
      requiredmentName: "Resume",
    },
    {
      requiredmentName: "School lerning certificate",
    },
    {
      requiredmentName: "Other",
    },
  ];

  const validationSchema = Yup.object({
    countryName: Yup.string().required("Country is required"),
    stateName: Yup.string().required("State is required"),
    universityName: Yup.string().required("University is required"),
    courseName: Yup.string().required("Course is required"),
    apkfeeName: Yup.string().required("Application Fee is required"),
    discountName: Yup.number()
      .typeError("Discount must be a number")
      .required("Discount is required"),
    tutfeeName: Yup.number()
      .typeError("Tuition Fee must be a number")
      .required("Tuition Fee is required"),
    commissionName: Yup.number()
      .typeError("Commission must be a number")
      .required("Commission is required"),
    intakeName: Yup.date().required("Intake date is required"),
    applicationStage: Yup.string().required("Application Stage is required"),
    paymentStatus: Yup.string().required("Payment Status is required"),
    requirements: Yup.array().of(Yup.string()),
    notes: Yup.string().max(501, "Notes cannot exceed 501 characters"),
  });

  const formik = useFormik({
    initialValues: {
      countryName: "",
      stateName: "",
      universityName: "",
      courseName: "",
      apkfeeName: "",
      discountName: "",
      tutfeeName: "",
      commissionName: "",
      intakeName: "",
      applicationStage: "",
      paymentStatus: "",
      requirements: [],
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
    
      // handle form submission here
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="apk_main_content_container">
      {/* <div className="apk_main_content_container"> */}
      <div className="apk_under_content_container">
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Country*"
            inputType="text"
            placeholderText="Enter country"
            inputId="countryId"
            name="countryName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.countryName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.countryName && formik.errors.countryName ? (
            <div>{formik.errors.countryName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="State*"
            inputType="text"
            placeholderText="Enter state"
            inputId="stateId"
            name="stateName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.stateName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.stateName && formik.errors.stateName ? (
            <div>{formik.errors.stateName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="University*"
            inputType="text"
            placeholderText="Enter university"
            inputId="universityId"
            name="universityName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.universityName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.universityName && formik.errors.universityName ? (
            <div>{formik.errors.universityName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Course*"
            inputType="text"
            placeholderText="Enter course"
            inputId="courseId"
            name="courseName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.courseName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.courseName && formik.errors.courseName ? (
            <div>{formik.errors.courseName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Application Fee*"
            inputType="text"
            placeholderText="Enter application fee"
            inputId="apkfeeId"
            name="apkfeeName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.apkfeeName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.apkfeeName && formik.errors.apkfeeName ? (
            <div>{formik.errors.apkfeeName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Discount*"
            inputType="number"
            placeholderText="Enter Discount (%)"
            inputId="discountId"
            name="discountName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.discountName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.discountName && formik.errors.discountName ? (
            <div>{formik.errors.discountName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Tutuion Fee*"
            inputType="number"
            placeholderText="Enter tutuion fee"
            inputId="tutfeeId"
            name="tutfeeName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.tutfeeName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tutfeeName && formik.errors.tutfeeName ? (
            <div>{formik.errors.tutfeeName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Commission (%)*"
            inputType="number"
            placeholderText="Enter Commission"
            inputId="commissionId"
            name="commissionName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.commissionName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.commissionName && formik.errors.commissionName ? (
            <div>{formik.errors.commissionName}</div>
          ) : null}
        </div>
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Intake*"
            inputType="date"
            placeholderText="Enter intake"
            inputId="intakeId"
            name="intakeName"
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.intakeName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.intakeName && formik.errors.intakeName ? (
            <div>{formik.errors.intakeName}</div>
          ) : null}
        </div>

        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Application Stage*"
            initialValue="Choose stage"
            options={allStages.map((perApkStage) => {
              return {
                optionName: perApkStage.stageName,
                optionValue: perApkStage.stageName,
              };
            })}
            name="applicationStage"
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={formik.values.applicationStage}
            selectValue={(value) =>
              formik.setFieldValue("applicationStage", value)
            }
          />
          {formik.touched.applicationStage && formik.errors.applicationStage ? (
            <div>{formik.errors.applicationStage}</div>
          ) : null}
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Payment Status*"
            initialValue="Choose status"
            options={allPaymentStatus.map((perPaymentStatus) => {
              return {
                optionName: perPaymentStatus.paymentStatus,
                optionValue: perPaymentStatus.paymentStatus,
              };
            })}
            name="paymentStatus"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.paymentStatus}
            selectValue={(value) =>
              formik.setFieldValue("paymentStatus", value)
            }
          />
          {formik.touched.paymentStatus && formik.errors.paymentStatus ? (
            <div>{formik.errors.paymentStatus}</div>
          ) : null}
        </div>
      </div>

      <div className="all_title_check_apk_content">
        <h6>Requirement*</h6>

        <div className="all_check_apk_content">
          {allCheckBoxText.map((perCheckRequirement, index) => {
            return (
              <div className="check_box_text_container" key={index}>
                <input
                  type="checkbox"
                  id={perCheckRequirement.requiredmentName}
                  name={perCheckRequirement.requiredmentName}
                  className="checkbox_self"
                  value={perCheckRequirement.requiredmentName}
                  onChange={(e) => {
                    const { checked, value } = e.target;
                    if (checked) {
                      formik.setFieldValue("requirements", [
                        ...formik.values.requirements,
                        value,
                      ]);
                    } else {
                      formik.setFieldValue(
                        "requirements",
                        formik.values.requirements.filter(
                          (item) => item !== value
                        )
                      );
                    }
                  }}
                  checked={formik.values.requirements.includes(
                    perCheckRequirement.requiredmentName
                  )}
                />
                <label
                  htmlFor={perCheckRequirement.requiredmentName}
                  className="checkbox_label"
                >
                  {perCheckRequirement.requiredmentName}
                </label>
              </div>
            );
          })}
        </div>
        <br />

        <h6>Notes*</h6>
        <textarea
          maxLength="501"
          id="notesId"
          name="notes"
          autoFocus
          placeholder="Write something.."
          className="textarea_about_style"
          onChange={formik.handleChange}
          value={formik.values.notes}
          onBlur={formik.handleBlur}
        />
        {formik.touched.notes && formik.errors.notes ? (
          <div>{formik.errors.notes}</div>
        ) : null}
        <div className="btn_submit_cntainer">
          <button className="submit_btn_dialog_box" type="submit">
            Submit
          </button>
        </div>
      </div>
      {/* </div> */}
    </form>
  );
}

export default ApplicationDialogContent;
