import React, { useState, useEffect } from "react";
import "../CSS file/univermain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
import SectionUnivercitiesLeftSide from "./SectionUnivercitiesLeftSide";
import UniverRightSide from "./UniverRightSide";
import { MdOutlineSearch } from "react-icons/md";
import CustDialogBox from "../../Components/CustDialogBox";
import FilterContent from "./FilterContent";
import { useSelector } from "react-redux";

export default function SectionOfUnivercity(props) {
  const [toggleModal, setToggleModal] = React.useState(false);
  const [section, setSection] = useState("Featured");
  const universityTypeToRender = useSelector(
    (state) => state.university.universityTypeToRender
  );

  function handleGroupSearch() {
    
  }

  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">
         
          {universityTypeToRender}
        
        </h4>

        <form className="connec_search_bar_contianer">
          <input
            type="search"
            className="input_search_conn"
            placeholder="Search univercities ..."
            autoComplete="off"
          />
          <button className="btn_conn_search" onClick={handleGroupSearch}>
            <MdOutlineSearch className="search_conn_self" />
          </button>
        </form>
      </div>
      <div className="filter_container_univercity">
        <button
          className="filter_btn_univercity"
          onClick={() => setToggleModal(true)}
        >
          Filter
        </button>

        <CustDialogBox
          dialogHeaderName="Use your preferences"
          open={toggleModal}
          onClose={() => setToggleModal(false)}
        >
          <FilterContent />
        </CustDialogBox>
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="left_side_connection_contianer">
          <SectionUnivercitiesLeftSide />
        </div>
        <div className="right_side_connection_contianer">
          <UniverRightSide section={section} />
        </div>
      </div>
    </div>
  );
}
