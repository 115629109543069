import React from "react";

function FactboxSkeleton(props) {
  return (
    <>
      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div
          style={{ height: 40, width: 40 }}
          className="view_detail_box_img_contianer skeleton skeleton_text"
        />
        <div className="view_detail_fact_text_subtitle_contianer skeleton_width">
          <div
            style={{ marginBottom: 10 }}
            className="view_detail_fact_title_text skeleton skeleton_text skeleton_width"
          />
          <div className="view_detail_fact_sub_title_text skeleton skeleton_text skeleton_width_short" />
        </div>
      </div>
      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div
          style={{ height: 40, width: 40 }}
          className="view_detail_box_img_contianer skeleton skeleton_text"
        />
        <div className="view_detail_fact_text_subtitle_contianer skeleton_width">
          <div
            style={{ marginBottom: 10 }}
            className="view_detail_fact_title_text skeleton skeleton_text skeleton_width"
          />
          <div className="view_detail_fact_sub_title_text skeleton skeleton_text skeleton_width_short" />
        </div>
      </div>
      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div
          style={{ height: 40, width: 40 }}
          className="view_detail_box_img_contianer skeleton skeleton_text"
        />
        <div className="view_detail_fact_text_subtitle_contianer skeleton_width">
          <div
            style={{ marginBottom: 10 }}
            className="view_detail_fact_title_text skeleton skeleton_text skeleton_width"
          />
          <div className="view_detail_fact_sub_title_text skeleton skeleton_text skeleton_width_short" />
        </div>
      </div>

      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div
          style={{ height: 40, width: 40 }}
          className="view_detail_box_img_contianer skeleton skeleton_text"
        />
        <div className="view_detail_fact_text_subtitle_contianer skeleton_width">
          <div
            style={{ marginBottom: 10 }}
            className="view_detail_fact_title_text skeleton skeleton_text skeleton_width"
          />
          <div className="view_detail_fact_sub_title_text skeleton skeleton_text skeleton_width_short" />
        </div>
      </div>
      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div
          style={{ height: 40, width: 40 }}
          className="view_detail_box_img_contianer skeleton skeleton_text"
        />
        <div className="view_detail_fact_text_subtitle_contianer skeleton_width">
          <div
            style={{ marginBottom: 10 }}
            className="view_detail_fact_title_text skeleton skeleton_text skeleton_width"
          />
          <div className="view_detail_fact_sub_title_text skeleton skeleton_text skeleton_width_short" />
        </div>
      </div>
    </>
  );
}

export default FactboxSkeleton;
