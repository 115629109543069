import { createSlice } from "@reduxjs/toolkit";
import { fetchGenders } from "../../Api/Api";

export const generalSlicer = createSlice({
  name: "general",
  initialState: {
    genders: [],
    isLoading: false,
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    storeGenderClassifications: (state, action) => {
      state.genders = action.payload;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeGenderClassifications,
  toggleLoader,
} = generalSlicer.actions;



export const fetchGenderClassificatons = () => async (dispatch) => {
  dispatch(toggleLoader(true));
  const genders = await fetchGenders();
  dispatch(storeGenderClassifications(genders.data));
};

export default generalSlicer.reducer;
