import React, { useState, useEffect } from "react";
import "../CSS file/univerleft.css";
import { useDispatch, useSelector } from "react-redux";
import ContentUnivercity from "./ContentUnivercity";
import ContentCourse from "./Courses/ContentCourse";
import ShortlistedUniver from "./ShortlistedUniver";
import ShortListCourses from "./Courses/ShortListCourses";
import { storeUniversityTypeToRender } from "../../StateManagement/slicers/universitySlicer";
import { storeSearchCoursesCategory, storeSearchUniversityCategory } from "../../StateManagement/slicers/userSlicer";

export default function UniverLeftSide() {
  let dispatch = useDispatch();
  const university = useSelector((state) => state.university.university);
  const course = useSelector((state) => state.university);
  const { shortlistedCourses } = useSelector((state) => state.course);
  const shortlistedUniversities = useSelector(
    (state) => state.university.shortlistedUniversities
  );

  const { tabToRender } = useSelector((state) => state.university);


  useEffect(() => {
    setToggleState(tabToRender);
  }, [tabToRender]);


  const [toggleState, setToggleState] = useState(1);
  const [totalTabs] = useState([
    {
      tab: 1,
      tabText: "Universities",
    },
    {
      tab: 2,
      tabText: "Courses",
    },
    {
      tab: 3,
      tabText: "Shortlisted Univercities",
    },
    {
      tab: 4,
      tabText: "Shortlisted Courses",
    },
  ]);


  const handleTabClick = (tab) => {
   
    setToggleState(tab);
    dispatch(
      storeUniversityTypeToRender({
        type: tab.tabText,
        tab: tab.tab,
      })
    );
    dispatch(storeSearchCoursesCategory([]));
    dispatch(storeSearchUniversityCategory([]));
    
 
  }


  const renderTabs = totalTabs.map((perTab) => {
   
    return (
      <button
        className={toggleState === perTab.tab ? "tabs active-tabs" : "tabs"}
        onClick={() => handleTabClick(perTab)}
        key={Math.random()}
      >
        {perTab.tabText}
        <span
          className={
            toggleState === perTab.tab
              ? " tab_count_text"
              : "tab_active_count_text"
          }
        >
          {perTab.tab === 1 ? university?.count : null}
          {perTab.tab === 2 ? course?.universitiesCourses?.total_courses : null}
          {perTab.tab === 3
            ? shortlistedUniversities?.data?.count
              ? shortlistedUniversities?.data?.count
              : 0
            : null}
          {perTab.tab === 4
            ? shortlistedCourses?.data?.count
              ? shortlistedCourses?.data?.count
              : 0
            : null}
        </span>
      </button>
    );
  });
  return (
    <div className="conectin_under_left_side_contianer">
      <div className="univer_tabs_container">
        <div className="tab_connection_contianer">
          <div className="bloc-tabs">{renderTabs}</div>
        </div>
      </div>

      <div className="content_tabs_univer_container">
        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <div className="univer_wrap_container">
              <ContentUnivercity />
            </div>
          </div>

          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <div className="univer_wrap_container">
              <ContentCourse />
            </div>
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <div className="univer_wrap_container">
              <ShortlistedUniver />
            </div>
          </div>

          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <div className="univer_wrap_container">
              <ShortListCourses />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
