import React, { useState, useEffect, useRef } from "react";
import CommonHeader from "../../Components/Common header/CommonHeader";
import "../CSS file/connectionmain.css";
import { MdOutlineSupportAgent } from "react-icons/md";
import "../CSS file/applicationsteper.css";
import ApplicationVisaMain from "./ApplicationVisaMain";
import PerStepSection from "./PerStepSection";
import CustDialogBox from "../../Components/CustDialogBox";
import ReqRelationshipContent from "./ReqRelationshipContent";
import UniversityCourseShortlisted from "./shortlisted table/UniversityCourseShortlisted";
import FloatingShortlistedBtn from "../../Components/FloatingShortlistedBtn";
const stage_icon = require("../../assets/stepIcon/step1_icon.svg");
const stage_icon1 = require("../../assets/stepIcon/step1_icon.svg");
const stage_icon2 = require("../../assets/stepIcon/step1_icon.svg");
const stage_icon3 = require("../../assets/stepIcon/step1_icon.svg");
const stage_icon4 = require("../../assets/stepIcon/step1_icon.svg");

function ApplicationStepper(props) {
  const [stepClick, setStepClick] = useState(1);
  const [stepperId, setStepperId] = useState("");
  const [toggleModal, setToggleModal] = useState(false);

  const handleOpenRelationship = () => {
    setToggleModal(true);
  };

  const handleSubmit = (e) => {
    setToggleModal(false);
  };

  const [allStepTitle, setAllStepTitle] = useState([
    {
      countStep: 1,
      titleStep: `Get along & explore`,
      stageProgress: "In Progress",
      stageIcon: stage_icon,
      stageName: "Get along & explore.",
      stageFirstContent: `<p> there!</p>
      <p>Get a smooth sail on our portal and explore the universities and courses that interest you.</p>`,
      stageAgendaSuccessMsg: [
        {
          aegendaContent: `Get started on your journey with us and tell us about your future plans. We’re curious!`,
        },
        {
          aegendaContent: `ready started on your journey with us and tell us about your future plans. We’re curious!`,
        },
      ],
      stageAgendaUnSuccessMsg: `Know more about us. `,
      stageExtraText:
        "<p>(Previous sub-stages: qualified, sent for university listing,university shortlisted)</p>",
    },
    {
      countStep: 2,
      titleStep: "Evaluate & map",
      stageProgress: "In Progress",
      stageIcon: stage_icon,
      stageName: "Evaluate & map",
      stageFirstContent: `<p>Verify your eligibility and pre-requisites for the countries, universities and courses you're interested in! Our team of experts is always there to assist you in making choices and finding your ideal match.
      </p>`,
      stageAgendaSuccessMsg: [
        {
          aegendaContent: `Fill us in with your academic background.`,
        },
        {
          aegendaContent: `Counselor – (name) has accepted your request.`,
        },
        {
          aegendaContent: `Explore courses & universities.`,
        },
        {
          aegendaContent: `Save your favorite courses.`,
        },
      ],

      stageExtraText: "",
    },
    {
      countStep: 3,
      titleStep: "Application Process & Status",
      stageProgress: "In Progress",
      stageIcon: stage_icon,
      stageName: "Application Process & Status",
      stageFirstContent: `<p>&ldquo;Where is my application?&rdquo; (Alternate title)<br />Assess and learn the real-time status of your application. <br /></p>`,
      stageAgendaSuccessMsg: [
        {
          aegendaContent: `  Application under process`,
        },
        {
          aegendaContent: `Deferral process initiated.`,
        },
        {
          aegendaContent: `Pre-submission apply`,
        },
        {
          aegendaContent: `Post-submission apply`,
        },
        {
          aegendaContent: `Application submitted to institute `,
        },
        {
          aegendaContent: `Offer (received/rejected)`,
        },
        {
          aegendaContent: `Tuition deposit (pending/completed)`,
        },
      ],
      stageExtraText: "",
    },
    {
      countStep: 4,
      titleStep: "Visa Process & Status",
      stageProgress: "In Progress",
      stageIcon: stage_icon,
      stageName: "Application Process & Status",
      stageFirstContent: `<p>Your visa is the document that allows you to pursue a study program in your desired country. Track it here on real-time basis and ensure your growth!</p>`,
      stageAgendaSuccessMsg: [
        {
          aegendaContent: ` Visa application under-process`,
        },
        {
          aegendaContent: `Visa application submitted`,
        },
        {
          aegendaContent: `Biometrics (Pending/Completed)`,
        },
        {
          aegendaContent: `Medical (Pending/Completed)`,
        },
        {
          aegendaContent: `Visa fees (Pending/Completed)`,
        },
        {
          aegendaContent: `Visa appointment booking (Pending/Completed)`,
        },
        {
          aegendaContent: `Visa status (granted/rejected)`,
        },
      ],
      stageExtraText: "<p>(If rejected,<br />Re-application process stage)</p>",
    },
    {
      countStep: 5,
      titleStep: "Off you go",
      stageProgress: "In Progress",
      stageIcon: stage_icon,
      stageName: "Off you go!",
      stageFirstContent: `<p>Our pre-departure services ensure your safety and comfort, during and after you reach your destination, till the start of your program. Yes, you have our companionship till you make it to your school!
      </p>`,
      stageAgendaSuccessMsg: [
        {
          aegendaContent: `Pre-departure service`,
        },
        {
          aegendaContent: `Enrollment`,
        },
        {
          aegendaContent: `Program start (on-campus)`,
        },
        {
          aegendaContent: `Program start (online)`,
        },
      ],
      stageExtraText: "",
    },
  ]);

  const [stepvalue, setStepValue] = useState({
    countStep: 1,
    titleStep: `Get along & explore`,
    stageProgress: "In Progress",
    stageIcon: stage_icon,
    stageName: "Get along & explore.",
    stageFirstContent: `
    <p>Hey there! 👋 &nbsp; <br/>Get a smooth sail on our portal and explore the universities and courses that interest you.</p>`,
    stageAgendaSuccessMsg: [
      {
        aegendaContent: `Get started on your journey with us and tell us about your future plans. We’re curious!`,
        value: 1,
      },
      {
        aegendaContent: `ready started on your journey with us and tell us about your future plans. We’re curious!`,
        value: 0,
      },
    ],

    stageExtraText:
      "<p>(Previous sub-stages: qualified, sent for university listing,university shortlisted)</p>",
  });

  const toggleStep = (index) => {
    setStepClick(index);
    setStepValue(allStepTitle[index]);
  };

  function handleNextStepCount(index) {
    setStepValue(allStepTitle[index]);
    setStepClick(index + 1);
    //setStepperId(indeind)
  }



  const renderStepperData = allStepTitle.map((perStepTitle) => {
    // setStepValue(perStepTitle.countStep);
    return (
      <a
        href="#nextStepper"
        key={Math.random()}
        className={
          stepClick === perStepTitle.countStep
            ? "step-wizard-item current-item"
            : "step-wizard-item"
        }
        onClick={() => {
          toggleStep(perStepTitle.countStep);
          setStepValue(perStepTitle);
        }}
      >
        <span className="progress-count">{perStepTitle.countStep}</span>
        <span className="progress-label">{perStepTitle.titleStep}</span>
      </a>
    );
  });

  return (
    <div style={{ background: "#f3f3f3" }}>
      <div className="main_connection_contianer">
        <CommonHeader />
        <div className="conn_title_search_bar_contianer">
          <h4 className="conn_title">Your Journey</h4>

          <button className="animated-button1" onClick={handleOpenRelationship}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <MdOutlineSupportAgent /> Request Relationship Member
          </button>
        </div>

        <CustDialogBox
          dialogHeaderName="Request your Relationships Member"
          open={toggleModal}
          onClose={() => setToggleModal(false)}
        >
          <ReqRelationshipContent toggleModal={handleSubmit} />
        </CustDialogBox>

        <hr className="conn_line" />

        <div className="connection_under_main_container">
          <div className="step_main_container">
            <section className="step-wizard">
              <ul className="step-wizard-list">{renderStepperData}</ul>
            </section>

            <section className="description_container_stepper">
              <div className={"content1  active-content"}>
                <PerStepSection stepvalue={stepvalue} />
              </div>
              {stepClick < 5 ? (
                <button
                  className="next_btn_apk_"
                  onClick={() => handleNextStepCount(stepClick)}
                >
                  <span>Next</span>
                </button>
              ) : null}
            </section>
          </div>
        </div>

        <hr className="conn_line" />
        <div className="conn_title_search_bar_contianer">
          <h4 className="conn_title">ShortListed Universities and Courses</h4>
        </div>
        <UniversityCourseShortlisted />

        <div className="conn_title_search_bar_contianer">
          <h4 className="conn_title">Your Application</h4>
        </div>

        <ApplicationVisaMain />
      </div>
      <FloatingShortlistedBtn />
    </div>
  );
}

export default ApplicationStepper;
