import React, { useState, useEffect } from "react";
import "../CSS file/univerright.css";
import BoxTextSubText from "../../Components/box text subtext/BoxTextSubText";
import { useSelector } from "react-redux";

export default function UniverRightSide(props) {
  const { universityTypeToRender, tabToRender } = useSelector(
    (state) => state.university
  );
  const courseTypeToRender = useSelector(
    (state) => state.course.courseTypeToRender
  );

  

  return (
    <div className="conectin_under_right_side_contianer">
      <div className="all_popular_univercity_container">
      {universityTypeToRender !== "Universities" &&
          tabToRender === 1 ? (
          <BoxTextSubText sectionUniversityCourseName="Universities" />
        ) : null}
        {universityTypeToRender !== "Popular Univercities" &&
          tabToRender === 1 ? (
          <BoxTextSubText sectionUniversityCourseName="Popular Univercities" />
        ) : null}

        {universityTypeToRender !== "Recommended Univercities" &&
          tabToRender === 1 ? (
          <BoxTextSubText sectionUniversityCourseName="Recommended Univercities" />
        ) : null}
 
        {universityTypeToRender !== "Featured Univercities" &&
          tabToRender === 1 ? (
          <BoxTextSubText sectionUniversityCourseName="Featured Univercities" />
        ) : null}
        

        {courseTypeToRender !== "Popular Courses" && tabToRender === 2 ? (
          <BoxTextSubText sectionUniversityCourseName="Popular Courses" />
        ) : null}

        {courseTypeToRender !== "Recommended Courses" && tabToRender === 2 ? (
          <BoxTextSubText sectionUniversityCourseName="Recommended Courses" />
        ) : null}

        {courseTypeToRender !== "Featured Courses" && tabToRender === 2 ? (
          <BoxTextSubText sectionUniversityCourseName="Featured Courses" />
        ) : null}
        
      </div>
    </div>
  );
}
