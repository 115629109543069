import React from "react";
import { useNavigate } from "react-router-dom";
import "./CSS file/errorpage.css";
const Error_img = require("../assets/error_img.svg");

function ErrorPage(props) {
  const navigate = useNavigate();

  const handleHomepage = () => {
    
    navigate("/feeds");
  };
  return (
    <div className="main_error_page_container">
      <img
        className="error_img"
        alt="404"
        loading="lazy"
        src={Error_img.default}
      />
      <p className="page_not_found_txt">Page Not Found</p>

      <button onClick={handleHomepage} className="home_page_btn">
        Home Page
      </button>
    </div>
  );
}

export default ErrorPage;
