import React, { useState, useEffect, useRef } from "react";
import "../CSS file/intropage.css";
import "../CSS file/personalpage.css";
import {
  updateLoggedInUser,
  fetchLoggedInUser,
} from "../../StateManagement/slicers/userSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { fetchCountriesData } from "../../StateManagement/slicers/countrySlicer";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import { fetchGenderClassificatons } from "../../StateManagement/slicers/generalSlicer";
const robo_icon = require("../../assets/bot.png");

function PerosonalPage() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personalPageData = useSelector((state) => state.user.user);
  const genders = useSelector((state) => state.general.genders);
  const token = useSelector((state) => state.token.token);
  const countries = useSelector((state) => state.country.countries);
  const [userProfile, setUserProfile] = useState({});
  const [gender, setGender] = useState(null);
  const [gendersToRender, setGendersToRender] = useState([]);
  const [userDetailsToUpdate, setUserDetailsToUpdate] = useState({});
  const [maxSupportedDate, setMaxSupportedDate] = useState("");
  const [countriesToRender, setCountriesToRender] = useState([]);
  const [eligibleToGoThrough, setEligibleToGoThrough] = useState(false);

  useEffect(() => {
    dispatch(fetchApiBearerToken());
    dispatch(fetchCountriesData());
  }, [dispatch]);

  useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      dispatch(fetchLoggedInUser());
      dispatch(fetchGenderClassificatons());
    }
  }, [token, dispatch]);


  useEffect(() => {
    let years = 7; // Days you want to subtrac
    let today = new Date();
    let maxYear = new Date(today.setFullYear(today.getFullYear() - years));
    const [onlyDate] = maxYear.toISOString().split("T");
    setMaxSupportedDate(onlyDate);
  }, []);

  useEffect(() => {
    if (personalPageData) {
      setUserProfile(personalPageData);
      if (
        personalPageData?.user_personal_profile?.gender_id &&
        personalPageData?.user_personal_profile?.selected_citizenship_id &&
        personalPageData?.user_personal_profile?.selected_country_id &&
        personalPageData?.user_personal_profile?.phone !== null &&
        personalPageData?.user_personal_profile?.phone !== "" &&
        personalPageData?.user_personal_profile?.date_of_birth !== "" &&
        personalPageData?.user_personal_profile?.date_of_birth !== null
      ) {
        setEligibleToGoThrough(true);
      }

      if (personalPageData?.user_personal_profile?.gender_id) {
        setGender(personalPageData?.user_personal_profile?.gender_id);
      }

      if (
        personalPageData?.user_personal_profile?.phone !== null &&
        personalPageData?.user_personal_profile?.phone !== ""
      ) {
        setUserDetailsToUpdate((preValue) => ({
          ...preValue,
          phone: personalPageData?.user_personal_profile?.phone,
        }));
      }

      if (
        personalPageData?.user_personal_profile?.date_of_birth !== null &&
        personalPageData?.user_personal_profile?.date_of_birth !== ""
      ) {
        setUserDetailsToUpdate((preValue) => ({
          ...preValue,
          date_of_birth: personalPageData?.user_personal_profile?.date_of_birth,
        }));
      }

      if (personalPageData?.user_personal_profile?.selected_citizenship_id) {
        setUserDetailsToUpdate((preValue) => ({
          ...preValue,
          citizenship: personalPageData?.user_personal_profile?.citizenship,
        }));
      }
      if (personalPageData?.user_personal_profile?.selected_country_id) {
        setUserDetailsToUpdate((preValue) => ({
          ...preValue,
          phone: personalPageData?.user_personal_profile?.country,
        }));
      }
    }
  }, [personalPageData]);

  useEffect(() => {
    if (genders.length > 0) {
      setGendersToRender(genders);
    }
  }, [genders]);

  useEffect(() => {
    if (countries.length > 0) {
      setCountriesToRender(countries);
    }
  }, [countries]);

  // useEffect(() => {
  // },[userDetailsToUpdate, gender])

  function handleGender(e) {
    setUserProfile((preValue) => ({
      ...preValue,
      user_personal_profile: {
        ...preValue.user_personal_profile,
        gender_id: e,
      },
    }));
    setGender(e);
  }

  function handleSubmit(e) {
    e.preventDefault();
    let formDataFields = new FormData();

    if (eligibleToGoThrough) {
      dispatch(updateLoggedInUser({ no_of_steps_followed: 2 }));
      navigate("/IntroPage");
    } else if (
      userDetailsToUpdate.date_of_birth !== "" &&
      userDetailsToUpdate.date_of_birth !== null &&
      userDetailsToUpdate.phone !== "" &&
      userDetailsToUpdate.phone !== null &&
      userDetailsToUpdate.country !== "DEFAULT" &&
      userDetailsToUpdate.country !== "" &&
      userDetailsToUpdate.country !== null &&
      userDetailsToUpdate.citizenship !== "DEFAULT" &&
      userDetailsToUpdate.citizenship !== null &&
      userDetailsToUpdate.citizenship !== "" &&
      gender !== null &&
      gender !== ""
    ) {
      if (userDetailsToUpdate.date_of_birth) {
        formDataFields.append(
          "date_of_birth",
          userDetailsToUpdate.date_of_birth
        );
      }
      if (userDetailsToUpdate.phone) {
        formDataFields.append("phone", userDetailsToUpdate.phone);
      }
      if (gender) {
        formDataFields.append("gender", gender);
      }
      if (userDetailsToUpdate.country) {
        formDataFields.append("country", userDetailsToUpdate.country);
      }
      if (userDetailsToUpdate.citizenship) {
        formDataFields.append("citizenship", userDetailsToUpdate.citizenship);
      }

      formDataFields.append("no_of_steps_followed", 2);
      formDataFields.append("is_fresh_user", true);

      dispatch(updateLoggedInUser(formDataFields));
      navigate("/IntroPage");
    } else {
      alert.show("Please Fill all the details! So that we can help You out!", {
        type: "error",
      });
    }
  }

  return (
    <section className="intro_page_main_container">
      <div className="intro_under_page_container">
        <div className="intro_all_content_container">
          <div className="intro_robo_container">
            <img src={robo_icon} alt="robo_icon" loading="lazy" />
          </div>
          <div className="all_under_content_container_intro">
            {userProfile?.user_personal_profile?.is_fresh_user === "0" ? (

              <>
                <span className="name_text_ps_">
                  Hello,{userProfile?.username || userProfile?.email}, Remember
                  me? <br /> I'm Rainbow, and We’ll do awesome things together !
                  🎉
                </span>
                <span className="lightname_text_ps_">
                  But first things first - we've been interrupted last time.
                  Will you find a moment to tell me about yourself?
                </span>
              </>
            ) : (
              <></>
            )}

            <div className="ps_all_input_container">
              <span className="input_text_ps_container">
                I was born on
                <input
                  className="text_input_ps"
                  type="date"
                  autoComplete="off"
                  id="start"
                  name="start"
                  max={maxSupportedDate}
                  value={
                    userProfile?.user_personal_profile?.date_of_birth
                      ? userProfile?.user_personal_profile?.date_of_birth
                      : ""
                  }
                  onChange={(e) => {
                    setUserDetailsToUpdate((preValue) => ({
                      ...preValue,
                      date_of_birth: e.target.value,
                    }));
                    setUserProfile((preValue) => ({
                      ...preValue,
                      user_personal_profile: {
                        ...preValue.user_personal_profile,
                        date_of_birth: e.target.value,
                      },
                    }));
                  }}
                // onChange={handleChange}
                />{" "}
                in
                <select
                  className="text_input_ps"
                  name="country"
                  id="country-select"
                  value={
                    userProfile?.user_personal_profile?.selected_country_id
                      ? userProfile?.user_personal_profile?.selected_country_id
                      : "DEFAULT"
                  }
                  onChange={(e) => {
                    setUserDetailsToUpdate((preValue) => ({
                      ...preValue,
                      country: e.target.value,
                    }));
                    setUserProfile((preValue) => ({
                      ...preValue,
                      user_personal_profile: {
                        ...preValue.user_personal_profile,
                        selected_country_id: parseInt(e.target.value),
                      },
                    }));
                  }}
                >
                  {userProfile?.user_personal_profile?.selected_country_id ? (
                    <></>
                  ) : (
                    <option key={Math.random()} value="DEFAULT">
                      Select Birth Country
                    </option>
                  )}

                  {countriesToRender.map((perDestination) => {
                    return (
                      <option key={Math.random()} value={perDestination.id}>
                        {perDestination.country_name}
                      </option>
                    );
                  })}
                </select>
                and I am
                <select
                  className="text_input_ps"
                  name="country"
                  id="country-select"
                  value={
                    userProfile?.user_personal_profile?.z
                      ? userProfile?.user_personal_profile
                        ?.z
                      : "DEFAULT"
                  }
                  onChange={(e) => {
                    setUserDetailsToUpdate((preValue) => ({
                      ...preValue,
                      citizenship: e.target.value,
                    }));
                    setUserProfile((preValue) => ({
                      ...preValue,
                      user_personal_profile: {
                        ...preValue.user_personal_profile,
                        selected_citizenship_id: parseInt(e.target.value),
                      },
                    }));
                  }}
                >
                  {userProfile?.user_personal_profile
                    ?.selected_citizenship_id ? (
                    <></>
                  ) : (
                    <option key={Math.random()} value="DEFAULT">
                      Select Your Citizenship
                    </option>
                  )}
                 
                  {countriesToRender.map((perDestination) => {
                    return (
                      <option key={Math.random()} value={perDestination.id}>
                        {perDestination.country_name}
                      </option>
                    );
                  })}
                </select>
                <br />
                I’m also a
                <div className="gender__btn_container">
                  {gendersToRender.length > 0
                    ? gendersToRender.map((perGender) => {
                      return (
                        <button
                          type="button"
                          className={
                            gender === perGender.id ||
                              userProfile?.user_personal_profile?.gender_id ===
                              perGender.id
                              ? "gender_btn_self_selected"
                              : "gender_btn_self"
                          }
                          key={Math.random()}
                          onClick={() => handleGender(perGender.id)}
                        >
                          {perGender.name}
                        </button>
                      );
                    })
                    : null}
                </div>
                your mail is{" "}
                <input
                  className="text_input_ps"
                  type="email"
                  autoComplete="off"
                  id="email"
                  placeholder="Enter email"
                  pattern=".+@globex\.com"
                  disabled
                  defaultValue={userProfile?.email}
                // onChange={handleChange}
                />
                and mobile number
                <input
                  className="text_input_ps"
                  type="number"
                  placeholder="Enter mobile no."
                  id="number"
                  autoComplete="off"
                  name="number"
                  max="10"
                  value={
                    userProfile?.user_personal_profile?.phone
                      ? userProfile?.user_personal_profile?.phone
                      : ""
                  }
                  onChange={(e) => {
                    setUserDetailsToUpdate((preValue) => ({
                      ...preValue,
                      phone: e.target.value,
                    }));
                    setUserProfile((preValue) => ({
                      ...preValue,
                      user_personal_profile: {
                        ...preValue.user_personal_profile,
                        phone: e.target.value,
                      },
                    }));
                  }}
                // onChange={handleChange}
                />
              </span>
            </div>
          </div>

          <div className="next_btn_container_ps">
            <div className="btn_continue_later_text_container">
              <button
                type="button"
                className="sure_btn_self"
                // onClick={() => navigate("/IntroPage")}
                onClick={handleSubmit}
              >
                Continue
              </button>
              <button
                type="button"
                className="later_btn_self"
                onClick={() => {
                  dispatch(updateLoggedInUser({ is_fresh_user: false }));
                  navigate("/ApplicationStepper");
                }}
              >
                Maybe later
              </button>

              <span className="remember_text_all">
                Remember you can always change all information on{" "}
                <span className="highlight_text_blue_intro">your Profile</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PerosonalPage;
