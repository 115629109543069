import React from "react";
const filterImg1 = require("../../assets/bot.png");



function FilterList({ imgName, titleName, subTitleName }) {
    const allTitleSubtitleData = [
        {
            imgName: filterImg1,
            titleName: "Univercity Name",
            subTitleName: `Pace University`,
        },
    ];

    return (

        <div
            className="filter_title_sub_data_img_container"
            key={Math.random()}
        >
            <div className="filter_data_img_container">
                <img
                    src={imgName.default}
                    alt="filterImg1"
                />
            </div>
            <div className="title_subtitle_filter_container">
                <h6 title={titleName}>{titleName}</h6>
                <h6 title={subTitleName}>
                    {subTitleName?.length > 30
                        ? `${subTitleName?.substring(0, 27)}...`
                        : subTitleName}
                </h6>
            </div>
        </div>


    );
}

export default FilterList;