import React, { useState } from "react";
import { MdClose, MdCheckCircle, MdBlock } from "react-icons/md";
import "./css files/filterright.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import FilterList from "./FilterList";
import { addOrRemoveUserPreferenceOnCourses } from "../../StateManagement/slicers/userSlicer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchApplyFilterOnCourse, storeFilterListSelector } from "../../StateManagement/slicers/filterSlicer";
const filterImg1 = require("../../assets/filtericon/university.svg");
const filterImg2 = require("../../assets/filtericon/withdegree.svg");
const filterImg3 = require("../../assets/filtericon/langtext.svg");
const filterImg4 = require("../../assets/filtericon/country.svg");
const filterImg5 = require("../../assets/filtericon/intake.svg");
const filterImg6 = require("../../assets/filtericon/additionaltest.svg");
const filterImg7 = require("../../assets/filtericon/specialization.svg");
const filterImg8 = require("../../assets/filtericon/duration.svg");
const filterImg9 = require("../../assets/filtericon/tutfee.svg");

function FilterRightSide(props) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { filterList, filterData } = useSelector((state) => state.filter);
  const specializations = useSelector((state) => state.study.specializations);
  const programLevel = useSelector((state) => state.study.programLevels);
  const intakes = useSelector((state) => state.study.intakes);
  const duration = useSelector((state) => state.study.duration);
  const course = useSelector((state) => state.course.course);
  const [selectedValue, setSelectedValue] = useState([]);
  const { courseTypeToRender } = useSelector((state) => state.course);


  // useEffect(() => {

  //   if (filterData.country && filterData.degree && filterData.duration && filterData.intake && filterData.course && filterData.study_area && filterData.additional_test && filterData.tuitionFees) {
  //     dispatch(fetchApplyFilterOnCourse(filterData));
  //   }

  // }, [dispatch, filterData]);


  function shortlistCourse(course) {


    const index = selectedValue.indexOf(course.perCourseContent.id);
    if (index > -1 || course.perCourseContent.if_shortlisted) {

      setSelectedValue(
        selectedValue.filter((i) => i !== course.perCourseContent.id)
      );
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: false,
          course_id: course.perCourseContent.course_id,
          university_id: course.perCourseContent.university_id,
        })
      );
    } else {

      setSelectedValue([...selectedValue, course.perCourseContent.id]);
      dispatch(
        addOrRemoveUserPreferenceOnCourses({
          if_shortlisted: true,
          course_id: course.perCourseContent.course_id,
          university_id: course.perCourseContent.university_id,
        })
      );
    }
  }

  function handleDeleteFilterData(key) {

    let newFilterData = { ...filterData };
    delete newFilterData[key];
    dispatch(storeFilterListSelector(newFilterData));


  }


  function handleCourseSelect(e) {
    
    const univercityId = e?.course_given_by_university?.id;
    const univercityName = e?.course_given_by_university?.u_name;
    const encodedUnivercityName = encodeURIComponent(univercityName);
    const path = `/UnivercityMain/ViewDetailUnivercity/${univercityId}/${encodedUnivercityName}`;

    navigate(path);
  }

  return (
    <>


      <div className="filter_right_side_main_container">
        <div className="highlighted_filter_btns">
          {filterData?.country && filterData?.country !== null ? <div className="text_close_btn" key={Math.random()}
            onClick={() => handleDeleteFilterData("country")}
          >
            {filterData?.country ? filterData?.country : null}<MdClose />
          </div> : null}
          {filterData?.degree && filterData.degree !== null ? <div className="text_close_btn" key={Math.random()}
            onClick={() => handleDeleteFilterData("degree")}
          >
            {
              programLevel ? programLevel.filter((perProgramLevel) => { return perProgramLevel.id === parseInt(filterData?.degree) })[0]?.program_level_name : null
            }<MdClose />
          </div> : null}
          {filterData?.duration &&
            filterData?.duration !== null
            ? <div className="text_close_btn" key={Math.random()}
              onClick={() => handleDeleteFilterData("duration")}
            >
              {
                duration?.filter((perDuration) => { return perDuration.id === parseInt(filterData?.duration) })[0]?.duration_range
              }<MdClose />
            </div> : null}
          {filterData?.intake && filterData?.intake !== null ? <div className="text_close_btn" key={Math.random()}

            onClick={() => handleDeleteFilterData("intake")}
          >
            {
              intakes.filter((perIntake) => { return perIntake.id === parseInt(filterData?.intake) })[0]?.intake_name
            }<MdClose />

          </div> : null
          }

          {filterData?.course && filterData?.course !== null ?
            filterData?.course?.length > 0
              ?
              <div className="text_close_btn"
                onClick={() => handleDeleteFilterData("course")}
              >
                {
                  filterData?.course?.length > 0 ? course?.filter((perCourse) => { return perCourse?.id === parseInt(filterData?.course) })[0]?.name : null
                }<MdClose />
              </div> : null : null}
          {filterData?.study_area && filterData?.study_area.length > 0 ?
            filterData?.study_area ?
              <div className="text_close_btn" key={Math.random()}
                onClick={() => handleDeleteFilterData("study_area")}
              >
                {
                  filterData?.study_area.length > 0 ? specializations.filter((perSpecialization) => { return perSpecialization.id === parseInt(filterData?.study_area) })[0]?.name : null

                }<MdClose />
              </div> : null : null}

          {filterData?.additional_test && filterData?.additional_test !== null ? <div className="text_close_btn" key={Math.random()}
            onClick={() => handleDeleteFilterData("additional_test")}
          >
            {
              filterData?.additional_test.toUpperCase()
            }<MdClose />
          </div> : null}
          {filterData?.tuitionFees && filterData?.tuitionFees?.length > 0 ? <div className="text_close_btn" key={Math.random()}
            onClick={() => handleDeleteFilterData("tuitionFees")}
          >
            {filterData?.tuitionFees?.length > 0 ? filterData?.tuitionFees : null}<MdClose />
          </div> : null}


        </div>


        {filterList?.length > 0 ? filterList && filterList.map((perCourseContent, index) => {

          return (

            <div className="data_of_result_filter_container">
              <h6>{perCourseContent?.course_has_entries_in_course
                ?.name
              }</h6>
              <div className="data_row_container">

                <FilterList
                  imgName={filterImg1}
                  titleName="Univercity Name"
                  subTitleName={perCourseContent?.course_given_by_university?.u_name
                  }
                />

                <FilterList
                  imgName={filterImg2}
                  titleName="With a degree"
                  subTitleName={perCourseContent.course_has_entries_in_course?.study_area_id[0]?.name
                  }
                />

                <FilterList
                  imgName={filterImg3}
                  titleName="Language Test"
                  subTitleName={perCourseContent.language_test
                  }
                />

                <FilterList
                  imgName={filterImg4}
                  titleName="Country name"
                  subTitleName={perCourseContent?.universities_location[0]?.country.country_name}
                />

                <FilterList
                  imgName={filterImg5}
                  titleName="Intake"
                  subTitleName={
                    perCourseContent.intake_ids.split(",").map((item) => { //aa value apdane api mathi malse but value joi ne put karje backend ma chhe e 
                      return intakes.filter((perIntake) => { //ahiya api call karje //specualization / intakes / program level / duration
                        return perIntake.id === parseInt(item) // aa apdu compare karine e id na data malse 
                      })[0]?.intake_name // print karva mate data malse
                    }
                    )




                  }

                />

                <FilterList
                  imgName={filterImg6}
                  titleName="Additional Test"
                  subTitleName={perCourseContent.additional_test
                  }
                />

                <FilterList
                  imgName={filterImg7}
                  titleName="Specialization"
                  subTitleName={perCourseContent.specialization
                  }
                />

                <FilterList
                  imgName={filterImg8}
                  titleName="Duration"
                  subTitleName={perCourseContent.duration
                  }
                />

                <FilterList
                  imgName={filterImg9}
                  titleName="Tution fee"
                  subTitleName={perCourseContent.tution_fee
                  }
                />

              </div>
              <div className="shortlisted_btn_container_filter_right_side">
                <button
                  type="button"
                  className={
                    perCourseContent?.if_shortlisted === true ||
                      selectedValue.includes(perCourseContent.id)
                      ? "like_icon_container"
                      : "like_icon_container red_color"
                  }
                  onClick={() =>
                    shortlistCourse({
                      perCourseContent: perCourseContent,
                      index: index,
                    })
                  }
                >
                  {perCourseContent?.if_shortlisted === true ||
                    perCourseContent?.if_shortlisted === "1" ||

                    perCourseContent?.if_shortlisted === 1 ||
                    courseTypeToRender === "Shortlisted Courses" ||
                    selectedValue.includes(perCourseContent.id) ? (
                    <div className="icon_block_text_container">
                      <MdCheckCircle className="blue_color" /> Shortlisted
                    </div>
                  ) : (
                    <div className="icon_block_text_container">
                      <MdBlock className="red_color" /> Shortlist
                    </div>
                  )}
                </button>

                <button onClick={() => handleCourseSelect(
                  perCourseContent
                )}>Details</button>
              </div>
            </div>

          )
        })
          : <div className="no_data_found_container">

            <h6>No data found</h6>
          </div>
        }
      </div>
    </>
  );
}

export default FilterRightSide;
