import React, { useState } from "react";
import "../../CSS file/univerright.css";
import BoxTextSubText from "../../../Components/box text subtext/BoxTextSubText";
const group_img_right = require("../../../assets/courses.png");

export default function CourseRightSide() {
  return (
    <div className="conectin_under_right_side_contianer">
      <div className="all_popular_univercity_container">
        <BoxTextSubText sectionUniversityCourseName="Popular Courses" />

        <BoxTextSubText sectionUniversityCourseName="Recommended Courses" />
      </div>
    </div>
  );
}
