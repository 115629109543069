import React, { useState, useRef, useEffect } from "react";
import "../../CSS file/personaldialog.css";
import photo_upload from "../../../assets/poster.jpg";
import InputText from "../../../Components/InputTextBox/InputText";
import "../../../Components/InputTextBox/inputtext.css";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CustLoader from "../../../Components/Loader/CustLoader";
import { fetchGenderClassificatons } from "../../../StateManagement/slicers/generalSlicer";
import { updateLoggedInUser } from "../../../StateManagement/slicers/userSlicer.js";
import { useAlert } from "react-alert";

export default function PersonalDialogbox(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const user = useSelector((state) => state.user.user);
  const [userProfileUpdate, setUserProfileUpdate] = useState({});
  const [userDetailsToUpdate, setUserDetailsToUpdate] = useState({});
  const [loading, setLoading] = useState("");
  const [count, setCount] = React.useState(0);
  const counter = useRef(0);
  const [image, setImage] = useState({ preview: "", raw: "" });

  useEffect(() => {
    setUserProfileUpdate(user);
  }, [user]);

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= image.length) {
      setLoading(false);
    }
  };

  function handleUploadPhoto(e) {
    setUserDetailsToUpdate((preValue) => ({
      ...preValue,
      profile_pic: e.target.files[0],
    }));

    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  }

  function handleCloseItem() {
    setImage("");
  }

  function handleRemoveImg() {
    setImage("");
  }

  async function handleProfileSubmit(e) {
    e.preventDefault();
    let formDataFields = new FormData();


    if (userDetailsToUpdate.profile_pic) {
      formDataFields.append("profile_pic", userDetailsToUpdate.profile_pic);
    }
    if (userDetailsToUpdate.first_name) {
      formDataFields.append("first_name", userDetailsToUpdate.first_name);
    }
    if (userDetailsToUpdate.last_name) {
      formDataFields.append("last_name", userDetailsToUpdate.last_name);
    }
    if (userDetailsToUpdate.phone) {
      formDataFields.append("phone", userDetailsToUpdate.phone);
    }
    if (userDetailsToUpdate.description) {
      formDataFields.append("description", userDetailsToUpdate.description);
    }
    if (userDetailsToUpdate.alternate_phone) {
      formDataFields.append(
        "alternate_phone",
        userDetailsToUpdate.alternate_phone
      );
    }
    dispatch(updateLoggedInUser(formDataFields));
    props.handleToggle(false);
    alert.show("profile Updated", {
      type: "success",
    });
  }

  return (
    <div className="main_ps_container">
      <div className="profile_two_btn_container">
        {image.preview ? (
          <>
            {loading ? (
              <div
                style={{
                  visibility: loading ? "visible" : "hidden",
                  position: "relative",
                  width: "150px",
                  margin: "0 auto",
                }}
              >
                <CustLoader />
              </div>
            ) : (
              <div className="edit_profile_uploaded_pic_container">
                <button
                  className="cancel_uploaded_pic_btn"
                  onClick={handleCloseItem}
                >
                  <MdClose className="cancel_img" />
                </button>

                <img
                  onLoad={imageLoaded}
                  src={image.preview}
                  loading="lazy"
                  alt="preview_img"
                  className="edit_profile_uploaded_pic"
                />
              </div>
            )}
          </>
        ) : (
          <img
            loading="lazy"
            src={
              user?.user_personal_profile?.profile_pic
                ? user?.user_personal_profile?.profile_pic
                : photo_upload
            }
            alt="upload_pic"
            className="edit_profile_upload_pic"
          />
        )}

        <div className="two_btn_upload_rmove_container">
          <label className="upload_remove_btn1" htmlFor="upload-button">
            Upload Photo
          </label>
          <button className="upload_remove_btn1" onClick={handleRemoveImg}>
            Remove Photo
          </button>
        </div>
        <input
          accept="image/png, image/jpeg, image/jpg"
          type="file"
          id="upload-button"
          style={{ display: "none" }}
          onChange={handleUploadPhoto}
        />
      </div>

      <form onSubmit={handleProfileSubmit}>
        <div className="two_input_container">
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="First name"
              inputType="text"
              placeholderText="Enter your first name"
              inputId="fname"
              name="fname"
              value={
                userProfileUpdate?.user_personal_profile?.first_name
                  ? userProfileUpdate?.user_personal_profile?.first_name
                  : ""
              }
              onChange={(e) => {
                setUserDetailsToUpdate((preValue) => ({
                  ...preValue,
                  first_name: e.target.value,
                }));
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    first_name: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Last name"
              inputType="text"
              placeholderText="Enter your last name"
              inputId="lname"
              name="lname"
              value={
                userProfileUpdate?.user_personal_profile?.last_name
                  ? userProfileUpdate?.user_personal_profile?.last_name
                  : ""
              }
              onChange={(e) => {
                setUserDetailsToUpdate((preValue) => ({
                  ...preValue,
                  last_name: e.target.value,
                }));
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    last_name: e.target.value,
                  },
                }));
              }}
            />
          </div>
          {/* <InputText
            headTextInput="UserName"
            inputType="name"
            placeholderText="Enter your username"
            inputId="u_id"
            name="userId"
            value={userProfileUpdate?.username ? userProfileUpdate?.username : null}
            onChange={(e) =>
              setUserProfileUpdate((preValue)=>({ 
                ...preValue,
                username : e.target.value,
              }))
            }
          /> */}
          {/* <InputText
            headTextInput="Email"
            inputType="email"
            placeholderText="Enter your email"
            inputId="emailId"
            name="emailId"
            onChange={(e) =>
              setUserProfileUpdate((preValue) => ({
                ...preValue,
                email: e.target.value,
              }))
            }
          /> */}

          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Phone number"
              inputType="number"
              placeholderText="Enter your phone no."
              inputId="pno"
              inputPattern="^-?[0-9]\d*\.?\d*$"
              name="number"
              value={
                userProfileUpdate?.user_personal_profile?.phone
                  ? userProfileUpdate?.user_personal_profile?.phone
                  : ""
              }
              onChange={(e) => {
                setUserDetailsToUpdate((preValue) => ({
                  ...preValue,
                  phone: e.target.value,
                }));
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    phone: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="label_input_text_contianer">
            <InputText
              headTextInput="Mobile number"
              inputType="number"
              placeholderText="Enter your mobile no."
              inputId="mno"
              inputPattern="^-?[0-9]\d*\.?\d*$"
              name="number"
              value={
                userProfileUpdate?.user_personal_profile?.alternate_phone
                  ? userProfileUpdate?.user_personal_profile?.alternate_phone
                  : ""
              }
              onChange={(e) => {
                setUserDetailsToUpdate((preValue) => ({
                  ...preValue,
                  alternate_phone: e.target.value,
                }));
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    alternate_phone: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="input_textarea_container">
            <h6 className="input_label_text">About</h6>
            <textarea
              maxLength="501"
              id="aboutId"
              name="about"
              autoFocus
              placeholder="Write something.."
              className="textarea_about_style"
              value={
                userProfileUpdate?.user_personal_profile?.description
                  ? userProfileUpdate?.user_personal_profile?.description
                  : ""
              }
              onChange={(e) => {
                setUserDetailsToUpdate((preValue) => ({
                  ...preValue,
                  description: e.target.value,
                }));
                setUserProfileUpdate((preValue) => ({
                  ...preValue,
                  user_personal_profile: {
                    ...preValue.user_personal_profile,
                    description: e.target.value,
                  },
                }));
                setCount(e.target.value.length);
              }}
            />
            <p className="text_length_text">{count}/500</p>
            {userProfileUpdate?.user_personal_profile?.description ? (
              userProfileUpdate?.user_personal_profile?.description.length >
              500 ? (
                <p className="error_text">
                  you have reached a limit of 500, Please write description
                  under 500 character !
                </p>
              ) : null
            ) : null}
          </div>
        </div>
        <div className="btn_submit_cntainer">
          <button className="submit_btn_dialog_box">Submit</button>
        </div>
      </form>
    </div>
  );
}
