import { createSlice } from '@reduxjs/toolkit'
import { fetchToken } from '../../Api/Api';

export const tokenSlicer = createSlice({
  name: 'token',
  initialState: {
    token: "",
    isLoading: false
  },
  reducers: {
    toggleLoader: (state, action) => {
      state.isLoading = action.payload
    },
    storeToken: () => {

    },
    fetchApiToken: (state, action) => {
      state.token = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { storeToken, fetchApiToken, toggleLoader } = tokenSlicer.actions

export const fetchApiBearerToken = () => async (dispatch) => {
  dispatch(toggleLoader(true))
  const token = await fetchToken();
  dispatch(toggleLoader(false))
  dispatch(fetchApiToken(token));
}

export default tokenSlicer.reducer 