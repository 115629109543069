import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../CSS file/personaldialog.css";
import SelectBox from "../../../Components/SelectBox";
import "../../CSS file/toggle.css";
import InputText from "../../../Components/InputTextBox/InputText";
import { addUserTestInfo } from "../../../StateManagement/slicers/userSlicer";
import { fetchEnglishExamsDetails } from "../../../StateManagement/slicers/studySlicer";
import { useAlert } from "react-alert";

export default function ExamDialogbox(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [switchValue, setSwitchValue] = useState(false);
  const user = useSelector((state) => state.user.user);
  const englishExam = useSelector((state) => state.study.englishExam);
  const [examTypesToRender, setExamTyesToRender] = useState([]);
  const [examType, setExamType] = useState(null);

  const [arr1, setArr1] = useState([]);
  const [arr2, setArr2] = useState([]);
  const userScoreRecord = useRef(new Array());

  useEffect(() => {
    dispatch(fetchEnglishExamsDetails());
  }, [dispatch]);

  useEffect(() => {
    setArr2(user?.user_appeared_for_test);
    setArr1(englishExam);
  }, [user?.user_appeared_for_test, englishExam]);

  useEffect(() => {
    if (examType) {
      if (examType?.sub_categories_with_marks_distribution) {
        let eng1 = JSON.parse(examType?.sub_categories_with_marks_distribution);
        setExamTyesToRender(eng1);
      }
    }
  }, [user, examType]);

  useEffect(() => {
    examTypesToRender.forEach(function(type) {
      return userScoreRecord.current.push({
        sectionName: type.sectionName,
        sectionValue: "0",
      });
    });
  }, [examTypesToRender]);

  const updateExamDetails = useRef({
    registrationNumber: "",
    exam: "",
    examDate: "",
    // planned: false,
  });

  const userExam = useRef({
    exam: "",
    examDate: "",
    registrationNumber: "",
    // planned: false,
  });

  const handleAddExamDetails = async (e) => {
    e.preventDefault();

    // Calculate the sum or average of section scores
    const sectionScores = userScoreRecord.current.map((obj) =>
      parseFloat(obj.sectionValue)
    );
    const sectionSum = sectionScores.reduce((acc, curr) => acc + curr, 0);
    const sectionAverage = sectionSum / sectionScores.length;

    // Validate individual section scores
    const isAnyScoreInvalid = userScoreRecord.current.some((obj) => {
      const sectionMaxScore = examTypesToRender.find(
        (exam) => exam.sectionName === obj.sectionName
      )?.sectionMaximumScore;

      return (
        isNaN(obj.sectionValue) ||
        obj.sectionValue < 0 ||
        obj.sectionValue > sectionMaxScore
      );
    });

    // Validate total score
    const totalScore = parseFloat(updateExamDetails.current.totalScore);
    const isTotalScoreInvalid =
      isNaN(totalScore) || totalScore < 0 || totalScore > examType.total_score;
    
    // Check if total score matches the sum or average of section scores
    const isTotalScoreMismatch =
      totalScore !== sectionSum && totalScore !== sectionAverage;

    if (isAnyScoreInvalid || isTotalScoreInvalid || isTotalScoreMismatch) {
      // Display an error message or handle the validation error
      alert.error("Invalid scores entered. Please check the scores.");
      return;
    }

    // If all scores are valid, proceed with form submission
    dispatch(
      addUserTestInfo({
        user_id: user.id,
        test_id: examType.id,
        overall_score: updateExamDetails.current.totalScore,
        schema_wise_marks: JSON.stringify(userScoreRecord.current),
      })
    );
    alert.success("Added Exam Details")

    props.handleToggle(false);
  }

  function handleUserScoreRecord(e) {
    const newState = userScoreRecord.current.map((obj) => {
      if (obj.sectionName === e.sectionName) {
        return { ...obj, sectionValue: e.sectionValue };
      }
      return obj;
    });
    userScoreRecord.current = newState;
  }

  return (
    <form>
      <div className="acc_details_main_contianer">
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Select Exam"
            initialValue={
              examType?.name ? examType?.name.toUpperCase() : "Select exam"
            }
            selectValue={(e) => {
              setExamType([]);
              userScoreRecord.current = [];
              let value = JSON.parse(e);
              setExamType(value);
            }}
            options={
              englishExam.length > 0
                ? englishExam.map((name) => {
                    return {
                      optionName: name.name.toUpperCase(),
                      optionValue: JSON.stringify(name),
                    };
                  })
                : []
            }
          />
        </div>
        {/* <div className="exam_toggle_contianer">
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => setSwitchValue(!switchValue)}
            // onChange={() => {
            //   userExam.current["planned"] = true;
            //   updateExamDetails.current["planned"] = true;
            // }}
            />
            <span className="slider"></span>
          </label>
          <span className="text_no">Planned</span>
        </div> */}
        <div className="label_input_text_contianer">
          <InputText
            headTextInput="Exam Date"
            inputType="date"
            inputId="examId"
            name="examName"
            // inputValue="Select DOB"
            onChange={(e) => {
              userExam.current["examDate"] = e.target.value;
              updateExamDetails.current["examDate"] = e.target.value;
            }}
          />
        </div>
        {examType === null || examType === "Select exam" ? null : (
          <>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput="Registration Number"
                inputType="text"
                placeholderText="Enter number"
                inputId="regId"
                name="regName"
                onChange={(e) => {
                  userExam.current["registrationNumber"] = e.target.value;
                  updateExamDetails.current["registrationNumber"] =
                    e.target.value;
                }}
              />
            </div>
            <div className="label_input_text_contianer">
              <InputText
                headTextInput={
                  "Total Score ( 0 - " + examType.total_score + ")"
                }
                inputType="number"
                placeholderText="Enter total score"
                inputId="totalId"
                name="totalScore"
                onChange={(e) => {
                  userExam.current["totalScore"] = e.target.value;
                  updateExamDetails.current["totalScore"] = e.target.value;
                }}
              />
            </div>
            {examTypesToRender.map((exam, index) => {
              return (
                <div className="label_input_text_contianer" key={Math.random()}>
                  <InputText
                    headTextInput={
                      exam?.sectionName +
                      "Total Score ( 0 - " +
                      exam.sectionMaximumScore +
                      ")"
                    }
                    value={userScoreRecord.current[index]?.sectionValue}
                    inputType="number"
                    placeholderText="Enter total score"
                    inputId="totalId"
                    name="totalName"
                    onChange={(e) => {
                      handleUserScoreRecord({
                        sectionName: exam.sectionName,
                        sectionValue: e.target.value,
                      });
                    }}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>

      <div className="btn_submit_cntainer">
        <button
          type="button"
          className="submit_btn_dialog_box"
          onClick={handleAddExamDetails}
        >
          Submit
        </button>
      </div>
    </form>
  );
  // }
}
