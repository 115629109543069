import React from "react";

import SelectBox from "../../Components/SelectBox";

function ReqRelationshipContent(props) {
  const handleSubmit = () => {
    props.toggleModal(false);
  };
  return (
    <div className="req_rel_main_container">
      <p>
        Relationship Member will guide you through your journey, assist with
        making decisions and help solve any problems. Tell us, which Office
        would you like to work with and we will assign you our best people!
      </p>
      <div className="select_box_two_container">
        <div className="select_input_text_contianer">
          <SelectBox headTextInput="Location" initialValue="Select option" />
        </div>
        <div className="select_input_text_contianer">
          <SelectBox
            headTextInput="Preffered office"
            initialValue="Select option"
          />
        </div>
      </div>

      <button type="button" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
}

export default ReqRelationshipContent;
