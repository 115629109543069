import React, { useEffect, useState } from "react";
import "../../CSS file/allcountsection.css";
import moment from "moment";

const img_avtar = require("../../../assets/profile_pic.jpg");
const no_like = require("../../../assets/no_likes.png");

function AllCountSection(props) {


  return (
    <>
      {props.userLikeInteraction.feeds_has_user_interactions.length > 0 ? (
        props.userLikeInteraction.feeds_has_user_interactions.map(
          (perCountBox) => {
            if (perCountBox.if_liked === true || perCountBox.if_liked === 1) {
              return (
                <div className="section_box_container">
                  <div className="left_count_container">
                    <div className="avtar_container">
                      <img
                        src={
                          perCountBox.user_interacted_name.profile_pic
                            ? perCountBox.user_interacted_name.profile_pic
                            : img_avtar
                        }
                        alt="pic"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="right_count_container">
                    <strong>
                      {perCountBox.user_interacted_name.first_name}{" "}
                      {perCountBox.user_interacted_name.last_name}
                    </strong>
                    <p>
                      {moment(perCountBox.user_interacted_name.created_at).format(
                        "MMM Do, YYYY"
                      )}
                    </p>
                  </div>
                </div>
              );
            }
          }
        )
      ) : (
        <div className="no_likes_container">
          <img src={no_like} alt="dwec_like" loading="lazy" />
        </div>
      )}
    </>
  );
}

export default AllCountSection;
