import React from "react";

function GallerysectionSkeleton(props) {
  return (
    <>
      <div className="per_photo_contianer skeleton_remove_border">
        <div className="photo_click_contaier skeleton ">
          <div className="photo_gallery skeleton " />
        </div>
      </div>
      <div className="per_photo_contianer skeleton_remove_border">
        <div className="photo_click_contaier skeleton ">
          <div className="photo_gallery skeleton " />
        </div>
      </div>
      <div className="per_photo_contianer skeleton_remove_border">
        <div className="photo_click_contaier skeleton ">
          <div className="photo_gallery skeleton " />
        </div>
      </div>

      <div className="per_photo_contianer skeleton_remove_border">
        <div className="photo_click_contaier skeleton ">
          <div className="photo_gallery skeleton " />
        </div>
      </div>
    </>
  );
}

export default GallerysectionSkeleton;
