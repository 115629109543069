import React, { useState, useEffect } from "react";
import sign_img from "../../assets/signup.png";
import "../CSS file/signup.css";
import SignHeader from "../../Components/Sign Header/SignHeader";
import { Link, useNavigate } from "react-router-dom";
import { userSignUp } from "../../Api/Api";
import Spinner from "../spinner/Spinner";
import { signInWithFirebase } from "../common/common";
import { useDispatch, useSelector } from "react-redux";
import { storeUser } from "../../StateManagement/slicers/userSlicer";
import { helpUserSigningUp } from "../../StateManagement/slicers/authSlicer";
import { SpinnerDotted } from "spinners-react";
import PhoneInput from "react-phone-input-2";
// import classNames from "classnames";
import '../../../node_modules/react-phone-input-2/lib/style.css';
import {
  emptyErrorMessage,
  emptySuccessMessage,

} from "../../StateManagement/slicers/authSlicer";
import { useAlert } from "react-alert";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  // contactNo: Yup.string().length(6, 'Contact number must be 6 digits').required('Contact number is required'),
  contactNo: Yup.string().matches(phoneRegExp, 'Phone number is not valid').length(12, 'Contact number must be 10 digits').required('A phone number is required'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  repassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Re-enter password'),
  termsCondition: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('You must accept the terms and conditions'),
});

export default function Signup({ verifiedEmail }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [userReg, setUserReg] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: verifiedEmail,
    contactNo: "",
    password: "",
    repassword: "",
  });
  const [termsCondtion, setTermsCondition] = useState(false);
  const [signInMethod, setSignInMethod] = useState("");
  const { isLoading, errorMessage, successMessage, user } = useSelector(
    (state) => state.auth
  );
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (successMessage != null && successMessage != "") {
      alert.show(successMessage, {
        type: "success",
      });
      dispatch(emptySuccessMessage());
      navigate("/WelcomePage");
    }
    if (errorMessage != null && errorMessage != "") {
      alert.show(errorMessage, {
        type: "error",
      });
      dispatch(emptyErrorMessage());
    }
  }, [successMessage, errorMessage]);

  function handleAllSignUp(e) {
    const name = e.target.name;
    const value = e.target.value;
    setUserReg({ ...userReg, [name]: value });
  }

  function handleTermsCondition(e) {
    if (e.target.value) {
      setTermsCondition(!termsCondtion);
    }
  }

  // async function SignupSubmit(e) {
  //   setSignInMethod("emailSignUp");
  //   e.preventDefault();
  //   if (termsCondtion) {
  //     dispatch(
  //       helpUserSigningUp({
  //         first_name: userReg.firstName,
  //         last_name: userReg.lastName,

  //         email: userReg.email,
  //         phone: userReg.contactNo,
  //         password: userReg.password,
  //         termsCondtion: termsCondtion,
  //         timezone: "IST",
  //         f_uid: "",
  //         provider: "web",
  //       })
  //     );
  //   }
  // }

  const handleSubmit = async (values, { setSubmitting }) => {
    dispatch(
      helpUserSigningUp({
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.contactNo,
        password: values.password,
        termsCondtion: values.termsCondition,
        timezone: 'IST',
        f_uid: '',
        provider: 'web',
       
      })
    );

    setSubmitting(false);
  };

  async function handleSignInWithFirebase(e) {
    setSignInMethod("googleSignUp");
    const res = await signInWithFirebase();
    const full_name = res.user.displayName;
    let name = full_name.split(" ");
    let first_name = name[0];
    let last_name = name[1];
    const email = res.user.email;
    const profilePic = res.user.photoURL;
    const phone = res.user.phoneNumber;
    const uid = res.user.uid;
    const providerId = res.user.providerData[0].providerId;
    dispatch(
      helpUserSigningUp({
        first_name: first_name,
        last_name: last_name,
        // username:userName,
        email: verifiedEmail || email,
        phone: phone,
        terms_and_condtion: true,
        timezone: "IST",
        f_uid: uid,
        provider: providerId,
        profile_pic: profilePic,
        
      })
    );
  }

  return (
    <>
      <SignHeader />

      <div className="main_container_sign_up">
        <div className="left_sign_up_container">
          <div className="left_under_sign_up_container">
            {/* <form action="#" onSubmit={SignupSubmit}> */}
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: verifiedEmail || '',
                contactNo: '',
                password: '',
                repassword: '',
                termsCondition: false,
              }}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting , errors , touched , setFieldValue }) => (
                <Form>
              <h6 className="create_text">Create New Account</h6>
              <p className="create_extra_text">
                It's free to signup and only takes a minute.
              </p>

              <h6 className="title_of_input">First name</h6>
              {/* <input
                type="text"
                id="first_name"
                className="sign_up_input"
                name="firstName"
                required
                value={userReg.firstName}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your first name"
              /> */}
              <Field type="text" name="firstName" className={`sign_up_input ${errors.firstName && touched.firstName ? 'is-invalid' : ''}`} placeholder="Enter your first name" />
              <ErrorMessage name="firstName" component="div" className="error-message" />

              <h6 className="title_of_input">Last name</h6>
              {/* <input
                type="text"
                id="last_name"
                className="sign_up_input"
                name="lastName"
                required
                value={userReg.lastName}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your last name"
              /> */}
              <Field type="text" name="lastName" className={`sign_up_input ${errors.lastName && touched.lastName ? 'is-invalid' : ''}`} placeholder="Enter your last name" />
              <ErrorMessage name="lastName" component="div" className="error-message" />
              {/* <h6 className="title_of_input">User name</h6>
              <input
                type="text"
                id="user_name"
                className="sign_up_input"
                name="userName"
                required
                value={userReg.userName}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your user name"
              /> */}

              <h6 className="title_of_input">Email address</h6>
              {/* <input
                type="email"
                id="email"
                className="sign_up_input"
                name="email"
                required
                value={userReg.email}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your email"
              /> */}
                <Field type="email" name="email"  className={`sign_up_input ${errors.email && touched.email ? 'is-invalid' : ''}`} placeholder="Enter your email" />
                <ErrorMessage name="email" component="div" className="error-message" />
                {/* errorMessage */}
              <h6 className="title_of_input">Contact no.</h6>
              {/* <input
                type="number"
                id="contact"
                className="sign_up_input"
                name="contactNo"
                required
                value={userReg.contactNo}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your contact number"
                maxLength="6"
              /> */}
              {/* <Field type="text" name="contactNo" className={`sign_up_input ${errors.contactNo && touched.contactNo ? 'is-invalid' : ''}`} placeholder="Enter your contact number" maxLength="6" /> */}
              {/* <Field name="contactNo"  containerClass={classNames('sign_up_input w-100', { 'is-invalid': errors.contactNo && touched.contactNo })}> */}
              <Field name="contactNo"  >
                    {({ field }) => (
                      <PhoneInput
                        country={'in'}
                        value={field.value}
                        onChange={(value) => setFieldValue('contactNo', value)}
                        inputClass={`sign_up_input w-100 ${errors.contactNo && touched.contactNo ? 'is-invalid' : ''}`}
                        // inputClass="sign_up_input_inner w-100"
                      />
                    )}
              </Field>
              <ErrorMessage name="contactNo" component="div" className="error-message" />

              <h6 className="title_of_input">Password</h6>
              {/* <input
                type="password"
                id="password"
                className="sign_up_input"
                name="password"
                required
                value={userReg.password}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your password"
              /> */}
              <Field type="password" name="password" className={`sign_up_input ${errors.password && touched.password ? 'is-invalid' : ''}`} placeholder="Enter your password" />
              <ErrorMessage name="password" component="div" className="error-message" />

              <h6 className="title_of_input">Re-Password</h6>
              {/* <input
                type="password"
                id="repassword"
                className="sign_up_input"
                name="repassword"
                required
                value={userReg.repassword}
                onChange={handleAllSignUp}
                autoComplete="off"
                placeholder="Enter your re-password"
              /> */}
              <Field type="password" name="repassword" className={`sign_up_input ${errors.repassword && touched.repassword ? 'is-invalid' : ''}`} placeholder="Enter your re-password" />
              <ErrorMessage name="repassword" component="div" className="error-message" />

              <div className="check_box_container">
                {/* <input
                  defaultChecked={termsCondtion}
                  type="checkbox"
                  id="myCheck"
                  name="termsCondtion"
                  required
                  className="check_box_self"
                  onClick={handleTermsCondition}
                /> */}
                <Field type="checkbox" name="termsCondition" className={`check_box_self ${errors.termsCondition && touched.termsCondition ? 'is-invalid' : ''}`} />
                <label className="text_terms" htmlFor="myCheck">
                  By clicking Create an account below, you agree to our terms of
                  service and privacy statement.
                </label>
              </div>
                <ErrorMessage name="termsCondition" component="div" className="error-message" />

              {/* <button
                disabled={!termsCondtion}
                className="btn_sign_in same_btn_sign_in"
              >
                {isLoading && signInMethod === "emailSignUp" ? (
                  <SpinnerDotted enabled={isLoading} color="white" />
                ) : (
                  "Sign Up"
                )}
              </button> */}
              <button type="submit" disabled={isSubmitting} className="btn_sign_in same_btn_sign_in">
                    {isSubmitting ? <SpinnerDotted enabled={isSubmitting} color="white" /> : 'Sign Up'}
              </button>


              <div className="or_horizontal_line">
                <div className="line_horizontal" />{" "}
                <span className="or_text_sign_in">OR</span>
                <div className="line_horizontal" />
              </div>
              </Form>
              )}
            </Formik>
            {/* </form> */}

            <div className="btn_google_fb_contianer">
              <button
                className="btn_google same_btn_sign_in"
                onClick={handleSignInWithFirebase}
              >
                {isLoading && signInMethod === "googleSignUp" ? (
                  <SpinnerDotted enabled={isLoading} color="white" />
                ) : (
                  "Sign Up with Google"
                )}
              </button>
              {/* <button className="btn_fb same_btn_sign_in">
                Sign Up with Facebook
              </button> */}

              <Link className="dont_have_text" to="/">
                {" "}
                Already have an account?{" "}
                <span className="create_text1">Sign In</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="right_sign_up_container">
          <img
            className="img-fluid right_sing_up"
            src={sign_img}
            alt="img_sign_up"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}