import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../CSS file/editprofile.css";
import { MdDeleteOutline } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CustDialogBox from "../../Components/CustDialogBox";
import PersonalDialogbox from "./Dialog Box Content/PersonalDialogbox";
import "../CSS file/personaldialog.css";
import AddressDialogbox from "./Dialog Box Content/AddressDialogbox";
import AccDialogBox from "./Dialog Box Content/AccDialogBox";
import BasicInfoDialogbox from "./Dialog Box Content/BasicInfoDialogbox";
import PreffereDialogbox from "./Dialog Box Content/PreffereDialogbox";
import EducationDialogbox from "./Dialog Box Content/EducationDialogbox";
import WorkExperienceDialogbox from "./Dialog Box Content/WorkExperienceDialogbox";
import AspirationDialogbox from "./Dialog Box Content/AspirationDialogbox";
import DestinationDialogbox from "./Dialog Box Content/DestinationDialogbox";
import BudgetDialogbox from "./Dialog Box Content/BudgetDialogbox";
import UnivercitiDialogbox from "./Dialog Box Content/UnivercitiDialogbox";
import ExamDialogbox from "./Dialog Box Content/ExamDialogbox";
import {
  deleteWorkExperienceData,
  addUserTestInfo,
  deleteUserEducationalBackground,
  deleteUserTestInfo,
} from "../../StateManagement/slicers/userSlicer";
import { deleteUserEducationDetail } from "../../Api/Api";
import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
// import LoaderFullPage from "../../Components/LoaderFullPage";

export default function EditProfileHeader(props) {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = React.useState(false);

  function handleToggle(e) {
    setToggleModal(e);
  }

  async function handleDelete(e) {

    
    if (e.editHeaderName === "Exams") {
      dispatch(deleteUserTestInfo(e.examId));
      alert.success("Deleted Exam Info.")
    }
    if (e.editHeaderName === "Work Experience") {

      dispatch(deleteWorkExperienceData(e.workId));
      alert.success("Deleted Work Experience Info.")
    }

    if (e.editHeaderName === "History") {
     

      dispatch(deleteUserEducationalBackground(e.educationId));
      alert.success("Deleted Education Info.")
    }
  }

  return (
    <>
      {/* <LoaderFullPage / > */}
      <div className="heading_edit_cotainer">
        <h6 className="heading_text">{props.editHeaderName}</h6>

        <div className="delete_edit_btn_container">
          {props.dlt !== "dlt" ? (
            <button className="edit_icon_self">
              <MdDeleteOutline
                className="edit_icon_style"
                onClick={() => handleDelete(props)}
              />{" "}
            </button>
          ) : null}

          {props.edit === "examInfo" ||
            props.edit === "univercitiInfo" ? null : (
            <button
              className="edit_icon_self"
              onClick={() => setToggleModal(true)}
            >
              <BiEditAlt className="edit_icon_style" />
            </button>
          )}
          {props.edit === "univercitiInfo" ? (
            <button
              className="edit_icon_self"
              onClick={() => navigate("/UnivercityMain")}
            >
              View All
            </button>
          ) : null}
        </div>

        {props.edit === "urDetails" ? (
          <CustDialogBox
            dialogHeaderName="Edit your details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <PersonalDialogbox
              handleToggle={handleToggle}
              handleLoader={true}
            />
          </CustDialogBox>
        ) : null}

        {props.edit === "accDetails" ? (
          <CustDialogBox
            dialogHeaderName="Change Password "
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <AccDialogBox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "basicInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit basic your details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <BasicInfoDialogbox
              handleToggle={handleToggle}
            // handleLoader={true} 
            />
          </CustDialogBox>
        ) : null}

        {props.edit === "addressInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit address your details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <AddressDialogbox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "prefferedInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit preffered your details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <PreffereDialogbox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "educationInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit education information"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <EducationDialogbox
              handleToggle={handleToggle}
              id={props.educationId}
            />
          </CustDialogBox>
        ) : null}

        {props.edit === "workInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit work experience details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <WorkExperienceDialogbox
              handleToggle={handleToggle}
              id={props.workId}
            />
          </CustDialogBox>
        ) : null}

        {props.edit === "aspirationInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit study aspirations details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <AspirationDialogbox
              handleToggle={handleToggle}

            />
          </CustDialogBox>
        ) : null}

        {props.edit === "destinationInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit desired destination details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <DestinationDialogbox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "budgetInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit study budget details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <BudgetDialogbox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "univercitiInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit shortlisted univercities details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <UnivercitiDialogbox handleToggle={handleToggle} />
          </CustDialogBox>
        ) : null}

        {props.edit === "examInfo" ? (
          <CustDialogBox
            dialogHeaderName="Edit exam details"
            open={toggleModal}
            onClose={() => setToggleModal(false)}
          >
            <ExamDialogbox handleToggle={handleToggle} id={props.examId} />
          </CustDialogBox>
        ) : null}
      </div>
    </>
  );
}
