import React from 'react';
import '../CSS file/righteditprofile.css'

function headerSubtitle2(props) {
    return (
        <div className="title_subtitle_header_container">
        <h6 className="date_of_title_text">{props.titleHeader1}</h6>
        <h6 className="sub_text_date">{props.subTitleHeader1}</h6>
        </div>
        );
    }
    
    export default headerSubtitle2;