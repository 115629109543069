import React, { useState, useEffect } from "react";
import CommonHeader from "../../Components/Common header/CommonHeader";
import LeftProfile from "./left profile/LeftProfile";
import "@szhsin/react-menu/dist/index.css";
import MiddleProfile from "./middle profile/MiddleProfile";
import RightProfile from "./right profile/RightProfile";
import whtsApp from "../../assets/bot.png";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import profile1 from "../../assets/profile_pic.jpg";
import close from "../../assets/close.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchApiBearerToken } from "../../StateManagement/slicers/tokenSlicer";
import "../CSS file/viewpro.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import LoaderFullPage from "../../Components/LoaderFullPage";

const theme = {
  background: "#f5f8fb",
  fontFamily: "IBMReg",
  headerBgColor: "var(--theme-red)",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "var(--theme-red)",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};

const steps = [
  {
    id: "1",
    message: "Welcome to the DYNAMIC WORLD EDUCATION COMMUNITY !!!",
  },
  {
    id: "1",
    message: "How can I help you?",
    end: false,
  },
];
export default function Viewprofile() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.token);
  let [showChat, setShowChat] = useState(false);
  useEffect(() => {
    dispatch(fetchApiBearerToken());
  }, []);

  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };
  return (
    <>

      <div>
        <CommonHeader />
        <div className="view_profile_main_container" id="viewprofile">
          <div className="one_main_container">
            <LeftProfile />
          </div>
          <div className="two_main_container">
            <MiddleProfile />
          </div>
          <div className="third_main_container">
            <div className="btn_whats_ap">
              {showChat ? (
                <ThemeProvider theme={theme}>
                  <ChatBot
                    steps={steps}
                    headerComponent={
                      <div className="header_chat_content">
                        <button className="close_btn" onClick={hideChat}>
                          <img
                            loading="lazy"
                            src={close}
                            alt="close"
                            className="close_btn_icon"
                          />
                        </button>
                      </div>
                    }
                    botAvatar={whtsApp}
                    userAvatar={profile1}
                    enableSmoothScroll={true}
                  />
                </ThemeProvider>
              ) : (
                <button className="btn" onClick={() => startChat()}>
                  <img
                    loading="lazy"
                    src={whtsApp}
                    alt="whtsApp"
                    className="whts_app_chat"
                  />
                  <h6 className="chat_with_text"> Chat with bot </h6>
                </button>
              )}
            </div>
            <RightProfile />
          </div>
        </div>
      </div>
    </>
  );
}
