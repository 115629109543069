import React, { useState } from "react";
import "../CSS file/connectionmain.css";
import CommonHeader from "../../Components/Common header/CommonHeader";
// import ConnectionLeftSide from "../Connection Screen/";
// import ConnectionRightSide from "./ConnectionRightSide";
import { MdOutlineSearch } from "react-icons/md";
import DataOfGallery from "./DataOfGallery";

export default function GalleryMain() {
  return (
    <div className="main_connection_contianer">
      <CommonHeader />
      <div className="conn_title_search_bar_contianer">
        <h4 className="conn_title">Gallery</h4>
      </div>

      <hr className="conn_line" />

      <div className="connection_under_main_container">
        <div className="gallery_view_container">
          <DataOfGallery />
        </div>
      </div>
    </div>
  );
}
