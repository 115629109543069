import React, { useState } from 'react';

const LoadMoreText = ({ text, maxLength = 100 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (text?.length <= maxLength) {
        // If the text is short enough, just return it as is without any "Load More" functionality
        return <span className="tagline_content">{text}</span>;
    }

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="">
            <p className="tagline_content">
                {isExpanded ? text : `${text?.slice(0, maxLength)}...`}
                <span onClick={toggleExpanded} className="read_text_more_left_side">
                    {isExpanded ? ' Show Less' : ' Load More'}
                </span>
            </p>
        </div>
    );
};

export default LoadMoreText;