import React, { useState, useEffect } from "react";
import "../CSS file/perstepsection.css";
import { BiLoader } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { IoAlertCircleOutline } from "react-icons/io5";
const stage_icon = require("../../assets/stepIcon/step1_icon.svg");

function PerStepSection(props) {

  return (
    <div className="main_per_step_section_container">
      <div className="two_stage_text_status_container">
        <div className="stage_sec_container">
          <p>STAGE : {props.stepvalue.countStep}</p>
        </div>
        <div className="status_text_container">
          <BiLoader className="status_yellow_color_text" />{" "}
          <p className="status_progress_text">In Progress</p>
        </div>
      </div>

      <div className="stage_content_container">
        <div className="stage_img_container">
          <img src={stage_icon.default} alt="stage" loading="lazy" />
        </div>

        <div className="stage_agenda_title_subtitle_container">
          <div className="stage_title_subtitle_container">
            <p className="stage_title_text">{props.stepvalue.titleStep}</p>
            <div
              className="stage_subtitle_text"
              dangerouslySetInnerHTML={{
                __html: props.stepvalue.stageFirstContent,
              }}
            />
          </div>

          <div className="stage_title_subtitle_container1">
            <p className="stage_title_text">Agenda :</p>
            <div className="stage_subtitle_text">
              {/* complete content */}

              {props.stepvalue.stageAgendaSuccessMsg.map((item) => {
                return (
                  <div
                    className={
                      item.value === 1 ? "complete_text" : "attention_text"
                    }
                    key={Math.random()}
                  >
                    {item.value === 1 ? (
                      <BsCheckCircle className="icon_size_status" />
                    ) : (
                      <IoAlertCircleOutline className="icon_size_status" />
                    )}
                    {item.aegendaContent}
                  </div>
                );
              })}
              
              <div
                className="extra_text"
                dangerouslySetInnerHTML={{
                  __html: props.stepvalue.stageExtraText,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerStepSection;
