import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { userSignUp, userLogin } from "../../Api/Api";
import initializeAuthentication from "../Firebase/firebase.init";
// import { useNavigate } from "react-router-dom";

export async function signInWithFirebase() {
 
  initializeAuthentication();
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  try {
    let result = await signInWithPopup(auth, provider);
    const full_name = result.user.displayName;
    let name = full_name.split(" ");
    let first_name = name[0];
    let last_name = name[1];
    const email = result.user.email;
    const profilePic = result.user.photoURL;
    const phone = result.user.phoneNumber;
    const uid = result.user.uid
    const providerId = result.user.providerData[0].providerId;
    return result;
   
  } catch (error) {
    return error;
  };
}




