import React, { useState, useEffect } from "react";
import "./CSS file/applynow.css";
import CustDialogBox from "../Components/CustDialogBox";
import ApplicationDialogContent from "../Screens/Univercity&Course/ApplicationDialogContent";

function ApplyNow(props) {
  const [toggleModal, setToggleModal] = useState(false);

  const handleApply = () => {
    setToggleModal(true);
  };

  return (
    <div className="apply_now_container">
      <div className="apply_btn_container">
        <button onClick={handleApply}>Apply now</button>

        <CustDialogBox
          dialogHeaderName="Create Application Details"
          open={toggleModal}
          onClose={() => setToggleModal(false)}
        >
          <ApplicationDialogContent />
        </CustDialogBox>
      </div>
    </div>
  );
}

export default ApplyNow;
