import React, { useState, useEffect } from "react";
import "../CSS file/groupleft.css";
import PaginationTab from "../../Components/Pagination tab/PaginationTab";
const avtar_img = require("../../assets/profile_pic.jpg");
const group_photo = require("../../assets/profile_pic.jpg");

const allGroupContent = [
  {
    groupImg: group_photo,
    groupName: "Human resource1",
    groupMember: "1200255",
    groupConnection: "2121212",
  },
  {
    groupImg: group_photo,
    groupName: "Human resource2",
    groupMember: "1200255",
    groupConnection: "2121212",
  },
  {
    groupImg: group_photo,
    groupName: "Human resource3",
    groupMember: "1200255",
    groupConnection: "2121212",
  },
  {
    groupImg: group_photo,
    groupName: "Human resource4",
    groupMember: "1200255",
    groupConnection: "2121212",
  },

  {
    groupImg: group_photo,
    groupName: "Human resource5",
    groupMember: "1200255",
    groupConnection: "2121212",
  },
];

export default function GroupLeftSide() {
  const [selectValue, setSelectValue] = useState([]);
  //  const[groupLists,setGroupLists] = useState([])

  //   useEffect(()=>{
  //     groupLists()
  //   },[])

  //   async function groupLists(){
  //     let response = await userGroupLists()
  //     setGroupLists(response)
  //   }

  function handleJoin(index) {
    let emptyArray = [];
    if (!selectValue.includes(index)) {
      setSelectValue((state) => [...state, index]);
    } else {
      const indexFound = selectValue.indexOf(index);
      const currentValues = selectValue;
      currentValues.splice(indexFound, 1);
      currentValues.forEach((item) => {
        emptyArray.push(item);
      });
      setSelectValue(emptyArray);
    }
  }

  const renderGroupContent = allGroupContent.map((perGroup, index) => {
    return (
      <div className="group_box_container" key={Math.random()}>
        <img
          loading="lazy"
          src={perGroup.groupImg}
          alt="group_pic"
          className="group_pic_self"
        />
        <h6 className="group_title">{perGroup.groupName}</h6>
        <p className="member_count_text">{perGroup.groupMember} members</p>

        <div className="avtar_img_text_container">
          <div className="avtar_group_img_contianer">
            <img
              src={avtar_img}
              alt="Avatar"
              className="avatar_img"
              loading="lazy"
            />
            <img
              src={avtar_img}
              alt="Avatar"
              className="avatar_img"
              loading="lazy"
            />
            <img
              src={avtar_img}
              alt="Avatar"
              className="avatar_img"
              loading="lazy"
            />
            <img
              src={avtar_img}
              alt="Avatar"
              className="avatar_img"
              loading="lazy"
            />
            <img
              src={avtar_img}
              alt="Avatar"
              className="avatar_img"
              loading="lazy"
            />
          </div>
          <p className="connection_text_mutual">
            {perGroup.groupConnection} Connections
          </p>
        </div>

        {selectValue.includes(index) ? (
          <button
            className="leave_btn same_btn_group"
            onClick={() => handleJoin(index)}
          >
            Leave Group
          </button>
        ) : (
          <button
            className="join_btn same_btn_group"
            onClick={() => handleJoin(index)}
          >
            Join
          </button>
        )}
      </div>
    );
  });
  return (
    <>
      <div className="conectin_under_left_side_contianer">
        <div className="group_contianer">{renderGroupContent}</div>
        <div className="pagination_event_tab_container">
          <PaginationTab />
        </div>
      </div>
    </>
  );
}
