import React from "react";
import ProfileBox from "../../../Components/Profile box/ProfileBox";
const follow_profile = require("../../../assets/profile_pic.jpg");

function ConnContent1(props) {
  return (
    <div className="conn_content_main_contianer">
      <ProfileBox
        valueToRender={
          [
          {
            follow_profile: follow_profile,
            profileBoxName: "Zhen Juan Chiu",
            profileBoxSubName: "Software Developer",
          },
          {
            follow_profile: follow_profile,
            profileBoxName: "Zhen Juan Chiu",
            profileBoxSubName: "Software Developer",
          },
          {
            follow_profile: follow_profile,
            profileBoxName: "Zhen Juan Chiu",
            profileBoxSubName: "Software Developer",
          },
          {
            follow_profile: follow_profile,
            profileBoxName: "Zhen Juan Chiu",
            profileBoxSubName: "Software Developer",
          },
          {
            follow_profile: follow_profile,
            profileBoxName: "Zhen Juan Chiu",
            profileBoxSubName: "Software Developer",
          },
        ]
      }
      />
    </div>
  );
}

export default ConnContent1;
