import React, { useState } from "react";
import {
  fetchUserProfileBySearch,
  fetchUserFriendGallery,
  storeUserSendInteractionWithOthers,
} from "../../StateManagement/slicers/userSlicer";
import { storeCourseTypeToRender } from "../../StateManagement/slicers/courseSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../CSS file/searchresult.css";
import LoaderFullPage from "../../Components/LoaderFullPage";
const avtar_pic = require("../../assets/events.jpeg");

function SearchResult() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSelector((state) => state.user.search);
 

  const handleViewDetailsUniversity = (e) => {
    const univercityId = e?.id;
    const univercityName = e?.u_name;
    const path = `/UnivercityMain/ViewDetailUnivercity/${univercityId}/${univercityName}`;
    navigate(path);
  };
  const handleViewDetailsCourses = (e) => {
    const courseId = e?.id;
    const courseName = e.course_has_entries_in_course.name;
    const path = `/UnivercityMain/ViewDetailCourses/${courseId}/${courseName}`;
    navigate(path);
  };

  

  const handleClick = async (e) => {

    dispatch(fetchUserProfileBySearch(e.id));
    dispatch(fetchUserFriendGallery(e.id));
    if (e.event === "users") {
      navigate("/MyActivityMain", { state: "id" });
    }
  };
  const handleFollowButton = async (e) => {
    console.log('e', e)
    if (e.value === false || e.value === "0" || e.value === 0) {
      dispatch(
        storeUserSendInteractionWithOthers({
          data: {
            if_requested: true,
            if_request_accepted: true,
          },
          id: e.id,
        })
      );

    } else {
      dispatch(
        storeUserSendInteractionWithOthers({
          data: {
            if_requested: false,
            if_request_accepted: false,
            if_follows: false,
          },
          id: e.id,
        })
      );

    }

  };

  function handleViewMoreButton(event) {
    if (event === "universities") {
      navigate("/UnivercityMain");
    }
    if (event === "courses") {
      dispatch(storeCourseTypeToRender("Courses"));
      navigate("/UnivercityMain");
    }
   
  }

  const renderSearch = search.map((item) => {
    return (
      <div className="search_result_box_container">
        <div className="search_result_title_content_container">
          {/* {item.ca} */}
          <div className="search_box_title_container">
            <p>{item.category}</p>
          </div>

          {item.category === "users"
            ? item.results.map((perResult) => {
              return (
                <div
                  className="search_content_box_button_container"
                  key={Math.random()}
                >
                  <div className="search_result_avtar_description_container">
                    <div className="search_result_avtar_container">
                      <img
                        loading="lazy"
                        src={perResult?.profile_pic  || avtar_pic}
                        className="avtar_self_search"
                        alt="avtar"
                      />
                    </div>
                    <div className="search_result_description_container">
                      <p
                        className="title_of_search"
                        onClick={(event) => {
                       
                          handleClick({
                            event: item.category,
                            id: perResult.id,
                          });
                        }}
                      >
                        {perResult?.first_name
                          ? perResult?.first_name + " " + perResult?.last_name
                          : perResult.email}
                      </p>
                    </div>
                  </div>
                  <div className="button_of_result_container">
                    <button
                      className={
                        perResult?.user_on_recipient?.if_requested === "1" ||
                         
                          perResult?.user_on_recipient?.if_requested === 1 ||
                          perResult?.user_on_recipient?.if_requested === true
                          ? "following_btn_search"
                          : "follow_btn_search"
                      }
                      onClick={() => {

                        handleFollowButton({
                          event: item.category,
                          id: perResult.user_id,
                          value: perResult?.user_on_recipient?.if_requested,
                        });
                      }}
                    >
                      {perResult?.user_on_recipient?.if_requested === "1" ||
                      
                        perResult?.user_on_recipient?.if_requested === 1 ||
                        perResult?.user_on_recipient?.if_requested === true
                        ? "Following"
                        : "Follow"}
                    </button>
                  </div>
                </div>
              );
            })
            : null}
          {item.category === "universities"
            ? item.results.slice(0, 5).map((perResult) => {
              return (
                <div
                  className="search_content_box_button_container"
                  key={Math.random()}
                >
                  <div className="search_result_avtar_description_container">
                    <div className="search_result_avtar_container">
                      <img
                        src={
                          perResult.university_logo
                            ? perResult.university_logo
                            : avtar_pic
                        }
                        className="avtar_self_search"
                        alt="avtar"
                        loading="lazy"
                      />
                    </div>
                    <div className="search_result_description_container">
                      <p className="title_of_search"  onClick={() => handleViewDetailsUniversity(perResult)}>
                        {perResult?.u_name ? perResult?.u_name : "-------"}
                      </p>
                      <p className="subtitle_of_search">
                        {perResult?.university_description
                          ? perResult?.university_description.substring(
                            0,
                            30
                          ) + "......"
                          : "-------"}
                      </p>
                    </div>
                  </div>
                  <div className="button_of_result_container">
                    {/* <button onClick={handleClick}>Follow</button> */}
                  </div>
                </div>
              );
            })
            : null}
          {item.category === "courses"
            ? item.results.slice(0, 5).map((perResult) => {
              return (
                <div
                  className="search_content_box_button_container"
                  key={Math.random()}
                >
                  <div className="search_result_avtar_description_container">
                    <div className="search_result_avtar_container">
                      <img
                        src={
                          perResult?.course_given_by_university
                            .university_logo
                            ? perResult.course_given_by_university
                              .university_logo
                            : avtar_pic
                        }
                        className="avtar_self_search"
                        alt="avtar"
                        loading="lazy"
                      />
                    </div>
                    <div className="search_result_description_container">
                      <p className="title_of_search" onClick={() => handleViewDetailsCourses(perResult)}>
                        {perResult?.course_given_by_university.u_name
                          ? perResult?.course_given_by_university.u_name.substring(
                            0,
                            34
                          )
                          : "-------"}
                      </p>
                      <p className="subtitle_of_search">
                        {perResult?.course_given_by_university
                          ?.university_description
                          ? perResult?.course_given_by_university?.university_description.substring(
                            0,
                            80
                          ) + "......"
                          : "-------"}
                      </p>
                    </div>
                  </div>
                  <div className="button_of_result_container">
                    {/* <button onClick={handleClick}>Follow</button> */}
                  </div>
                </div>
              );
            })
            : null}
          {item.category === "events"
            ? item.results.map((perResult) => {
              return (
                <div
                  className="search_content_box_button_container"
                  key={Math.random()}
                >
                  <div className="search_result_avtar_description_container">
                    <div className="search_result_avtar_container">
                      <img
                        src={avtar_pic}
                        className="avtar_self_search"
                        alt="avtar"
                        loading="lazy"
                      />
                    </div>
                    <div className="search_result_description_container">
                      <p className="title_of_search">
                        {/* {perResult?.titleOfResult.substring(0, 34)
                        ? perResult?.titleOfResult.substring(0, 34)
                        : "-------"} */}
                        meet
                      </p>
                      <p className="subtitle_of_search">
                        {/* {perResult?.subTitleOfResult.substring(0, 80)
                        ? perResult?.subTitleOfResult.substring(0, 80)
                        : "-------"} */}
                        meet
                      </p>
                    </div>
                  </div>
                  <div className="button_of_result_container">
                    {/* <button onClick={handleClick}>Follow</button> */}
                  </div>
                </div>
              );
            })
            : null}

          {item.results.length > 4 ? (
            <div className="button_of_result_container">
              <button onClick={() => handleViewMoreButton(item.category)}>
                View More
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  });

  return (
    <>
      {search.length > 0 ? (
        renderSearch
      ) : (
        <LoaderFullPage />
        // <>No Search Results Found In Record</>
      )}
    </>
  );
}

export default SearchResult;
