// import React, { useState, useEffect } from "react";
// import "./paginaitontab.css";

// function PaginationTab(props) {
//   const [activeColor, setActiveColor] = useState(1);
//   const [currentPage, setCurrentPage] = useState(2);



//   const pageNumbers = [];
//   for (let i = 1; i <= props.postsPerPage; i++) {
//     pageNumbers.push(i);


//   }

//   const handleSelectPagination = (e) => {
//     setActiveColor(e);
//   };

//   const renderPagination = pageNumbers.map((perPagiantion) => {
//     return (
//       <a
//         href="#pagination"
//         key={Math.random()}
//         className={
//           activeColor === perPagiantion
//             ? "pagination_tab_btn active"
//             : "pagination_tab_btn"
//         }
//         onClick={() => {
//           handleSelectPagination(perPagiantion);
//           props.paginate(perPagiantion);
//         }}
//       >
//         {perPagiantion}
//       </a>
//     );
//   });

//   return (
//     <div className="main_pagination_tab_container">
//       <div className="pagination_box_container">
//         {pageNumbers.includes(currentPage + 1) && (
//           <a
//             href="#prevPage"
//             className="pagination_tab_btn"
//             onClick={() => {
//               setCurrentPage(currentPage + 1);
//               props.paginate(currentPage + 1);
//             }}
//           >
//             <strong>&#8592;</strong>
//           </a>
//         )}
//         <div className="center_pagination">{renderPagination}</div>

//         {pageNumbers.includes(currentPage + 1) && (
//           <a
//             href="#nextPage"
//             className="pagination_tab_btn"
//             onClick={() => {
//               setCurrentPage(currentPage + 1);
//               props.paginate(currentPage + 1);
//             }}
//           >
//             &#10140;
//           </a>
//         )}

//       </div>
//     </div>
//   );
// }

// export default PaginationTab;


import React, { useState } from "react";
import "./paginaitontab.css";

function PaginationTab(props) {
  const [activeColor, setActiveColor] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(props.totalPosts / props.postsPerPage);

  const handleSelectPagination = (pageNumber) => {
    setActiveColor(pageNumber);
    setCurrentPage(pageNumber);
    props.paginate(pageNumber);
  };

  const renderPagination = () => {
    const paginationNumbers = [];
    const maxVisiblePages = 5; // Adjust this to change how many pagination numbers are visible at once

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    if (startPage > 1) {
      paginationNumbers.push(
        <span key="start-ellipsis" className="ellipsis">
          ...
        </span>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(
        <a
          href="#pagination"
          key={i}
          className={
            activeColor === i ? "pagination_tab_btn active" : "pagination_tab_btn"
          }
          onClick={() => handleSelectPagination(i)}
        >
          {i}
        </a>
      );
    }

    if (endPage < totalPages) {
      paginationNumbers.push(
        <span key="end-ellipsis" className="ellipsis">
          ...
        </span>
      );
    }

    return paginationNumbers;
  };

  return (
    <div className="main_pagination_tab_container">
      <div className="pagination_box_container">
        {currentPage > 1 && (
          <a
            href="#prevPage"
            className="pagination_tab_btn"
            onClick={() => handleSelectPagination(currentPage - 1)}
          >
            <strong>&#8592;</strong>
          </a>
        )}
        <div className="center_pagination">{renderPagination()}</div>
        {currentPage < totalPages && (
          <a
            href="#nextPage"
            className="pagination_tab_btn"
            onClick={() => handleSelectPagination(currentPage + 1)}
          >
            &#10140;
          </a>
        )}
      </div>
    </div>
  );
}

export default PaginationTab;
