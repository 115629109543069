import React, { useState, useEffect } from "react";
import FactboxSkeleton from "../../Components/skeleton loader/FactboxSkeleton";
import "../CSS file/factbox.css";

function FactBox(props) {
  const [factBoxValue, setFactBoxValue] = useState([]);

  useEffect(() => {
    if (props.valueToRender.length > 0) {
      setFactBoxValue(props.valueToRender);
    }
  }, [props.valueToRender]);

  const renderFactBox = factBoxValue.map((perFactValue) => {
    return (
      <div
        className="view_detail_title_sub_title_container"
        key={Math.random()}
      >
        <div className="view_detail_box_img_contianer">
          <img
            loading="lazy"
            src={perFactValue.factBoxImg}
            alt="popular_univer"
            className="view_detail_img"
          />
        </div>
        <div className="view_detail_fact_text_subtitle_contianer">
          <h6 className="view_detail_fact_title_text">
            {perFactValue.factBoxTitle}
          </h6>
          <p className="view_detail_fact_sub_title_text">
            {perFactValue.factBoxSubTitle}
          </p>
        </div>
      </div>
    );
  });
  return <>{factBoxValue.length > 0 ? renderFactBox : <FactboxSkeleton />}</>;
}

export default FactBox;
